import { dashboardApi, dashboardModel } from '@entities/dashboard';
import { message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useCreateDashboard = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const userId = useSelector((state: RootState) => state.user.id);
	const dashboard: TDashboard = {
		title: '',
		usersIds: [],
		creator: userId,
		is_public: false,
		content: [],
	};
	const dispatch = useDispatch();
	const onSubmit = (values: any) => {
		const newDashboard = {
			...dashboard,
			...values,
		};
		setLoading(true);
		dashboardApi
			.createDashboard(newDashboard)
			.then((response: any) => {
				dispatch(
					dashboardModel.createDashboard({
						...response.data,
						content: response.data.content,
					})
				);
				message.success(`Панель ${response.data.title} успешно создана!`, 1);
				setVisible(false);
			})
			.catch((e: any) => {
				message.error(`Не удалось создать панель`, 0.5);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const onOpenModal = () => {
		setVisible(true);
	};
	const onCancel = () => {
		setVisible(false);
	};
	return {
		onSubmit,
		onOpenModal,
		visible,
		onCancel,
		dashboard,
		loading,
	};
};
