import React from 'react';
import { useUpdatePreset } from '../../../../entities/preset/models';
import DefaultSelect from '../../../../shared/ui/DefaultSelect';

const FiltersSelect = ({ preset, updatePresetParent }) => {
    const updatePreset = useUpdatePreset(preset);

    const onChange = (values) => {
        const newValue = [["fields", values.map((id) => {
            const filter = preset.fields?.find(field => field.id === id);
            if (filter) {
                return filter;
            } else {
                const field = preset?.subroute?.fields.find(field => field.id === id);
                return field;
            }
        })]]
        if (updatePresetParent) {
            updatePresetParent(
                newValue
            )
        } else
            updatePreset(newValue)
    }
    return (
        <DefaultSelect
            mode="multiple"
            options={preset?.subroute?.fields}
            value_key={"id"}
            label_key={"title"}
            onChange={onChange}
            value={preset?.fields?.map(field => field.id)}
        />
    );
};

export default FiltersSelect;