import { message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dashboardModel } from "../dashboard";
import { updateDashboard } from "../dashboard/api";
import {
    selectCurrentDashboard,
    setModalPreset,
    setRerenderPresetId,
    setTargetPreset,
    updateDashboards,
    useRerenderPresetId,
} from "../dashboard/model";
import { getPresetData } from "./api";
import { getRequestBody, getRequestUrl } from "./lib/ParsePresetInfo";
import { useSelector } from "react-redux";
import { userModel } from "@entities/user";

export const useLoadPresetData = ({ preset, extraReload, noBreak, presetUrl }) => {
    const [data, setData] = useState([]);
    const [modificators, setModificators] = useState({});
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(false);
    const rerenderId = useRerenderPresetId();
    const dashboard = useSelector(selectCurrentDashboard) ?? {};
    const dispatch = useDispatch();
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const ref = useSelector(userModel.selectUserRef);
    let requestCancelTokenSource;

    useEffect(() => {
        return () => {
            setData([]);
        };
    }, []);
    const loadPresetData = () => {
        if (!preset?.period) {
            dispatch(dashboardModel.setRerenderPresetId(0));
            return;
        }
        setData([]);
        setLoading(true);
        setError(false);
        const copiedPreset = JSON.parse(JSON.stringify(preset));
        requestCancelTokenSource = axios.CancelToken.source();
        const url = presetUrl ? presetUrl : getRequestUrl(copiedPreset);
        const body = getRequestBody(copiedPreset);
        if (!isAdmin) {
            body.filters = [
                ...(body.filters ?? []),
                // {
                //     "field": "manager",
                //     "value": !ref ? "ref" : ref,
                //     "comparison": "like",
                //     "condition": "and"
                // }
            ];
        }
        getPresetData(requestCancelTokenSource, url, body)
            .then((response) => {
                setData(response.data?.result);
                setModificators(response.data?.modificators ?? {});
            })
            .catch((e) => {
                console.log("ERROR", e);
                setError(true);
            })
            .finally(() => {
                dispatch(dashboardModel.setRerenderPresetId(0));
                setLoading(false);
            });
    };
    useEffect(() => {
        return () => {
            if (requestCancelTokenSource) {
                requestCancelTokenSource.cancel();
            }
        };
    }, []);
    useEffect(() => {
        loadPresetData();
        return () => {
            if (requestCancelTokenSource && !noBreak) {
                requestCancelTokenSource.cancel();
            }
        };
    }, [refresh, dashboard?.id]);
    useEffect(() => {
        if (rerenderId === preset?.key_id) {
            loadPresetData();
        }
        return () => {
            if (requestCancelTokenSource && rerenderId === preset.key_id && !noBreak) {
                requestCancelTokenSource.cancel();
            }
        };
    }, [rerenderId]);

    const refreshFunc = () => {
        setRefresh(!refresh);
    };
    return { data, setData, error, loading, modificators, refreshFunc };
};

export const useSavePreset = (closeModal) => {
    const dashboard = useSelector(selectCurrentDashboard);
    const dispatch = useDispatch();
    const savePreset = (preset) => {
        updateDashboard(dashboard.id, {
            ...dashboard,
            content: dashboard?.content?.map((row) => {
                if (row.row_id === preset?.row_id) {
                    return {
                        ...row,
                        cols: row.cols.map((col) => {
                            if (col.key_id === preset.key_id) {
                                return preset;
                            }
                            return col;
                        }),
                    };
                }
                return row;
            }),
        })
            .then((response) => {
                localStorage.removeItem(`columns-${preset.key_id}`);
                dispatch(setModalPreset(preset));
                dispatch(
                    updateDashboards({ ...response.data, content: response.data.content })
                );
                dispatch(setRerenderPresetId(preset.key_id));
                if (closeModal) {
                    closeModal();
                }
            })
            .catch((e) => {
                message.error("Не удалось сохранить!");
                console.log(e);
            });
    };

    return savePreset;
};

export const useUpdatePreset = (preset) => {
    const dispatch = useDispatch();
    const updatePreset = (updates) => {
        const newPreset = JSON.parse(JSON.stringify(preset));
        updates.forEach((update) => {
            newPreset[update[0]] = update[1];
        });
        dispatch(setTargetPreset(newPreset));
    };
    return updatePreset;
};
export const useSetPreset = (preset) => {
    const dispatch = useDispatch();

    const setPreset = (preset) => {
        dispatch(dashboardModel.setTargetPreset(preset));
    };

    return setPreset;
};
