import { Spin, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { parseModificatorsColumns } from "../lib/ParseModificators";
import "./GroupChart.scss";
import ExpandedGroupRow from "./EpandedGroupRow";
import styled from "styled-components";
import { useTableColumnsList } from "../UseTableColumnsList";

const StyledTableNoMargin = styled(Table)`
    .ant-table-wrapper .ant-table {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 0px !important;
        margin-inline: 0px 0px !important;
    }
    .ant-table-expanded-row > td {
        padding: 0px !important;
    }
`;
const GroupChart = ({
    onRowClick,
    noTableMargin,
    routes,
    isExpanded,
    data,
    loading,
    columns,
    orderParamsLoading,
    modificators,
    selectionData,
    groups,
    layer,
    preset,
    scroll,
}) => {
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (ref?.current?.clientHeight) {
            setHeight(ref?.current?.clientHeight);
        }
    }, [ref?.current?.clientHeight]);

    const StyledTable = noTableMargin ? StyledTableNoMargin : Table;
    if (orderParamsLoading)
        return (
            <div
                style={{
                    minHeight: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin />
            </div>
        );
    return (
        <div
            ref={ref}
            style={{
                width: "100%",
                height: "100%",
                paddingRight: 10,
                paddingBottom: 20,
            }}
        >
            <StyledTable
                tableLayout={"fixed"}
                size={isExpanded ? "small" : "small"}
                style={
                    scroll
                        ? {
                              height: height,
                              overflowY: "scroll",
                              paddingRight: 7,
                          }
                        : {
                              paddingTop: 10,
                          }
                }
                showHeader={!isExpanded || 1 === groups.length}
                pagination={
                    layer != 1
                        ? 1 === groups.length
                            ? {
                                  pageSize: 500,
                                  hideOnSinglePage: true,
                              }
                            : {
                                  pageSize: 500,
                                  hideOnSinglePage: true,
                              }
                        : {
                              defaultPageSize: 250,
                              pageSizeOptions: ["10", "50", "100", "250", "500"],
                              position: ["topRight", "bottomRight"],
                          }
                }
                loading={loading}
                sticky={
                    scroll
                        ? {
                              offsetHeader: 0,
                          }
                        : undefined
                }
                onRow={
                    onRowClick
                        ? (record, rowIndex) => {
                              return {
                                  onClick: (event) => {
                                      console.log(onRowClick);
                                      event.stopPropagation();
                                      const fields = onRowClick?.fields;
                                      let url = onRowClick?.url;
                                      if (fields && fields?.length > 0 && url) {
                                          fields.forEach((item) => {
                                              url = url.replace(item, record[item]);
                                          });
                                      }
                                      window.open(url, "_blank").focus();
                                  }, // click row
                                  onDoubleClick: (event) => {}, // double click row
                                  onContextMenu: (event) => {}, // right button click row
                                  onMouseEnter: (event) => {}, // mouse enter row
                                  onMouseLeave: (event) => {}, // mouse leave row
                              };
                          }
                        : undefined
                }
                expandable={
                    preset?.grouped?.[0]
                        ? {
                              expandedRowRender: (record, index, indent, expanded) => (
                                  <>
                                      {expanded && (
                                          <ExpandedGroupRow
                                              index={index}
                                              routes={routes}
                                              groups={groups}
                                              parentColumns={columns}
                                              modificators={modificators}
                                              expanded={expanded}
                                              layer={layer}
                                              row={record}
                                              preset={preset}
                                          />
                                      )}
                                  </>
                              ),
                              rowExpandable: (record) =>
                                  record.id !== "Остальные" && record.id !== "Итого",
                          }
                        : undefined
                }
                dataSource={data ?? []}
                columns={
                    parseModificatorsColumns(
                        columns,
                        modificators,
                        selectionData,
                        preset?.plans || []
                    ) || []
                }
                rowKey="id"
            />
        </div>
    );
};

export default GroupChart;
