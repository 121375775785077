import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { dashboardApi, dashboardModel } from '../../entities/dashboard';
import { useSelector } from "react-redux";
const DeleteRowAction = ({ row_id }) => {

    const dispatch = useDispatch();
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);

    const deleteRow = () => {
        dashboardApi.updateDashboard(dashboard.id, {
            ...dashboard, content: dashboard.content.filter(row => row.row_id !== row_id)
        }).then(() => {
            dispatch(dashboardModel.updateDashboards({ ...dashboard, content: dashboard.content.filter(row => row.row_id !== row_id) }))

        })

    }

    return (
        <Popconfirm
            placement="topRight"
            title="Удалить строку?"
            onConfirm={deleteRow}
            okText="Удалить"
            cancelText="Отмена"
        >
            <Button
                style={{ borderWidth: 1 }}
                type="default"
                shape="circle"
                danger
                size='middle'
                icon={<DeleteOutlined />} />
        </Popconfirm>
    );
};

export { DeleteRowAction };
