import { Button, Popover } from 'antd';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

const PickColorButton = ({
    color,
    setColorRgb
}) => {
    const [openColor, setOpenColor] = useState(false);

    const onChange = (color) => {
        console.log(color);
        if (setColorRgb) {
            setColorRgb(color.rgb);
        }
    }
    const onChangeComplete = () => {

    }
    return (
        <Popover
            trigger="click"
            open={openColor}
            onOpenChange={setOpenColor}
            content={(
                <div>
                    <ChromePicker
                        color={color}
                        onChange={onChange}
                        onChangeComplete={onChangeComplete}
                    />
                </div>
            )}
        >
            <Button style={{ borderRadius: 15 }}>Цвет</Button>
        </Popover>
    );
};

export default PickColorButton;