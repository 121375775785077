import { Modal, Spin } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dashboardModel } from "../../../entities/dashboard";
import GraphByType from "./GraphByType";
import PresetHeader from "./PresetHeader";
import { useSelector } from "react-redux";

const PresetModalBox = ({ visible, setVisible, openModifyModal, routes }) => {
    const navigate = useNavigate();
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
    const preset = useSelector(dashboardModel.selectModalPreset) ?? {};
    const dispatch = useDispatch();

    const updatePreset = (updates) => {
        const newPreset_ = JSON.parse(JSON.stringify(preset));
        updates.forEach((update) => {
            newPreset_[update[0]] = update[1];
        });
        dispatch(dashboardModel.setModalPreset(newPreset_));
        if ((preset?.fields?.length ?? 0) < newPreset_?.fields?.length) return;
        if (refreshFunc) refreshFunc();
    };

    const closeModal = () => {
        setVisible(false);
        navigate({
            search: "?dashboard_id=" + dashboard?.id,
        });
    };

    const { data, loading, error, modificators, refreshFunc } =
        useSelector(dashboardModel.selectModalRequestData) ?? {};
    const [type, setType] = useState(null);
    const openPresetModifyModule = () => {
        openModifyModal(preset);
    };

    return (
        <Modal
            title={null}
            centered
            open={visible}
            okText={"Закрыть"}
            onCancel={closeModal}
            closable={false}
            destroyOnClose
            onOk={closeModal}
            style={{ marginTop: 20, marginBottom: 20 }}
            width={window.screen.width < 450 ? "90%" : "90%"}
            zIndex={1001}
            styles={{ body: { paddingBottom: 16, height: "90%" } }}
        >
            <div style={{ width: "100%", marginBottom: 15 }}>
                {
                    <PresetHeader
                        dashboard={dashboard}
                        preset={preset}
                        openModifyPreset={openPresetModifyModule}
                        reloadGraph={refreshFunc}
                        isModal={true}
                    />
                }
            </div>
            <div
                style={{
                    width: "100%",
                    height: window.innerHeight - (window.innerHeight * 30) / 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
            >
                {preset?.period ? (
                    <>
                        {loading ? (
                            <div
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Spin />
                            </div>
                        ) : error ? (
                            <div>Не удалось загрузить данные</div>
                        ) : (
                            <GraphByType
                                noTableMargin={
                                    localStorage.getItem("groupChatWithMargin") === "1"
                                }
                                data={data}
                                preset={preset}
                                type={preset.type}
                                modificators={modificators}
                                modal={true}
                                refreshFunc={refreshFunc}
                                routes={routes}
                                updatePreset={updatePreset}
                                loading={loading}
                            />
                        )}
                    </>
                ) : (
                    <div style={{ fontSize: 16, fontWeight: 500 }}>Добавить</div>
                )}
            </div>
        </Modal>
    );
};

export default PresetModalBox;
