import { Button, Popover } from 'antd';
import React from 'react';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

const ColorPick = ({ color, setColor, title }) => {
    const [hover, setHover] = useState(false)
    return (<Popover

        trigger="click"
        // open={openColor}
        // onOpenChange={setOpenColor}
        content={(
            <div>
                <ChromePicker
                    color={color}
                    onChange={setColor}
                    onChangeComplete={() => console.log("CHANGE!")}
                />
            </div>
        )}
    >
        <div style={{ display: "flex", alignItems: "center", marginRight: 10, cursor: "pointer" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            <div style={{
                marginRight: 10,
                color: hover ? "#1890ff" : "#1c1c1c"
            }}>{title}</div>

            <button style={{
                outline: "none",
                border: "none",
                background: color,
                width: 30,
                height: 30,
                borderRadius: 6
            }}></button>
        </div>
    </Popover >
    );
};

export default ColorPick;