import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import layoutReducer from './layoutSlice'
import routesSlice from './routesSlice'
import { dashboardModel } from '../NewDashboard/entities/dashboard'

const store = configureStore({
  reducer: {
    user: userReducer,
    layout: layoutReducer,
    routes: routesSlice,
    dashboard: dashboardModel.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store