
import React from 'react';
import { useState } from 'react';
import UpdateUserModal from './components/UpdateUserModal/UpdateUserModal';
import UserTable from './components/UserTable/UserTable';
import { userModel } from '@entities/user';
import { useSelector } from 'react-redux';


// const roles = [
//     { value: "super_admin", label: "Главный администратор" },
//     { value: "d_manager", label: "Менеджер" },
//     { value: "d_user", label: "Дилер" },
//     { value: "p_admin", label: "Администратор проекта" },
//     { value: "p_supervisor", label: "Руководитель проекта" },
//     { value: "p_brigadier", label: "Бригадир" },
//     { value: "p_user", label: "Исполнитель" },
//     { value: "p_coordinator", label: "Координатор" },
//     { value: "s_admin", label: "Сервисный администратор" },
//     { value: "f_editor", label: "Руководитель | Производство" },
//     { value: "f_user", label: "Исполнитель | Производство" },
// ]
// const roles_ = {
//     "super_admin": "Главный администратор",
//     "d_manager": "Менеджер",
//     "d_user": "Дилер",
//     "p_admin": "Администратор проекта",
//     "p_supervisor": "Руководитель проекта",
//     "p_brigadier": "Бригадир",
//     "p_user": "Исполнитель",
//     "p_coordinator": "Координатор",
//     "s_admin": "Сервисный администратор",
//     "f_editor": "Руководитель | Производство",
//     "f_user": "Исполнитель | Производство",
// }
const UsersPage = () => {
    const [editUser, setEditUser] = useState<any>(undefined)
    const [reloadPage, setReloadPage] = useState(true);
    const [updatedIndex, setUpdatedIndex] = useState(0);
    const { roles } = userModel.useLoadRoles();
    const rolesByName = roles.reduce((roles, role) => {
        return {
            ...roles,
            [role.role]: role.label
        }
    }, {})
    const rolesForSelect = roles.map(role => {
        return {
            label: `${role.label} ${role.admin ? "(Админ)" : ""}`,
            value: role.role,
            role: role
        }
    })
    return (
        <>
            <UserTable rolesForSelect={rolesForSelect} roles={rolesByName} reloadPage={reloadPage} updatedIndex={updatedIndex} setEditUser={setEditUser} />
            <UpdateUserModal setEditUser={setEditUser} editUser={editUser} reloadPage={reloadPage} setUpdatedIndex={setUpdatedIndex} setReloadPage={setReloadPage} roles={rolesForSelect} />
        </>
    );
};

export default UsersPage;