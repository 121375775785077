import { message } from "antd";
import { useEffect, useState } from "react";
import { getRoutes, getSubroutes } from "./api";

export const useLoadRoutes = () => {
    const [subroutes, setSubroutes] = useState([]);
    const [routes, setRoutes] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const loadRoutes = async () => {
            try {
                setLoading(true);
                const routes = await getRoutes();
                const subroutes = await getSubroutes();
                setRoutes(routes.data);
                setSubroutes(subroutes.data);
            } catch (error) {
                message.error("Не удалось загрузить пути")
            } finally {
                setLoading(false);


            }
        }
        loadRoutes();
    }, [])

    return [
        routes,
        subroutes,
        loading
    ]
}



