import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./LoginPage.scss";
import { Form, Input, Button, Checkbox, Image, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import UserService from "../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setUser, signIn, signOut } from "../store/userSlice";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const formRef = React.createRef();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if (username !== null && password !== null) {
      formRef.current.setFieldsValue({
        username,
        password,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    UserService.signIn(values.username, values.password)
      .then((response) => {
        dispatch(signIn(response.data));
        if (values.remember) {
          localStorage.setItem("username", values.username);
          localStorage.setItem("password", values.password);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
        UserService.getUserInfo().then((response) => {
          dispatch(setUser(response.data.user));
          if (response.data.user?.capabilities.includes("s_admin")) {
            navigate("/portal-services");
          } else {
            navigate("/portal-dashboard");
          }
        });
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {}, [user.authorized]);
  return (
    <div className="signin">
      <Form
        name="normal_username"
        className="username-form signin__form"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        ref={formRef}
      >
        <div className="signin__image-container">
          <div className="signin__image-frame">
            <Image preview={false} src={logo} alt="logo" />
          </div>
          <div className="signin__image-description">Портал</div>
        </div>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Вход
        </Typography.Title>

        <Form.Item
          name="username"
          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
          help={error ? "Проверьте правильность данных" : undefined}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail"
            // value={isLoginAvailable ? username : ""}
          />
        </Form.Item>

        <Form.Item
          name="password"
          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
          help={error ? "Проверьте правильность данных" : undefined}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
            // value={isPassAvailable ? password : ""}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="username-form-button"
            loading={loading}
          >
            Войти
          </Button>
          Или <a onClick={() => navigate("/sign-up")}>зарегистрироваться</a>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
