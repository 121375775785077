import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, message, Modal, Select, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import TasksService from '../../../service/TasksService';
import "./CreateTaskModal.scss"


const CreateTaskModal = ({ task, visible, setVisible, users, groups, setGroups, addTask }) => {
    const [groupName, setGroupName] = useState("");
    const [loading, setLoading] = useState(false)
    const [userGroup, setUserGroup] = useState(true);
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    function sendForm() {
        form
            .validateFields()
            .then((values) => {
                console.log();
                setLoading(true)
                TasksService.createTask({ ...values }).then((response) => {
                    console.log("create task", response.data);
                    addTask(response.data)
                    message.success("Задачу успешно создана.");
                    setVisible(false);

                }).catch((err) => {
                    console.log("Create task error", err);
                    message.error("Не удалось создать задачу.")
                }).finally(() => {
                    setLoading(false);
                })

            })
            .catch((error) => {
                console.log("Validate Failed:", error);
            });
    }
    useEffect(() => {
        if (visible) {
            setGroupName("");
            form.setFieldsValue(task);

        }
    }, [visible])


    const createGroup = (e) => {
        e.preventDefault();
        TasksService.createGroup({
            "title": groupName,
            "type": userGroup ? "user" : "public",
        }).then(response => {
            console.log("data", response);
            setGroupName("")
            setGroups([...groups, response.data])
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }).catch(err => {
            console.log("create group err", err);
            message.error("Не удалось создать группу.");
        })
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>{task?.create ? "Создать задачу" : "Редактировать задачу"}</Typography.Title>
            }
            centered
            open={visible}
            onOk={sendForm}
            okText={task?.create ? "Создать" : "Редактировать"}
            onCancel={() => setVisible(false)}
            closable={false}
            destroyOnClose
            forceRender
            width={window.screen.width < 450 ? "95%" : 500}
            confirmLoading={loading}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}
        >
            <Form
                name="task"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}

                layout="vertical"
            >
                <Form.Item
                    label="Группа"
                    name="task_group_id"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        filterOption={(inputValue, option) => {
                            return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                        }}
                        options={groups.map(group => {
                            return { label: group.title, value: group.id }
                        })}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder="Создать группу"
                                        ref={inputRef}
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={createGroup}>
                                        Создать группу
                                    </Button>
                                    <Checkbox checked={userGroup} onChange={(e) => setUserGroup(e.target.checked)}>Личная</Checkbox>
                                </Space>
                            </>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Имя задачи"
                    required
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Описание"

                    name="description"
                    rules={[
                        {
                            required: false,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    label="Комментарий"
                    name="comment"
                    rules={[
                        {
                            required: false,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Постановщики"
                    name="directors"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(inputValue, option) => {
                            return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                        }}
                        options={users.map(user => {
                            return { label: user.name, value: user.id }
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label="Ответственные"
                    name="executors"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(inputValue, option) => {
                            return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                        }}
                        options={users.map(user => {
                            return { label: user.name, value: user.id }
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label="Соисполнители"
                    name="responsibles"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(inputValue, option) => {
                            return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                        }}
                        options={users.map(user => {
                            return { label: user.name, value: user.id }
                        })}
                    />
                </Form.Item>

            </Form>

        </Modal >
    );
};

export default CreateTaskModal;