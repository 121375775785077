import React, { useMemo } from 'react';
import "./FormItem.scss"
const FormItem = ({ labels, children, style }) => {
    console.log();

    const width = useMemo(() => {
        return (95 / children.length) + "%";
    }, [children.length])
    return (

        <div className='form-row' style={children.length > 1 ? { ...style ? style : undefined, display: "flex", flexDirection: "row", justifyContent: "space-between" } : { ...style ? style : undefined }}>
            {children.length ? children.map((children, index) => {

                return <div key={index} className='form-row__item' style={{ width: width }}>
                    <div className='form-row__item__label'>{labels[index]}
                    </div>
                    {children}
                </div>
            })
                :
                <div className='form-row__item' style={{ width: width }}>
                    <div className='form-row__item__label'>{labels}
                    </div>

                    {children}
                </div>
            }
        </div >
    );
};

export default FormItem;