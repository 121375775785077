import { useSelector } from "react-redux";
import { selectUserIsAdmin } from "../model";
import { ReactNode } from "react";

interface IAdminAccess {
    children: ReactNode
}

const AdminAccess = ({
    children
}: IAdminAccess) => {
    const isAdmin = useSelector(selectUserIsAdmin);
    if (isAdmin)
        return children;
    return <>
    </>

};

export default AdminAccess;