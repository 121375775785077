import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ColorPick from './ColorPick';

const ColorSelection = ({
    preset,
    updatePreset
}) => {
    const addSelection = () => {
        updatePreset([["selectionData", [...preset?.selectionData ?? [], {
            "id": moment().unix(),
            "field": null,
            "value": 0,
            "color_more": "#55B156",
            "color_less": "#F90A23",
            // "color_equal": "#504E4E"
        }]]])
    }
    const deleteSelection = (id) => {
        updatePreset([["selectionData", preset?.selectionData.filter(item => item.id !== id)]])
    }

    const selectField = (id, object) => {
        updatePreset([["selectionData", preset?.selectionData?.map(item => {
            if (item.id === id) {
                return { ...item, field: object }
            }
            return item;
        })]])
    }
    const setValue = (id, event) => {

        const value = event.target.value;
        const reg = /^\d*(\.\d*)?$/;
        if (reg.test(value)) {
            updatePreset([["selectionData", preset?.selectionData?.map(item => {
                if (item.id === id) {
                    return { ...item, value }
                }
                return item;
            })]])
        }
    }
    const setColor = (id, type, color) => {
        updatePreset([["selectionData", preset?.selectionData?.map(item => {
            if (item.id === id) {
                return { ...item, [type]: color.hex }
            }
            return item;
        })]])
    }

    const getSelectOptions = () => {
        return preset?.subroute?.out_values.filter(option => {
            if (!preset?.outValues?.includes(option.value) || preset?.selectionData.find(item => item?.field?.value === option.value)) {
                return false;
            }
            return true;
        })
    }
    return <>
        {<div>
            <div>
                {<Button onClick={addSelection}>Добавить</Button>}
            </div>
            {<>{preset?.selectionData?.map(selection => {
                return <div key={selection.id}>
                    <div style={{ display: "flex", marginTop: 10 }}>

                        <Select
                            placeholder="Поле"
                            style={{ minWidth: "70%" }}
                            value={selection.field}
                            onChange={(field, object) => selectField(selection.id, object)}
                            options={getSelectOptions()}
                        />
                        <Input onChange={e => setValue(selection.id, e)} style={{ marginLeft: 10 }} value={selection.value} placeholder='Предел' />
                        <Button onClick={() => deleteSelection(selection.id)} danger type="link" style={{ marginLeft: 10, width: 50 }} icon={<DeleteOutlined />} />
                    </div>
                    <div style={{ display: "flex", marginTop: 10 }}>
                        <ColorPick title={"Больше"} color={selection.color_more} setColor={(color) => setColor(selection.id, "color_more", color)} />
                        {/* <ColorPick title={"Равно"} color={selection.color_equal} setColor={(color) => setColor(selection.id, "color_equal", color)} /> */}
                        <ColorPick title={"Меньше"} color={selection.color_less} setColor={(color) => setColor(selection.id, "color_less", color)} />
                    </div>
                </div>
            })}</>}
        </div>}
    </>
};

export default ColorSelection;