import React from "react";
import { MaskedInput } from "antd-mask-input";

const DynamicPhone = (props) => {
    const cellphoneMask = "(00) 0 0000-0000";
    const phoneMask = "+7 (000) 000-00-00";

    // always memoize dynamic masks
    const mask = React.useMemo(
        () => [
            {
                mask: cellphoneMask,
                lazy: false,
            },
            {
                mask: phoneMask,
                lazy: false,
            },
        ],
        []
    );

    return (
        <MaskedInput
            {...props}
            mask={mask}
            value={props.value}
            maskOptions={{
                dispatch: function (appended, dynamicMasked) {
                    const isCellPhone = dynamicMasked.unmaskedValue[2] === "9";
                    return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                },
            }}
        />
    );
};
export default DynamicPhone;