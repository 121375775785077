import { Input } from 'antd';
import React from 'react';
import { useUpdatePreset } from '../../../../entities/preset/models';
import FormItem from '../FormItem';

const InputName = ({ preset }) => {
    const updatePreset = useUpdatePreset(preset);

    const onChange = (e) => {
        const value = e.target.value;
        updatePreset([["name", value]])

    }

    return (
        <FormItem labels={"Название"}>
            <Input style={{ width: "100%" }} value={preset?.name} onChange={onChange} />
        </FormItem>

    );
};

export default InputName;