import React, { useEffect } from "react";
import GroupChart from "./GroupChart";
import { useLoadPresetData } from "../models";
import { useState } from "react";
import FiltersItem from "../../../features/ModifyPressetModal/ui/InputField/FiltersItem";
import FormItem from "../../../features/ModifyPressetModal/ui/FormItem";
import FiltersSelect from "../../../features/ModifyPressetModal/ui/PreparedFields/FiltersSelect";

const getExpandedPreset = (preset, row) => {
    const newPreset = JSON.parse(JSON.stringify(preset));
    if (preset?.grouped?.length > 1) {
        if (newPreset.filters)
            newPreset.filters.push({
                field: newPreset.grouped[0],
                value: row["id"],
                comparison: "=",
                condition: "and",
            });
        else {
            newPreset.filters = [
                {
                    field: newPreset.grouped[0],
                    value: row["id"],
                    comparison: "=",
                    condition: "and",
                },
            ];
        }
        newPreset.full_grouped = newPreset.full_grouped
            ? newPreset.full_grouped
            : [...newPreset.grouped];
        newPreset.grouped.shift();
        return newPreset;
    }
    if (newPreset.filters)
        newPreset.filters.push({
            field: newPreset.grouped[0],
            value: row["id"],
            comparison: "=",
            condition: "and",
        });
    else {
        newPreset.filters = [
            {
                field: newPreset.grouped[0],
                value: row["id"],
                comparison: "=",
                condition: "and",
            },
        ];
    }

    newPreset.grouped = null;
    newPreset.isLastLayer = true;
    return newPreset;
    //return preset;
};

const ExpandedGroupRow = ({
    index,
    period,
    routes,
    layer,
    row,
    parentColumns,
    groups,
    expanded,
    preset,
}) => {
    const [newPreset, setNewPreset] = useState({});
    const { data, setData, loading, error, modificators, refreshFunc } =
        useLoadPresetData({ preset: newPreset, noBreak: true }) ?? {};

    useEffect(() => {
        if (expanded) {
            setNewPreset(getExpandedPreset(preset, row));
            refreshFunc();
        }
    }, [expanded]);
    return (
        <>
            {data?.dataSource?.[0]?.["group_name"] && (
                <div
                    style={{
                        fontSize: 14,
                        marginBottom: 10,
                        fontWeight: 500,
                    }}
                >
                    {data?.dataSource?.[0]?.["group_name"]}
                </div>
            )}

            <GroupChart
                isExpanded={true}
                loading={loading}
                preset={newPreset}
                onRowClick={data?.link}
                layer={layer + 1}
                data={data.dataSource ?? []}
                columns={
                    !preset?.grouped?.length || preset?.grouped?.length < 2
                        ? data?.columns
                        : parentColumns ?? data?.columns
                }
                modificators={modificators}
                selectionData={preset?.selectionData}
                groups={preset?.grouped}
            />
        </>
    );
};

export default ExpandedGroupRow;
