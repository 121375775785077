import {
  CheckCircleTwoTone,
  CloseOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Popconfirm,
  Popover,
  Result,
  Select,
  Space,
  Typography,
  message,
  notification,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FileUpload from "../../../components/FileUpload/FileUpload";
import TasksService from "../../../service/TasksService";
import { setTasksIds } from "../../../store/layoutSlice";
import Deadline from "../Deadline/Deadline";
import UserSelect from "../UserSelect";
import "./Task.scss";

const RawHTML = ({ children, className = "" }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }}
  />
);
const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;
const StyledResult = styled(Result)`
  .ant-result-icon {
    margin-bottom: 24px;
  }
  .ant-result {
    margin: 10px;
  }
`;
const Task = ({
  task,
  files,
  sendToBitrix,
  tasks,
  users,
  updateTask,
  setTasks,
  setChecked,
  groupingTasks,
  setGroupFilter,
  checked,
  groups,
  setGroups,
  addTask,
  setTargetTask,
  deleteTask,
  user,
  setMovedTask,
  movedTask,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [sendLoad, setSendLoad] = useState(false);
  const [directors, setDirectors] = useState(null);
  const [responsibles, setResponsibles] = useState(null);
  const [executors, setExecutors] = useState(null);
  const [updateTitle, setUpdateTitle] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);
  const [comment, setComment] = useState(task.comment);
  const [userGroup, setUserGroup] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectGroup, setSelectGroup] = useState(null);
  const [moveSelectGroup, setMoveSelectGroup] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const [showMe, setShowMe] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const navigate = useNavigate();
  const [moveState, setMoveState] = useState(false);
  const [multiTaskForRespon, setMultiTaskForRespon] = useState(false);
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    group: false,
    directors: false,
    executors: false,
    responsibles: false,
  });
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();

  const updateTask_ = (task_) => {
    if (!task?.create || task?.move) {
      TasksService.updateTask({ ...task_, create: undefined, move: undefined })
        .then((response) => {
          if (task_?.move) {
            setOpenPopover(false);
            setMoveState(true);

            timeoutRef.current = setTimeout(() => {
              setMoveState(false);
              const newTasks = tasks.filter((task) => {
                if (task.id === task_.id) {
                  return task.task_group_id === task_.task_group_id;
                }
                return true;
              });
              setTasks(newTasks);
              dispatch(setTasksIds(newTasks.map((item) => item.id)));
              groupingTasks(newTasks);

              // updateTask(response.data);
            }, 3000);
          } else {
            updateTask(response.data);
          }
        })
        .catch((err) => {
          message.error("Не удалось обновить задачу");
        });
    }
  };

  const syncTask = (e) => {
    e.stopPropagation();
    sendToBitrix([task.id]);
    // setSendLoad(true);
    // TasksService.syncTasks([task.id]).then(response => {
    //     if (response.data[task.id] != null)
    //         updateTask({ ...task, sync: 1 })
    //     else {
    //         throw "error"
    //     }
    // }).catch(err => {
    //     message.error("Не удалось отправить в Bitrix задачу")
    // }).finally(() => {
    //     setSendLoad(false);
    // })
  };
  useEffect(() => {
    if (!task?.create && !task?.move)
      if (
        title !== task.title ||
        description !== task.description ||
        comment !== task.comment
      )
        updateTask_({
          ...task,
          title: title,
          description: description,
          comment: comment,
        });
  }, [title, description, comment]);
  const updateChecked = () => {
    if (!task?.create)
      if (checked.includes(task.id)) {
        setChecked(checked.filter((item) => item !== task.id));
      } else {
        setChecked([...checked, task.id]);
      }
  };
  useEffect(() => {
    if (task?.task_group_id != "new") {
      setSelectGroup(task.task_group_id);
    }
    setDeadline(task.deadline);
  }, [task]);

  const createGroup = (e) => {
    e.preventDefault();
    if (groupName != "")
      TasksService.createGroup({
        title: groupName,
        type: userGroup ? "user" : "public",
      })
        .then((response) => {
          setGroupName("");
          setGroups([...groups, response.data]);
          setTimeout(() => {
            inputRef.current?.focus();
          }, 0);
        })
        .catch((err) => {
          message.error("Не удалось создать группу.");
        });
  };

  const createTask = () => {
    let error = false;
    let errors_ = {
      title: false,
      description: false,
      group: false,
      directors: false,
      executors: false,
      responsibles: false,
    };

    if (title == "") {
      errors_.title = true;
      error = true;
    }
    if (description == "") {
      errors_.description = true;
      error = true;
    }
    if (directors == null) {
      errors_.directors = true;
      error = true;
    }

    if (responsibles == null) {
      errors_.responsibles = true;
      error = true;
    }
    if (selectGroup == null) {
      errors_.group = true;
      error = true;
    }
    setErrors(errors_);

    if (!error) {
      if (multiTaskForRespon) {
        responsibles.forEach((responsible) => {
          TasksService.createTask({
            title: title,
            description: description,
            task_group_id: selectGroup,
            executors:
              executors.length === 0
                ? null
                : executors.map((user) => user.id).join(","),
            directors: directors.id,
            responsibles: responsible.id,
            deadline: deadline,
            comment: comment,
          })
            .then((response) => {
              addTask({ ...task, ...response.data, create: undefined });
              message.success("Задача успешно создана.");
              setTargetTask(null);
            })
            .catch((err) => {
              console.log("Create task error", err);
              message.error("Не удалось создать задачу.");
            })
            .finally(() => {});
        });
      } else {
        TasksService.createTask({
          title: title,
          description: description,
          task_group_id: selectGroup,
          executors:
            executors.length === 0
              ? null
              : executors.map((user) => user.id).join(","),
          directors: directors.id,
          responsibles: responsibles.id,
          deadline: deadline,
          comment: comment,
        })
          .then((response) => {
            console.log("create task", response.data);
            addTask({ ...task, ...response.data, create: undefined });
            message.success("Задача успешно создана.");
            setTargetTask(null);
          })
          .catch((err) => {
            console.log("Create task error", err);
            message.error("Не удалось создать задачу.");
          })
          .finally(() => {});
      }
    }
  };
  const deleteTaskConfirm = () => {
    console.log(task);
    if (task.id === 0 || task?.create || task?.move) {
      console.log("Delete task new");
      setTargetTask(null);
    } else
      TasksService.deleteTask(task.id)
        .then(() => {
          deleteTask(task.id);
          message.success("Задача успешно удалена.");
        })
        .catch((err) => {
          message.error("Не удалось удалить задачу.");
        });
  };
  // https://vakio.bitrix24.ru/company/personal/user/327/tasks/task/view/199321/
  const goToTask = () => {
    if (task.sync) {
      window.open(
        `https://vakio.bitrix24.ru/company/personal/user/${user.bitrix_id}/tasks/task/view/${task.bitrix_id}/`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const moveTask = () => {
    if (moveSelectGroup) {
      updateTask_({
        ...task,
        title: title,
        description: description,
        comment: comment,
        task_group_id: moveSelectGroup,
        move: true,
        transfer_count: task.transfer_count + 1,
      });
    }
  };
  const moveTaskAdmin = () => {
    if (moveSelectGroup) {
      updateTask_({
        ...task,
        title: title,
        description: description,
        comment: comment,
        task_group_id: moveSelectGroup,
        move: true,
      });
    }
  };

  const moveToTask = () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current);
    }
    navigate({
      pathname: "/portal-tasks",
      search: "?group_id=" + moveSelectGroup,
    });
    setMovedTask(task.id);

    setGroupFilter(moveSelectGroup);
    setMoveState(false);
  };

  useEffect(() => {
    if (movedTask === task.id) {
      setShowMe(true);
      setTimeout(() => {
        setShowMe(false);
        setMovedTask(-1);
      }, 1500);
    }
  }, [movedTask]);
  return (
    <div className="task">
      <div style={{ position: "relative", width: "100%", display: "flex" }}>
        {moveState && (
          <div className="task__overbg">
            <Result
              className="task__overbg__result"
              status="success"
              title="Задача успешно перемещена"
              subTitle={`${
                groups.find((group) => group.id === selectGroup)?.title
              } => ${
                groups.find((group) => group.id === moveSelectGroup)?.title
              }`}
              extra={[
                <Button type="primary" key="go-to" onClick={moveToTask}>
                  Перейти к задаче
                </Button>,
              ]}
            />
          </div>
        )}
        {showMe && <div className="task__overbg showme"></div>}
        <div
          className="task__info"
          style={
            task?.result
              ? {
                  borderLeft: "4px solid #52c41a",
                }
              : {}
          }
          onClick={updateChecked}
        >
          <div>
            <div
              className="task__info__header"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="task__info__header__title">
                {!task?.create &&
                  (user?.portal_isAdmin || user?.portal_isSupervisor) && (
                    <Checkbox
                      checked={checked.includes(task.id)}
                      onChange={updateChecked}
                      style={{ marginRight: 10 }}
                    />
                  )}

                <div
                  style={{ width: "100%" }}
                  onBlur={() => setEditTitle(false)}
                >
                  {task?.create ? (
                    <>
                      <Input
                        status={errors.title ? "error" : undefined}
                        placeholder="Имя задачи"
                        value={title}
                        onChange={(e) => {
                          setErrors({ ...errors, title: false });
                          setTitle(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {
                        <Typography.Text
                          className="task__info__header__title__text"
                          ellipsis={{ symbol: "more" }}
                          onClick={() => goToTask()}
                          editable={
                            !user?.portal_isAdmin &&
                            !user?.portal_isSupervisor &&
                            task.created_by !== user.id
                              ? undefined
                              : {
                                  icon: (
                                    <EditOutlined
                                      style={{ opacity: 0.3 }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEditTitle(true);
                                      }}
                                    />
                                  ),
                                  editing: task?.create || editTitle,
                                  onChange: (text) => setTitle(text),
                                  onEnd: () => setEditTitle(false),
                                  onBlur: () => setEditTitle(false),
                                  onCancel: () => {
                                    console.log("CANCEL!");
                                    setEditTitle(false);
                                    setTitle(task.title);
                                  },
                                }
                          }
                        >
                          {title}
                        </Typography.Text>
                      }
                    </>
                  )}
                </div>
              </div>
              {!task?.create && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {task.sync ? (
                    <div className="task__info__header__sync">
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </div>
                  ) : (
                    <div className="task__info__header__no-sync">
                      <div style={{ marginRight: 4 }}>{"Не отправлено"}</div>
                      <CheckCircleTwoTone twoToneColor="#f1d03d" />
                    </div>
                  )}

                  <StyledPopover
                    trigger="click"
                    //overlayClassName={styles.popoverNoPadding}
                    open={openPopover}
                    onOpenChange={(state) => {
                      if (
                        user?.portal_isAdmin ||
                        user?.portal_isSupervisor ||
                        task.created_by === user.id
                      )
                        setOpenPopover(state);
                    }}
                    content={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Text
                          style={{ marginBottom: 5, fontWeight: "500" }}
                        >
                          Перенести задачу
                        </Typography.Text>
                        <Select
                          value={moveSelectGroup}
                          onChange={(value) => {
                            setErrors({ ...errors, group: false });
                            setMoveSelectGroup(value);
                          }}
                          placeholder="Событие"
                          style={{ width: 170, paddingBottom: 5 }}
                          status={errors.group ? "error" : undefined}
                          showSearch
                          filterOption={(inputValue, option) => {
                            return option.label
                              ?.toLowerCase()
                              .includes(inputValue?.toLowerCase());
                          }}
                          options={groups
                            .filter((item) => item.id != "new")
                            .map((group) => {
                              return { label: group.title, value: group.id };
                            })}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Создать группу"
                                  ref={inputRef}
                                  value={groupName}
                                  onChange={(e) => setGroupName(e.target.value)}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={createGroup}
                                ></Button>
                              </Space>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Checkbox
                                  style={{ marginLeft: 10 }}
                                  checked={userGroup}
                                  onChange={(e) =>
                                    setUserGroup(e.target.checked)
                                  }
                                >
                                  Личная
                                </Checkbox>
                              </div>
                            </>
                          )}
                        />
                        <Button type="primary" onClick={moveTask}>
                          Перенести
                        </Button>
                        {user?.portal_isAdmin && (
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={moveTaskAdmin}
                          >
                            Перенос без учета
                          </Button>
                        )}
                      </div>
                    }
                    placement="bottom"
                  >
                    <Button
                      icon={<MoreOutlined size={"large"} />}
                      type="link"
                    />
                  </StyledPopover>
                </div>
              )}
              {task?.create && (
                <Select
                  value={selectGroup}
                  onChange={(value) => {
                    setErrors({ ...errors, group: false });
                    setSelectGroup(value);
                  }}
                  placeholder="Событие"
                  style={{ width: 170, paddingBottom: 5, marginLeft: 10 }}
                  status={errors.group ? "error" : undefined}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option.label
                      ?.toLowerCase()
                      .includes(inputValue?.toLowerCase());
                  }}
                  options={groups
                    .filter((item) => item.id != "new")
                    .map((group) => {
                      return { label: group.title, value: group.id };
                    })}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Создать группу"
                          ref={inputRef}
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={createGroup}
                        ></Button>
                      </Space>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Checkbox
                          style={{ marginLeft: 10 }}
                          checked={userGroup}
                          onChange={(e) => setUserGroup(e.target.checked)}
                        >
                          Личная
                        </Checkbox>
                      </div>
                    </>
                  )}
                />
              )}
            </div>
            {task?.create ? (
              <TextArea
                status={errors.description ? "error" : undefined}
                style={{ marginTop: 7, marginBottom: 10 }}
                placeholder="Описание задачи"
                rows={4}
                value={description}
                onChange={(e) => {
                  setErrors({ ...errors, description: false });
                  setDescription(e.target.value);
                }}
              />
            ) : (
              <Typography.Paragraph
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="task__info__description"
                status={errors.description ? "error" : undefined}
                editable={
                  !user?.portal_isAdmin &&
                  !user?.portal_isSupervisor &&
                  task.created_by !== user.id
                    ? undefined
                    : {
                        icon: <EditOutlined style={{ opacity: 0.3 }} />,
                        editing: task?.create,
                        onChange: (text) => setDescription(text),
                        onCancel: () => {
                          console.log("CANCEL!");
                          setDescription(task.description);
                        },
                      }
                }
              >
                {description}
              </Typography.Paragraph>
            )}
            {task?.create ? (
              <TextArea
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ marginTop: 7, marginBottom: 10 }}
                placeholder="Комментарий"
                rows={2}
                value={comment}
                onChange={(e) => {
                  setErrors({ ...errors, description: false });
                  setComment(e.target.value);
                }}
              />
            ) : (
              <div
                style={{
                  display: !editComment ? "flex" : null,
                  flexDirection:
                    (comment == null || comment == "") &&
                    (!task.sync || user.portal_isAdmin)
                      ? "row"
                      : "column",
                  width: "100%",
                  marginTop: 10,
                  marginLeft: 10,
                  justifyContent: !editComment ? "flex-start" : null,
                }}
                onBlur={() => setEditComment(false)}
              >
                {editComment ? null : task.sync && !user.portal_isAdmin ? (
                  comment == null || comment == "" ? null : (
                    <div
                      style={{
                        color: "#9e9e9e",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {"Комментарий:"}
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      color: "#9e9e9e",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {`${
                      comment == null || comment == ""
                        ? "Добавить комментарий"
                        : "Комментарий: "
                    }`}{" "}
                  </div>
                )}
                <Typography.Paragraph
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  editable={
                    task.sync &&
                    !user.portal_isAdmin &&
                    task.created_by !== user.id
                      ? undefined
                      : {
                          icon: <EditOutlined style={{ opacity: 0.3 }} />,
                          onStart: () => {
                            console.log("START EDIT");
                            setEditComment(true);
                          },
                          onEnd: () => {
                            console.log("END EDIT");
                            setEditComment(false);
                          },
                          editing: task?.create,
                          onChange: (text) => setComment(text),
                          onCancel: () => {
                            setEditComment(false);
                            console.log("CANCEL!");
                            setDescription(task.comment);
                          },
                        }
                  }
                >
                  {comment}
                </Typography.Paragraph>
              </div>
            )}
            {task?.result && (
              <div style={{ marginLeft: 10 }}>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 14,
                    color: "#1fbe1fee",
                    fontWeight: 500,
                  }}
                >
                  Результат:
                </div>
                <RawHTML>{task?.result}</RawHTML>
              </div>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="task__info__files">
              {files && (
                <FileUpload
                  task_id={task.id}
                  files={files.map((file) => {
                    return {
                      ...file,
                      name: file.filename,
                      status: "done",
                    };
                  })}
                  type="mini"
                  disabled={
                    !user?.portal_isAdmin &&
                    !user?.portal_isSupervisor &&
                    !task.create
                  }
                />
              )}
            </div>
            {task?.create === true ? (
              <>
                {task?.move === true ? (
                  <Button
                    onClick={() =>
                      updateTask_({
                        ...task,
                        title: title,
                        description: description,
                        comment: comment,
                        task_group_id: selectGroup,
                      })
                    }
                    type="primary"
                    shape="round"
                  >
                    Перенести
                  </Button>
                ) : (
                  <Button onClick={createTask} type="primary" shape="round">
                    Создать
                  </Button>
                )}
              </>
            ) : (
              <>
                {(user?.portal_isAdmin ||
                  user?.portal_isSupervisor ||
                  task.created_by === user.id) && (
                  <Button loading={sendLoad} onClick={syncTask} shape="round">
                    Отправить в Bitrix
                  </Button>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className="task__users"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(user?.portal_isAdmin ||
            task?.create ||
            task.created_by === user.id ||
            (task.sync != 1 &&
              (user?.portal_isAdmin ||
                user?.portal_isSupervisor ||
                task.id === 0))) && (
            <div className="task__users__delete">
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                placement="topRight"
                type=""
                title={"Удалить задачу?"}
                onConfirm={deleteTaskConfirm}
                okText="Да"
                cancelText="Отмена"
              >
                <Button
                  size="large"
                  icon={<CloseOutlined />}
                  type="link"
                  danger
                  shape="circle"
                />
              </Popconfirm>
            </div>
          )}
          <Deadline
            disabled={
              !user?.portal_isAdmin &&
              !user?.portal_isSupervisor &&
              !task.create &&
              task.created_by !== user.id
            }
            deadline={deadline}
            setDeadline={setDeadline}
            create={task?.create}
            updateTask={updateTask_}
            task={task}
          />
          <div
            className="task__users__item"
            style={{
              borderBottom: errors.directors ? "1px solid #ff4d4f" : undefined,
            }}
          >
            <Typography.Text className="task__users__item__label">
              Постановщик:
            </Typography.Text>

            <UserSelect
              multiple={false}
              disabled={
                !user?.portal_isAdmin &&
                !user?.portal_isSupervisor &&
                !task.create &&
                task.created_by !== user.id
              }
              error={errors.directors}
              users={users}
              key_select={"directors"}
              task={task}
              updateTask={updateTask_}
              selectedUsers={directors}
              setSelectedUsers={(value) => {
                setErrors({ ...errors, description: false });
                setDirectors(value);
              }}
              rawUsers={task.directors}
            />
          </div>
          <div
            className="task__users__item"
            style={{
              borderBottom: errors.responsibles
                ? "1px solid #ff4d4f"
                : undefined,
            }}
          >
            <Typography.Text className="task__users__item__label">
              Ответственные:
            </Typography.Text>
            <UserSelect
              multiple={task?.create && multiTaskForRespon}
              disabled={
                !user?.portal_isAdmin &&
                !user?.portal_isSupervisor &&
                !task.create &&
                task.created_by !== user.id
              }
              error={errors.responsibles}
              users={users}
              key_select={"responsibles"}
              task={task}
              updateTask={updateTask_}
              selectedUsers={responsibles}
              setSelectedUsers={(value) => {
                setErrors({ ...errors, responsibles: false });
                setResponsibles(value);
              }}
              rawUsers={task.responsibles}
            />
          </div>
          <div
            className="task__users__item"
            style={{
              borderBottom: errors.executors ? "1px solid #ff4d4f" : undefined,
            }}
          >
            <Typography.Text className="task__users__item__label">
              Соисполнители:
            </Typography.Text>
            <UserSelect
              multiple={true}
              disabled={
                !user?.portal_isAdmin &&
                !user?.portal_isSupervisor &&
                !task.create &&
                task.created_by !== user.id
              }
              error={errors.executors}
              users={users}
              key_select={"executors"}
              task={task}
              updateTask={updateTask_}
              selectedUsers={executors}
              setSelectedUsers={(value) => {
                setErrors({ ...errors, executors: false });
                setExecutors(value);
              }}
              rawUsers={task.executors}
            />
          </div>

          {task?.create && (
            <Checkbox
              style={{ marginTop: 20 }}
              checked={multiTaskForRespon}
              onChange={(e) => {
                if (responsibles) {
                  console.log(
                    "multiTaskForRespon",
                    multiTaskForRespon ? responsibles[0] : [responsibles]
                  );
                  setResponsibles(
                    multiTaskForRespon ? responsibles[0] : [responsibles]
                  );
                }
                setMultiTaskForRespon(e.target.checked);
                console.log(e.target.checked);
              }}
            >
              Новая задача для каждого ответственного
            </Checkbox>
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
