import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const selectDashboards = (state) => state.dashboard.dashboards;
export const selectDashboardId = (state) => state.dashboard.dashboard;
export const selectUpdatingDashboard = (state) =>
  state.dashboard.updatingDashboard;
export const selectCopingDashboard = (state) => state.dashboard.copingDashboard;
export const selectCurrentDashboard = (state) => {
  const dashboard = state.dashboard.dashboard;
  return state.dashboard.dashboards.find((item) => item.id === dashboard);
};
export const selectTargetPreset = (state) => state.dashboard.targetPreset;

export const selectModalRequestData = (state) =>
  state.dashboard.modalRequestData;
export const selectModalPreset = (state) => state.dashboard.modalPreset ?? {};

export const selectEditeModeDashboard = (state) => state.dashboard.editeMode;

export const useRerenderPresetId = () =>
  useSelector(
    createSelector(
      (state) => state.dashboard,
      (dashboard) => dashboard.rerenderId
    )
  );
