import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../store/layoutSlice';
import DealerModal from './components/DealerModal/DealerModal';
import DealersTable from './components/DealerTable/DealersTable';
import "./DealersPage.scss"
const DealersPage = () => {
    const [visible, setVisible] = useState(false)
    const [dealers, setDealers] = useState([])
    const [editDealer, setEditDealer] = useState({
        name: "",
        address: "",
        phone: ""
    })

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle(""));
    })
    return (
        <div>
            <DealersTable setDealers={setDealers} dealers={dealers} setEditDealer={setEditDealer} updateModalVisible={visible} setUpdateModalVisible={setVisible} />
            <DealerModal setDealers={setDealers} dealers={dealers} setVisible={setVisible} visible={visible} editDealer={editDealer} />
        </div>
    );
};

export default DealersPage;