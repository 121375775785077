import React from 'react';
import { getModifiedStyle, parseModifiedValue } from '../lib/ParseModificators';

const Tooltip = ({ datum, label, modificators, selectionData }) => {
    return <div style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: 9,
        display: "flex",
        flexDirection: 'column'
    }}>
        <div style={{
            display: 'flex',
            marginRight: 10,
            justifyItems: "center"
        }}>

            <div style={{ fontSize: 15, fontWeight: 500, color: "#6f6f6f" }}>
                {datum.indexValue}:
            </div>

        </div>
        <div style={{
            alignItems: "center",
            justifyItems: "center",
            display: 'flex'
        }}>

            <div style={{
                fontSize: 15,
                fontWeight: 500, color: "#6f6f6f", marginRight: 10
            }}>
                {label}:
            </div>
            <div style={{
                textAlign: "center",
                fontSize: 15,
                ...getModifiedStyle(datum.value, datum.id, selectionData)
            }}>
                {parseModifiedValue(datum.value, datum.id, modificators)}
            </div>
        </div>

    </div >;
};

export default Tooltip;