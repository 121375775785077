import React, { useState } from 'react';
import { AddRow } from './AddRow';
import AddRowModal from './AddRowModal';
import "./RowModal.scss"

const AddRowModule = () => {
    const [addRowModalVisible, setRowModalVisible] = useState(false);
    const openAddRowModal = () => {
        setRowModalVisible(true);
    }

    return (
        <>
            <AddRow openAddRowModal={openAddRowModal} />
            <AddRowModal visible={addRowModalVisible} setVisible={setRowModalVisible} />
        </>
    );
};

export { AddRowModule };