import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useState } from 'react';

const Deadline = ({ deadline, editing, setDeadline, create, updateTask, task, disabled, offFocus }) => {
    const [updateDate, setUpdateDate] = useState(false);


    const updateValueDate = () => {
        console.log("UPDATE DATE",);
    }
    return (
        <div className='task__users__deadline'
            style={{ padding: 5, fontWeight: 500, fontSize: 14 }}
            onClick={() => {
                if (!updateDate)
                    setUpdateDate(true);
            }}>
            {(((updateDate && !offFocus) || create || editing) && !disabled) ?
                <DatePicker
                    placeholder='Без срока'



                    onBlur={() => {

                        // setUpdateDate(false);
                        // console.log(deadline);
                        // if (!offFocus)
                        //     updateTask({ ...task, deadline: deadline })
                    }}
                    disabledDate={(current) => {
                        const today = dayjs().toDate();
                        today.setHours(0);
                        today.setMinutes(0);
                        return (current.weekday() > 4 || today > current);
                    }}
                    // onOk={(value) => {
                    //     if (value != null) {

                    //         setDeadline(value.format("YYYY-MM-DDTHH:mm:ssZ"));
                    //         console.log("Set value", value.format("YYYY-MM-DDTHH:mm:ssZ"));
                    //     }
                    //     else {

                    //         setDeadline(value);
                    //     }
                    //     setUpdateDate(false);
                    //     if (!offFocus)
                    //         updateTask({ ...task, deadline: value != null ? value.format("YYYY-MM-DDTHH:mm:ssZ") : null })
                    // }}
                    showTime={{
                        showHour: true,
                        showMinute: true,
                    }}
                    onChange={(value) => {
                        setDeadline(value.format("YYYY-MM-DDTHH:mm:ssZ"));
                        setUpdateDate(false);

                        updateTask({ ...task, deadline: value != null ? value.format("YYYY-MM-DD HH:mm:ss") : null })
                    }}
                    value={deadline ? dayjs(deadline) : null}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    onSelect={(value) => {
                        console.log("Set value", value);
                        if (value != null)
                            setDeadline(value.format("YYYY-MM-DD HH:mm:ss"));
                        else
                            setDeadline(value);
                    }} />
                :
                <div style={{ display: "flex", alignItems: "center", color: deadline != null ? (dayjs() > dayjs(deadline, "YYYY-MM-DD HH:mm:ss") && !task?.result) ? "red" : "black" : "black" }}>
                    {task?.transfer_count && <Tooltip title="Количество переносов">

                        <div style={{
                            fontWeight: 400,
                            background: "#1890ff21",
                            width: 30,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: 10,
                            borderRadius: "50%"
                        }}>{task?.transfer_count}</div>
                    </Tooltip>}
                    {deadline != null ? dayjs(deadline, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY HH:mm") : "Без срока"}
                </div>}
        </div>
    );
};

export default Deadline;