import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Table, Typography, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DealersService from '../../../service/DealersService';
import { setLocations } from '../../../store/layoutSlice';
import "./DealersTable.scss";

const shortDaysStr = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
const fullDaysStr = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
// superadmin
const DealersTable = ({ setDealers, dealers, updateModalVisible, setUpdateModalVisible, setEditDealer }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [reloadPage, setReloadPage] = useState(true);
    const [editUser, setEditUser] = useState(undefined);
    const [updatedIndex, setUpdatedIndex] = useState(0);
    const layout = useSelector(state => state.layout)
    const [visibleFullTime, setVisibleFullTime] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true);
        DealersService.getLocations().then(response => {
            dispatch(setLocations(response.data))
            DealersService.getDealers().then(response => {

                setDealers(response.data.service_dealers)
            }).finally(() => setIsLoading(false))
        }).catch(() => setIsLoading(false))


    }, [])
    const getWorkingTimeString = (workinkgTime) => {
        let resultWeekdays = ""
        let resultWeekends = ""
        const mapedTime = JSON.parse(workinkgTime).map(day => {
            if (day !== null)
                return day[Object.keys(day)[0]];
            return day;
        })
        const checkEvenDay = mapedTime.filter(day => day !== null).filter((day, index, arr) => {
            if (day === null) return false;

            return day[0] === arr[0][0] && day[1] === arr[0][1]
        })
        const weekdays = mapedTime.slice(0, 5).filter(day => day !== null);
        const weekends = mapedTime.slice(5, 8).filter(day => day !== null);

        if (checkEvenDay.length === 7) {
            return { today: `Ежедневно с ${checkEvenDay[0][0]} до ${checkEvenDay[0][1]}` }
        } else {
            if (checkEvenDay.length === 0)
                return { today: `Не указано` }
        }

        const checkWeekdays = weekdays.filter((day, index, arr) => {
            if (day === null) return false;
            return day[0] === arr[0][0] && day[1] === arr[0][1]
        })

        const checkWeekends = weekends.filter((day, index, arr) => {
            if (day === null) return false;
            return day[0] === arr[0][0] && day[1] === arr[0][1]
        })

        if (checkWeekdays.length === 5) {
            resultWeekdays = `Будни с ${checkWeekdays[0][0]} до ${checkWeekdays[0][1]} \n`
        }
        if (checkWeekends.length === 2) {
            resultWeekends = `Выходные с ${checkWeekends[0][0]} до ${checkWeekends[0][1]}`
        }
        let byDays = [];
        let today = "";
        if (checkWeekdays.length !== 5 || checkWeekends.length !== 2) {
            const dayToday = moment().isoWeekday();
            const todayTime = mapedTime[dayToday - 1];
            if (todayTime !== null) {

                today = `Сегодня с ${todayTime[0]} до ${todayTime[1]} `
            } else {
                today = `Сегодня выходной`
            }
            byDays = mapedTime.map((day, index) => {
                if (day !== null)
                    return `${fullDaysStr[index]} с ${day[0]} до ${day[1]}`
                else
                    return `${fullDaysStr[index]} выходной`
            })
        }

        return { today: today, weekdays: resultWeekdays, weekends: resultWeekends, byDays: byDays };
    }
    const addTimeToVisible = (index) => {
        setVisibleFullTime([...visibleFullTime, index])
    }
    const removeTimeToVisible = (index) => {
        setVisibleFullTime(visibleFullTime.filter(idx => idx !== index))
    }

    const updateVisibleTime = (index) => {
        const found = visibleFullTime.findIndex(idx => idx === index);
        if (found === -1) {
            addTimeToVisible(index);
        } else {
            removeTimeToVisible(index);

        }
    }

    const deleteDealer = (id) => {

        DealersService.deleteDealer(id).then(() => {
            setDealers(dealers.filter(dealer => dealer.id !== id));
            message.success("Дилер успешно удалён")
        }).catch(error => {
            message.error("Не удалось удалить дилера")
        })
    }
    return (
        <Card
            title={<Typography.Title level={3}>Дилеры</Typography.Title>}
            style={{ marginBottom: 20 }}
            extra={
                <Button
                    type="default"
                    // shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setEditDealer(null);
                        setUpdateModalVisible(true);
                    }}
                >
                    {/* {window.screen.width > 450 && "Добавить пользователя"} */}
                    Добавить
                </Button>
            }
            bordered={false}
            className="card"
        >
            <Table
                pagination={false}
                // pagination={{
                //   defaultCurrent: 1,
                //   total: 500,
                //   pageSize: 500,
                // }}
                rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
                bordered={true}
                loading={isLoading}
                columns={[
                    {
                        title: "Город",
                        dataIndex: "location",
                        key: "location",
                        render: (loc_id) => {
                            const loc = layout.locations.find(loc => loc.id === Number(loc_id))
                            return <div>{loc.city.includes("(") ? loc.city : loc.city + ` (${loc.region})`}</div>
                        }
                    },
                    {
                        title: "Название компании",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "Адрес",
                        dataIndex: "address",
                        key: "address",
                        render: (text) => (<div>{text}</div>)
                    },
                    {
                        title: "Телефон",
                        dataIndex: "phone",
                        key: "phone",
                        width: 200,
                        render: (phones) => {
                            if (phones !== null) {
                                return phones.split(",").map((phone, index) => <div key={index}>{phone}</div>
                                )
                            }
                        }
                    },
                    {
                        title: "Время работы",
                        dataIndex: "working_time",
                        key: "working_time",
                        width: 250,
                        render: (text, full) => {
                            const time = getWorkingTimeString(text)
                            return (<>

                                <>
                                    {time.byDays?.length > 0 ?
                                        <>
                                            <div style={{ display: "flex" }}>
                                                {time.today !== "" && (<div>{time.today}</div>)}
                                                <Button type="link" icon={visibleFullTime.findIndex(index => index === full.id) !== -1 ?
                                                    <UpOutlined />
                                                    :
                                                    <DownOutlined />
                                                } size={"small"} onClick={() => updateVisibleTime(full.id)} />
                                            </div>
                                            {visibleFullTime.findIndex(index => index === full.id) !== -1 && time.byDays.map((day, index) => <div key={index}>{day}</div>)}
                                        </>
                                        :

                                        <>

                                            {time.today !== "" && (<div>{time.today}</div>)}
                                            {time.weekdays !== "" && (<div>{time.weekdays}</div>)}
                                            {time.weekends !== "" && (<div>{time.weekends}</div>)}
                                        </>
                                    }
                                </>

                            </>)
                        }
                    },
                    {
                        title: "Комментарий по компании",
                        dataIndex: "company_comment",
                        key: "company_comment",
                        render: (text) => (<div>{text}</div>)
                    },
                    {
                        title: "Внутренний комментарий",
                        dataIndex: "internal_comment",
                        key: "internal_comment",
                        render: (text) => (<div>{text}</div>)
                    },

                    {
                        title: "",
                        dataIndex: "action",
                        key: "action",
                        render: (_, full) => (
                            <Typography.Link

                                onClick={(e) => {
                                    setUpdateModalVisible(true)
                                    e.preventDefault();
                                    setEditDealer(full);
                                }}
                            >
                                <EditOutlined />
                            </Typography.Link>
                        ),
                    },
                    {
                        title: "",
                        dataIndex: "delete",
                        key: "delete",
                        render: (_, full) => (
                            <Popconfirm title="Удалить дилера"
                                placement="topRight"
                                onConfirm={() => deleteDealer(full.id)} okText="Удалить" cancelText="Отмена" >
                                <Typography.Link

                                    onClick={(e) => {

                                        e.preventDefault();

                                    }}
                                >
                                    <DeleteOutlined />
                                </Typography.Link>
                            </Popconfirm>
                        ),
                    },
                ]}
                dataSource={dealers ? dealers : []}
                scroll={{ x: 300 }}
                rowKey="id"
            />
        </Card>

    );
};

export default DealersTable;