import { dashboardApi, dashboardModel } from "@entities/dashboard";
import { message } from "antd";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

export const useUpdateDashboard = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false)
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);


    const dispatch = useDispatch()
    const onSubmit = (values: any) => {
        const newDashboard = {
            ...dashboard,
            ...values
        }
        setLoading(true)
        dashboardApi.updateDashboard(newDashboard.id, newDashboard).then((response: any) => {
            dispatch(dashboardModel.updateDashboards({ ...response.data, content: response.data.content }));
            message.success(`Панель ${response.data.title} успешно создана!`, 1)
            setVisible(false);
        }).catch((e: any) => {
            message.error(`Не удалось создать панель`, 0.5)
        })
            .finally(() => {
                setLoading(false)
            })
    }
    const onOpenModal = () => {
        setVisible(true)
    }
    const onCancel = () => {
        setVisible(false)
    }
    return {
        onSubmit,
        onOpenModal,
        visible,
        onCancel,
        dashboard,
        loading

    }
}