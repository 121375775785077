import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    headerTitle: '',
    headerSubtitle: "",
    headerButtonText: "",
    showHeaderTitle: false,
    showHeaderSubtitle: false,
    showHeaderButton: false,
    showHeaderTitleButton: false,
    headerDashboard: false,
    tasksIds: [],
    locations: []
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setHeaderTitle: (state, action) => {
            state.headerTitle = action.payload;
            state.showHeaderTitle = true;
            state.headerDashboard = false;
        },
        setTasksIds: (state, action) => {
            state.tasksIds = action.payload;

        },
        setHeaderDashboard: (state, action) => {
            state.headerDashboard = true;
        },
        setHeaderSubTitle: (state, action) => {
            state.headerSubtitle = action.payload
            state.headerDashboard = false;
            state.showHeaderSubtitle = true;
        },
        setHeaderButtonText: (state, action) => {
            state.headerButtonText = action.payload
            state.showHeaderButton = true;
            state.headerDashboard = false;
        },
        setLocations: (state, action) => {
            state.locations = action.payload
        },
    },
})

export const { setHeaderTitle, setTasksIds, setLocations, setHeaderSubTitle, setHeaderButtonText, setHeaderDashboard } = layoutSlice.actions
export default layoutSlice.reducer