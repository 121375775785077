import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLoadPresetData } from "../../../entities/preset/models";
import { getColWidth } from "../../../shared/lib";
import { DeleteRowAction } from "../../DeleteRowAction";
import GraphByType from "./GraphByType";
import "./PresetBox.scss";
import PresetHeader from "./PresetHeader";
import { dashboardModel } from "../../../entities/dashboard";
import { useDispatch } from "react-redux";
const PresetBox = ({
    preset,
    routes,
    openModifyModal,
    openPresetBoxModal,
    openedModalPreset,
    dashboard,
}) => {
    const { data, setData, loading, error, modificators, refreshFunc } =
        useLoadPresetData({ preset, noBreak: true });
    const [type, setType] = useState(null);
    const dispatch = useDispatch();
    const [noTableMargin, setNoTableMargin] = useState(
        localStorage.getItem("groupChatWithMargin") === "1"
    );

    useEffect(() => {
        if (openedModalPreset === preset?.key_id)
            dispatch(
                dashboardModel.setModalRequestdata({
                    data,
                    loading,
                    error,
                    modificators,
                    refreshFunc,
                })
            );
    }, [loading, data, data?.length, error, modificators]);

    const openPresetModifyModule = () => {
        openModifyModal(preset);
    };
    const openBoxModal = () => {
        openPresetBoxModal(preset);

        dispatch(
            dashboardModel.setModalRequestdata({
                data,
                loading,
                error,
                modificators,
                refreshFunc,
            })
        );
    };

    useEffect(() => {
        setData([]);
        setType(preset?.type);
    }, [preset?.type]);

    return (
        <div
            className={`col`}
            style={{
                width: `${getColWidth(preset.col)}%`,
            }}
        >
            <div
                className="preset"
                style={{
                    background: preset?.background
                        ? `rgba(${preset?.background?.r}, ${preset?.background?.g}, ${preset?.background?.b}, ${preset?.background?.a})`
                        : undefined,
                }}
            >
                {
                    <PresetHeader
                        dashboard={dashboard}
                        setNoTableMargin={setNoTableMargin}
                        noTableMargin={noTableMargin}
                        reloadGraph={refreshFunc}
                        preset={preset}
                        openBoxModal={openBoxModal}
                        openModifyPreset={openPresetModifyModule}
                    />
                }
                <div
                    className="preset__graph"
                    style={{
                        height: preset?.request
                            ? (window.innerHeight - 100) / 2.5
                            : dashboard?.content?.length === 1 && preset.col === 24
                            ? "80vh"
                            : (window.innerHeight - 100) / 2.5 + 12,
                    }}
                >
                    <div className="preset__graph__new">
                        {preset?.period ? (
                            <>
                                {loading ? (
                                    <Spin />
                                ) : error ? (
                                    <div>Не удалось загрузить данные</div>
                                ) : (
                                    <GraphByType
                                        noTableMargin={noTableMargin}
                                        loading={loading}
                                        data={data}
                                        preset={preset}
                                        type={type}
                                        modificators={modificators}
                                        refreshFunc={refreshFunc}
                                        routes={routes}
                                    />
                                )}
                            </>
                        ) : (
                            <div
                                onClick={openPresetModifyModule}
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    display: "flex",
                                    height: "100%",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Добавить
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PresetBox };
