export const empty_rows = [
    [
        { col: 24 },

    ],
    [
        { col: 12 },
        { col: 12 },
    ],
    [
        { col: 12 },
        { col: 6 },
        { col: 6 },
    ],
    [
        { col: 8 },
        { col: 8 },
        { col: 8 },
    ],
    [
        { col: 6 },
        { col: 6 },
        { col: 6 },
        { col: 6 },
    ]
]