import { Button, Input, Space, Table } from "antd";
import { useManagersList } from "../model";
import { EditOutlined } from "@ant-design/icons";
import EditManagerModal from "./EditManagerModal";

interface IManagersTable {}

const ManagersTable = ({}: IManagersTable) => {
  const {
    loading,
    managers,
    isEditing,
    editingManager,
    updatingLoading,
    managerName,
    onCloseEditing,
    onSave,
    onStartEditing,
    onChangeManagerName,
  } = useManagersList();

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 15,
        borderRadius: 10,
      }}
    >
      <Input
        placeholder="Имя менеджера"
        style={{
          maxWidth: 300,
          marginRight: 10,
          marginBottom: 10,
        }}
        allowClear
        value={managerName}
        onChange={onChangeManagerName}
      />
      <Table rowKey={"id"} loading={loading} dataSource={managers}>
        <Table.Column title="Менеджер" dataIndex="name" key="name" />
        <Table.Column title="Ref" dataIndex="ref" key="ref" />
        <Table.Column
          title="Департаменты"
          dataIndex="departments"
          key="departments"
          render={(departments: TDepartment[], manager: TManager) => {
            return (
              <div>
                {departments.map((department) => department.name).join(", ")}
              </div>
            );
          }}
        />

        <Table.Column
          key="action"
          render={(_: any, manager: TManager) => {
            const onClickEdit = () => {
              onStartEditing(manager);
            };
            return (
              <Space size="middle">
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={onClickEdit}
                />
              </Space>
            );
          }}
        />
      </Table>
      <EditManagerModal
        onSave={onSave}
        confirmLoading={updatingLoading}
        onCloseEditing={onCloseEditing}
        isEditing={isEditing}
        manager={editingManager!}
      />
    </div>
  );
};

export default ManagersTable;
