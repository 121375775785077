import * as React from "react"

const BarChart = (props) => (
    <svg
        {...props}
        width={70}
        height={56}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#a)" strokeWidth={0.989}>
            <path
                d="M1.743 56h66.513M1.743 28.199h66.513M1.743 0h66.513"
                stroke="#E6E6E6"
            />
            <path d="M1.743 56h66.513" stroke="#CCD6EB" />
            <path
                d="M13.812 20.255H5.766v20.653h8.046V20.255ZM30.44 33.759h-8.045v16.68h8.046V33.76ZM47.069 4.369h-8.046v24.624h8.046V4.369ZM63.697 38.128h-8.046v7.149h8.046v-7.15Z"
                fill="#DCE6F2"
                stroke="#fff"
            />
            <path
                d="M13.812 40.908H5.766V56h8.046V40.908ZM30.44 50.44h-8.045V56h8.046v-5.56ZM47.069 28.993h-8.046V56h8.046V28.993ZM63.697 45.277h-8.046V56h8.046V45.277Z"
                fill="#C3CAD1"
                stroke="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h70v56H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default BarChart
