import { dashboardApi, dashboardModel } from "@entities/dashboard";
import { userModel } from "@entities/user";
import { message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useCopyDashboard = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state: RootState) => state.user.id);

  const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
  const copiedDashboard = useSelector(dashboardModel.selectCopingDashboard);

  //portal_user,d_manager,super_admin,service_manager,portal_admin,electrical
  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    setLoading(true);
    dashboardApi
      .createDashboard({
        ...copiedDashboard,
        ...values,
        id: undefined,
      })
      .then((response: any) => {
        dispatch(
          dashboardModel.createDashboard({
            ...response.data,
            content: response.data.content,
          })
        );
        message.success(`Панель ${response.data.title} успешно создана!`, 1);
        setVisible(false);
      })
      .catch((e: any) => {
        message.error(`Не удалось создать панель`, 0.5);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onOpenModal = () => {
    dispatch(
      dashboardModel.setCopingDashboard({
        ...dashboard,
        creator: userId,
        is_public: false,
        usersIds: [],
      })
    );
    setVisible(true);
  };
  const onCancel = () => {
    setVisible(false);
  };
  return {
    onSubmit,
    onOpenModal,
    visible,
    onCancel,
    copiedDashboard,
    loading,
  };
};
