import {
  DeleteOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Popconfirm, Typography, Upload } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import FileService from "../../service/FileService";
import "./FileUpload.scss";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
const StyledUpload = styled(Upload)`
  .ant-upload-list-text-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 10px !important;
    background-color: #efefef !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
    border-radius: 7px !important;
    align-items: center !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .ant-upload-list-text-container:not(:first-child) {
  }
  .ant-upload-list {
    display: flex;
    align-items: flex-end !important;
  }

  .ant-upload-list-item {
    margin-top: 0px;
    flex-wrap: wrap;
  }
  .ant-upload-list {
    flex-wrap: wrap;
  }
  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
`;
const StyledButton = styled(Button)`
  span {
    margin-left: 0px !important;
    margin-right: 4px !important;
  }
  .anticon {
    margin-right: 0px !important;
    margin-bottom: -2px !important;
  }
`;

const FileUpload = ({ maxFiles = 10, type, files, disabled, task_id }) => {
  const [fileList, setFileList] = useState(files);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileq, setFileQ] = useState(null);

  const setFiles = () => {
    const newFilesFormat =
      fileList
        .filter((file) => file.folder === "file")
        .map((file) => {
          return {
            id: file.id,
            name: "",
            url: file.link,
            status: "done",
          };
        }) || [];
    setFileList(newFilesFormat);
  };

  const removeFile = (id) => {
    FileService.deleteFile(id)
      .then((response) => {
        setFileList(fileList.filter((file) => file.id != id));
        // if (response.status === 204) {
        //     const del_index = tasks.findIndex(task_ => task_.id === 0);
        //     const newState = JSON.parse(JSON.stringify(tasks));
        //     newState[del_index].files = newState[del_index].files.filter(file => file.id !== fileq.id);
        //     task.files = newState[del_index].files;
        //     dispatch(setTasks(newState))

        //     setFiles();

        // }
        // console.log(response);
      })
      .catch((error) => console.log("DELETE FILE", error));
  };
  function onImageChange(fileq) {
    if (fileq.file.status === "removed") {
      FileService.deleteFile(fileq.file.id)
        .then((response) => {
          if (response.status === 204) {
          }
        })
        .catch((error) => console.log("DELETE FILE", error));
    } else {
      setFileList([
        ...fileList,
        {
          id: moment().unix(),
          filename: "",
          url: "",
          status: "uploading",
        },
      ]);
      const file = fileq.file.originFileObj;

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const splited_name = fileq.file.name.split(".");
        const format = splited_name[splited_name.length - 1];
        const name = splited_name
          .slice(0, splited_name.length - 1)
          .join(".")
          .toLowerCase()
          .replace(" ", "_");
        FileService.uploadFile(task_id, reader.result, "upload", format, name)
          .then((response) => {
            console.log("RESPONSE ", response);
            setFileList([
              ...fileList,
              {
                ...response.data,
                name: response.data.link.replace("/upload/", ""),
                status: "done",
              },
            ]);
          })
          .catch((error) => {
            setFileList([
              ...fileList.filter((file) => file.status !== "uploading"),
            ]);
            message.error(
              "При загрузке иображения произошла ошибка, попробуйте ещё раз."
            );
          });
      };

      reader.onerror = function () {
        console.log(reader.error);
        setFileList([
          ...fileList.filter((file) => file.status !== "uploading"),
        ]);

        message.error(
          "При загрузке иображения произошла ошибка, попробуйте ещё раз."
        );
      };
    }
  }

  const handlePreview = async (file) => {
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // if (!isJpgOrPng) {
    //     message.error("Вы можете загрузить файл только в форматах JPG/PNG!");
    // }

    return 1;
  };

  const downloadFile = (file) => {
    FileService.downloadFile(file.id)
      .then((response) => response.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", file.name); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        message.error("Не удалось загрузить файл");
      })
      .finally(() => {});
  };
  return (
    <div className="file-upload" onClick={(e) => e.stopPropagation()}>
      {type === "mini" ? (
        <StyledUpload
          multiple
          itemRender={(originNode, file) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography.Link
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile(file);
                  }}
                >
                  {file.filename}
                </Typography.Link>
                <DownloadOutlined
                  style={{ marginRight: 10, marginLeft: 10 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile(file);
                  }}
                />
                <Popconfirm
                  title={"Удалить файл " + file.filename}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  onConfirm={(e) => {
                    removeFile(file.id);
                  }}
                  onCancel={() => {}}
                  okText="Удалить"
                  cancelText="Отмена"
                  placement="topRight"
                >
                  <DeleteOutlined style={{ color: "red", marginRight: 5 }} />
                </Popconfirm>
              </div>
            );
          }}
          openFileDialogOnClick
          onDownload={() => {}}
          onChange={(fileq) => onImageChange(fileq)}
          //beforeUpload={(fileq) => beforeUpload(fileq)}
          style={{ display: "flex" }}
          onPreview={() => {
            //handlePreview()
          }}
          onRemove={() => {}}
          fileList={fileList}
        >
          {fileList >= maxFiles || disabled ? null : (
            <div>
              <div className="add-container">
                <div className="add-text">Файлы</div>
                <div>
                  <PlusCircleOutlined style={{ color: "#1890ff" }} />
                </div>
              </div>
            </div>
          )}
        </StyledUpload>
      ) : (
        <Upload
          multiple={true}
          // action="/upload.do"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={(fileq) => onImageChange(fileq)}
          beforeUpload={(fileq) => beforeUpload(fileq)}
          listType="picture-card"
        >
          {fileList.length >= maxFiles ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }} onClick={(e) => e.preventDefault()}>
                Загрузить
              </div>
            </div>
          )}
        </Upload>
      )}
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt="загруженное изображение"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default FileUpload;
