import { Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const SearchSelect = ({ field, multiple, value, onChange }) => {


    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([]);

    const updateSearch = (value) => {
        setSearch(value);
    }


    const customRequest = async (url, body = null) => {
        const token = localStorage.getItem("accessToken");
        return axios.post(url, body, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    // Поиск значений для конкретного фильтра
    const onSearch = (search) => {
        if (field?.domain !== null) {
            const url = field?.domain + field?.url;


            customRequest(url, {
                "filters": [
                    {
                        "field": field?.field_label,
                        "value": search,
                        "comparison": "like"
                    }
                ]
            }).then((response) => {
                setOptions(response.data.map((item => {
                    return {
                        label: item[field.field_label],
                        value: item[field.field_id]
                    }
                })))
            })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            onSearch(search);
        }, 300)

        return () => {
            clearTimeout(timeout)
        }
    }, [search])

    const onChange_ = (value, option) => {
        //setSearch("")
        onChange(field.id, value);

    }
    return <Select
        filterOption={() => {
            return true
        }}
        placeholder="Все"
        style={{
            width: "100%"
        }}
        showSearch
        value={value}
        mode={multiple ? "multiple" : undefined}
        onSearch={updateSearch}
        onChange={onChange_}
        autoClearSearchValue={false}
        options={options}
    />
}

export default SearchSelect;