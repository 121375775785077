import { Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { dashboardModel } from '../../entities/dashboard';
import "./edite-dashboard.scss";
import { useSelector } from 'react-redux';
import { UserAccess } from '@entities/user';
const EditeDashboardCheckbox = () => {

    const editeMode = useSelector(dashboardModel.selectEditeModeDashboard);
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
    const dispatch = useDispatch();

    const onChangeEditeMode = (checked) => {
        dispatch(dashboardModel.setEditeMode(checked));
    }

    return (
        <UserAccess.DashboardEditAccess dashboard={dashboard}>
            <div className='edite-checkbox'>
                <div className='edite-checkbox__label'  >
                    Редактировать
                </div>
                <Switch checked={editeMode} onChange={onChangeEditeMode} />
            </div>
        </UserAccess.DashboardEditAccess>
    );
};

export { EditeDashboardCheckbox };
