import { Checkbox, DatePicker, Radio } from 'antd';
import moment from 'moment';
import React from 'react';
import FormItem from '../FormItem';
import ExtraField from '../InputField/ExtraFields';
import "./InputFields.scss"
import dayjs from 'dayjs';
const DateField = ({
    error,
    preset,
    updatePreset,
    disabled,
    field
}) => {


    const onChangeRadioDate = (event) => {
        const value = event.target.value;
        const nowDate = moment();
        const difDate = moment().subtract(1, value);
        updatePreset([["period", [difDate.format("YYYY-MM-DD"), nowDate.format("YYYY-MM-DD")]]]);
    }
    const onChange = (date) => {
        updatePreset([["period", [date[0].format("YYYY-MM-DD"), date[1].format("YYYY-MM-DD")]]]);
    }

    const onChangeCheckbox = (event) => {
        const value = event.target.checked;
        updatePreset([["fixedDate", value]])
    }
    return (
        <div className='date'>
            <div className={'date__radio'}>
                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={onChangeRadioDate} style={{ marginRight: 10 }} disabled={disabled === true}>
                    <Radio.Button value="months">Месяц</Radio.Button>
                    <Radio.Button value="quarters">Квартал</Radio.Button>
                    <Radio.Button value="years">Год</Radio.Button>
                </Radio.Group>
            </div>
            <div>
                <DatePicker.RangePicker
                    disabled={disabled === true}
                    status={error}
                    value={preset?.period?.map(item => dayjs(item))}
                    onChange={onChange}
                    format={"YYYY-MM-DD"}
                    style={{ marginRight: 10 }}
                />
                <Checkbox checked={preset?.fixedDate} onChange={onChangeCheckbox}>Не изменять</Checkbox>

            </div>

        </div>
    );
};

export default DateField;