import React, { useMemo, useEffect } from "react";
import Map from "./Map";
import "./styles.css";

export default function GisMap({ x, y, zoom }) {

    useEffect(() => {
    }, [x, y])
    const options = useMemo(
        () => ({
            center: [x, y],
            zoom
        }),
        [x, y, zoom]
    );

    //const [text, setText] = useState("");
    // const onTextChange = e => setText(e.target.value);
    return (
        <>
            <Map options={options} />
        </>
    );
}
