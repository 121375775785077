import { Button, Form, Input, message, Modal, TimePicker, Typography } from 'antd';
import React, { useState } from 'react';
import RegionSelect from '../RegionSelect/RegionSelect';
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import "./DealerModal.scss"
import GisMap from '../../../components/Map/GisMap';
import DealersService from '../../../service/DealersService';
import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';


const DealerModal = ({ visible = true, setVisible, editDealer, setDealers, dealers }) => {
    const [region, setRegion] = useState("");
    const layout = useSelector(state => state.layout)
    const [location, setLocation] = useState("")
    const [zoom, setZoom] = useState(12)
    const [center, setCenter] = useState([55.01, 82.94])
    const [centerError, setCenterError] = useState(false);
    const [phones, setPhones] = useState([""]);
    const [phonesValid, setPhonesValid] = useState([true]);
    const [regionError, setRegionError] = useState(false);
    const [phoneError, setPhoneError] = useState(false)
    const [createNew, setCreateNew] = useState(false)
    const [form] = Form.useForm();
    const [workTime, setWorktime] = useState([
        { key: "pn", label: "Понедельник", short: "Пн", time: null },
        { key: "vt", label: "Вторник", short: "Вт", time: null },
        { key: "sr", label: "Среда", short: "Ср", time: null },
        { key: "ch", label: "Четверг", short: "Чт", time: null },
        { key: "pt", label: "Пятница", short: "Пт", time: null },
        { key: "sb", label: "Суббота", short: "Сб", time: null },
        { key: "vs", label: "Воскресенье", short: "Вс", time: null },
    ])
    const [country, setCounty] = useState({
        "name": "Russia",
        "dialCode": "7",
        "countryCode": "ru",
        "format": "+. (...) ...-..-.."
    });

    useEffect(() => {
        setCreateNew(editDealer === null)
        if (editDealer === null) {
            setCenter([55.01, 82.94]);
            setPhones([""]);
            setRegion("");
            form.resetFields()
            setLocation("");
            setZoom(12);
            setCenterError(false);
            setPhoneError(false);
            setWorktime([
                { key: "pn", label: "Понедельник", short: "Пн", time: null },
                { key: "vt", label: "Вторник", short: "Вт", time: null },
                { key: "sr", label: "Среда", short: "Ср", time: null },
                { key: "ch", label: "Четверг", short: "Чт", time: null },
                { key: "pt", label: "Пятница", short: "Пт", time: null },
                { key: "sb", label: "Суббота", short: "Сб", time: null },
                { key: "vs", label: "Воскресенье", short: "Вс", time: null },
            ])
        }
        if (editDealer !== null)
            if (editDealer?.name !== "" && editDealer?.name !== null) {
                form.setFieldsValue(editDealer)

                setZoom(15)
                if (editDealer.lat && editDealer.lon)
                    setCenter([Number(editDealer.lat), Number(editDealer.lon)]);
                if (editDealer.phone !== null)
                    setPhones(editDealer.phone.split(","))
                const time = (JSON.parse(editDealer.working_time));
                setWorktime([
                    { key: "pn", label: "Понедельник", short: "Пн", time: time[0]["pn"] ? [moment("2017-03-13 " + time[0]["pn"][0]), moment("2017-03-13 " + time[0]["pn"][1])] : null },
                    { key: "vt", label: "Вторник", short: "Вт", time: time[1]["vt"] ? [moment("2017-03-13 " + time[1]["vt"][0]), moment("2017-03-13 " + time[1]["vt"][1])] : null },
                    { key: "sr", label: "Среда", short: "Ср", time: time[2]["sr"] ? [moment("2017-03-13 " + time[2]["sr"][0]), moment("2017-03-13 " + time[2]["sr"][1])] : null },
                    { key: "ch", label: "Четверг", short: "Чт", time: time[3]["ch"] ? [moment("2017-03-13 " + time[3]["ch"][0]), moment("2017-03-13 " + time[3]["ch"][1])] : null },
                    { key: "pt", label: "Пятница", short: "Пт", time: time[4]["pt"] ? [moment("2017-03-13 " + time[4]["pt"][0]), moment("2017-03-13 " + time[4]["pt"][1])] : null },
                    { key: "sb", label: "Суббота", short: "Сб", time: time[5]["sb"] ? [moment("2017-03-13 " + time[5]["sb"][0]), moment("2017-03-13 " + time[5]["sb"][1])] : null },
                    { key: "vs", label: "Воскресенье", short: "Вс", time: time[6]["vs"] ? [moment("2017-03-13 " + time[6]["vs"][0]), moment("2017-03-13 " + time[6]["vs"][1])] : null },
                ])
                const loc = layout.locations.find(loc => loc.id === Number(editDealer.location))
                const labelloc = loc.city.includes("(") ? loc.city : loc.city + ` (${loc.region})`
                setRegion({ label: [labelloc, " "], value: editDealer.location, key: editDealer.location, disabled: undefined })
            }
    }, [editDealer?.id, editDealer === null])
    function sendForm() {
        let error = false;

        if (region.length < 1) {
            setRegionError(true);
            error = true;
        }

        phones.forEach((phone, index) => {

            if (phone.length < country.format.length) {
                phonesValid[index] = false;
                setPhonesValid([...phonesValid])
                setPhoneError(true)
                error = true;
            }
        })
        form
            .validateFields()
            .then((values) => {

                if (error) return
                if (createNew) {


                } else {

                }
                const time = workTime.map(day => {
                    let obj = {};
                    if (day.time === null)
                        obj[day.key] = null;
                    else
                        obj[day.key] = [day.time[0].format("HH:mm"), day.time[1].format("HH:mm")];
                    return obj
                })
                const newDelear = {
                    location: region === null ? region : region.value,
                    name: values.name,
                    phone: phones.join(","),
                    address: values.address,
                    company_comment: values.company_comment,
                    internal_comment: values.internal_comment,
                    lat: center[0],
                    lon: center[1],
                    working_time: JSON.stringify(time)
                }
                if (createNew) {
                    DealersService.createDealer(newDelear).then(response => {
                        setDealers([response.data, ...dealers])
                        setVisible(false);
                        message.success("Дилер успешно добавлен")
                    }).catch(error => {
                        message.error("Не удалось добавить дилера")
                    }).finally(() => {

                    })
                } else {
                    DealersService.updateDealer(editDealer.id, newDelear).then(response => {
                        setVisible(false);
                        setDealers(dealers.map(dealer => {
                            if (dealer.id !== response.data.id) {
                                return dealer;
                            } else {
                                return { ...response.data, ...newDelear };
                            }
                        }))
                        message.success("Дилер успешно обновлён")
                    }).catch(error => {
                        message.error("Не удалось обновить дилера")
                    })
                }

            })
            .catch((error) => {
                console.log("Validate Failed:", error);
            });
    }
    const addPhoneInput = () => {
        if (phones.length < 4) {
            setPhones([...phones, ""])
            setPhonesValid([...phonesValid, true])
        }
    }
    const removePhoneInput = (deletedIndex) => {

        setPhones(phones.filter((phone, index) => index !== deletedIndex))
        setPhonesValid(phonesValid.filter((phone, index) => index !== deletedIndex))
    }

    const updateCountry = (index, value, country_, e, formattedValue) => {
        if (country.countryCode !== country.countryCode) {
            setPhones(phones.map(phone => ""))
        }
        setCounty(country_);
        phones[index] = formattedValue;
        setPhones([...phones])
        phonesValid[index] = true;
        setPhoneError(false);
        setPhonesValid([...phonesValid])
    }
    //https://2gis.ru/novosibirsk/geo/141373161850592/82.916134%2C55.034201?m=82.916473%2C55.027602%2F15.02
    const onChangeLocation = (location) => {
        setLocation(location);

        const split_1 = location.split("?m=");
        if (split_1.length > 1) {
            const coord_raw = split_1[1].split("%2C");
            if (coord_raw.length > 1) {
                const y = coord_raw[0]
                const x_raw = coord_raw[1].split("%2F");
                if (x_raw.length > 1) {
                    const x = x_raw[0];

                    setCenter([...[Number(x), Number(y)]]);
                    setZoom(16)
                    setCenterError(false);
                    return;
                }
            }

        }
        setCenterError(true);
    }


    const updateDealerRegion = (region) => {
        if (region) {

            setRegion(region);
        } else {
            setRegion("")
        }
        setRegionError(false);
    }


    const updateTimeStart = (time, key) => {
        let timeIsSet = true;
        const setedTime = workTime.find(day => day.time !== null);

        if (!setedTime) {
            timeIsSet = false;
        }



        setWorktime(workTime.map(day => {
            if (day.key === key || !timeIsSet) {

                day.time = time

                return day;
            } else {
                return day;
            }
        }))
    }
    return (
        <div>
            <Modal
                title={
                    <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} дилера</Typography.Title>
                }
                centered
                open={visible}
                onOk={sendForm}
                okText={createNew ? "Добавить" : "Обновить"}
                onCancel={() => setVisible(false)}
                closable={false}
                destroyOnClose
                forceRender
                width={window.screen.width < 450 ? "100%" : 450}
                // zIndex={10000}
                styles={{ body: { paddingBottom: 16 } }}

            >
                <Form
                    style={{ marginBottom: 4 }}
                    name="wrap"
                    // labelCol={{ flex: "110px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    form={form}
                    // initialValues={editUser}
                    layout="vertical"
                >
                    <Form.Item
                        label="Город"
                        required
                        name="location"
                        validateStatus={regionError ? "error" : undefined}
                        help={regionError ? "Поле обязательно к заполнению" : undefined}
                        rules={[
                            {
                                required: false,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <RegionSelect region={region} setRegion={updateDealerRegion} />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 4 }}
                        label="Название компании"
                        required
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder='Название компании' />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 4 }}
                        label="Адрес"
                        required
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder='Адрес' />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 10 }}
                        label="Телефон"
                        required
                        name="phone"
                        validateStatus={phoneError ? "error" : undefined}
                        help={phoneError ? "Некорректный телефон" : undefined}
                        rules={[
                            {
                                required: false,
                                message: "Некорректный телефон",
                                type: "string",
                            },
                        ]}

                    >
                        {phones.map((phone, index) => {
                            if (index === 0)
                                return <div key={index} style={{ display: "flex" }}>
                                    <PhoneInput
                                        disableAreaCodes

                                        country={country.countryCode}
                                        label={null}
                                        onlyCountries={['ru', 'by', 'kz', 'az', 'am', 'kg', 'tm', 'uz', 'tj', 'md']}
                                        onChange={(value, country, e, formattedValue) => updateCountry(index, value, country, e, formattedValue)}
                                        value={phone}
                                        countryCodeEditable={false}
                                        inputExtraProps={{
                                            name: "tel",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        isValid={phonesValid[index]}
                                    // onChange={phone => this.setState({ phone })}
                                    />
                                    <Button style={{ marginLeft: 10 }} disabled={phones.length > 3} onClick={addPhoneInput} type="primary" icon={<PlusOutlined />} size={"medium"} />
                                </div>;
                            else
                                return <div key={index} style={{ display: "flex", marginTop: 10 }}>
                                    <PhoneInput
                                        disableAreaCodes
                                        disableDropdown
                                        country={country.countryCode}
                                        countryCodeEditable={false}
                                        isValid={phonesValid[index]}
                                        onChange={(value, country, e, formattedValue) => updateCountry(index, value, country, e, formattedValue)}

                                        label={null}
                                        onlyCountries={['ru', 'by', 'kz', 'az', 'am', 'kg', 'tm', 'uz', 'tj', 'md']}
                                        value={phone}
                                    // onChange={phone => this.setState({ phone })}
                                    />
                                    <Button style={{ marginLeft: 10 }} onClick={() => { removePhoneInput(index) }} type="primary" icon={<MinusOutlined />} size={"medium"} />
                                </div>;
                        })}

                    </Form.Item>

                    <div style={{ width: "100%", height: 200 }}>
                        <GisMap x={center[0]} y={center[1]} zoom={zoom} />
                    </div>
                    <Form.Item

                        required
                        name="position"
                        validateStatus={centerError ? "error" : undefined}
                        help={centerError ? "Некорректная ссылка" : undefined}
                        style={{ marginTop: 10 }}
                        rules={[
                            {
                                required: createNew,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder='Ссылка 2гис' value={location} onChange={e => onChangeLocation(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 4 }}
                        label="Комментарий по компании"
                        name="company_comment"
                        rules={[
                            {
                                required: false,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder='Комментарий по компании' />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 4 }}
                        label="Внутренний комментарий"

                        name="internal_comment"
                        rules={[
                            {
                                required: false,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder='Внутренний комментарий' />
                    </Form.Item>
                </Form>
                <Form labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}>

                    {workTime.map((day, index) => {

                        return (<Form.Item
                            label={day.label}
                            key={index}
                            style={{ marginBottom: 4 }}
                            rules={[
                                {
                                    required: false,
                                    message: "Поле обязательно к заполнению",
                                },
                            ]}
                        >
                            <TimePicker.RangePicker
                                size="large"
                                allowClear={true}
                                value={day.time}
                                onChange={e => updateTimeStart(e, day.key)}
                                type={"time"}
                                defaultPickerValue={"18:00"}
                                format={"HH:mm"}
                                placeholder="Время"
                            />

                        </Form.Item>)
                    })}
                </Form>



            </Modal>
        </div >
    );
};

export default DealerModal;