import { Form, Input, message, Modal, Typography } from 'antd';
import React, { useEffect } from 'react';
import QuestionService from '../../../service/QuestionService';

const ItemModal = ({ visible, setVisible, targetItem, items, setItems }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(targetItem);
    }, [visible])
    function sendForm() {
        form
            .validateFields()
            .then((values) => {
                if (targetItem.create) {
                    QuestionService.createArticleItem({ ...values, article_rubric_id: targetItem.article_rubric_id }).then((response) => {
                        message.success("Статья успешно создана!");

                        setItems([...items, response.data]);
                        setVisible(false);
                    }).catch(() => {
                        message.error("Не удалось создать статью!");
                    }).finally(() => {
                    })
                } else {
                    QuestionService.updateArticleItem(targetItem.id, { ...values }).then((response) => {
                        message.success("Статья успешно обновлёна!");
                        setItems(items.map(item => {
                            if (item.id === targetItem.id) {
                                return response.data
                            } else {
                                return item;
                            }
                        }));
                        setVisible(false);
                    }).catch(() => {
                        message.error("Не удалось обновить статью!");
                    }).finally(() => {


                    })
                }
            })
            .catch((error) => {
                console.log("Validate Failed:", error);
            });
    }
    return (
        <Modal
            title={
                <Typography.Title level={4}>{targetItem.create ? "Создать" : "Обновить"}</Typography.Title>
            }
            centered
            open={visible}
            onOk={sendForm}
            okText={targetItem.create ? "Создать" : "Обновить"}
            onCancel={() => setVisible(false)}
            closable={false}
            destroyOnClose
            forceRender
            width={window.screen.width < 450 ? "100%" : 500}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}
        >
            <Form
                name="title"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Заголовок статьи"
                    required
                    name="article_title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Вводная часть"

                    name="article_excerpt"
                    rules={[
                        {
                            required: false,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Текст статьи"
                    required
                    name="article_content"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ItemModal;