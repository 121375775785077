import './FormReadValue.scss';

interface IFormReadValue {
	value: any;
	customClassName?: string;
}

const FormReadValue = ({ value, customClassName }: IFormReadValue) => {
	return <div className={`form-read-value ${customClassName ?? '-'}`}>{value}</div>;
};

export default FormReadValue;
