import React, { useEffect, useRef } from "react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import autoAnimate from "@formkit/auto-animate";
import { colorsPalette } from "../config";
import CustomLegend from "../../../../components/CustomLegend/CustomLegend";

const formatter = Intl.NumberFormat("ru", { notation: "compact" });

const LineChart = ({
  data,
  isInteractive = true,
  modificators,
  additionalInfo,
}) => {
  const parentRef = useRef();

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  const getDateFormatKey = (value) => {
    return value.format("MMMM YY DD");
    // if (options.execute === "executeInMonths")
    //     return moment(value).format("MMMM YY")
    // else if (options.execute === "executeInYears")
    //     return value + " год"
    // else if (options.execute === "executeInQuarters")
    //     return value?.[5] + " квартал " + value.slic
  };
  return (
    <div style={{ height: "100%", width: "100%" }} ref={parentRef}>
      {additionalInfo && (
        <CustomLegend
          legends={Array.isArray(data) ? data.map((item) => item.id) : []}
        />
      )}
      <div style={{ height: additionalInfo ? "85%" : "100%" }}>
        <ResponsiveLine
          data={data ?? []}
          margin={{ top: 10, right: 20, bottom: 25, left: 60 }}
          tickValues={1}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          axisBottom={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",

            renderTick: ({
              tickIndex,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y,
            }) => {
              return (
                <>
                  <g transform={`translate(${x} ${y})`} rotate={20}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX}, ${textY} ) rotate(10 0 0)`}
                      id={"text" + tickIndex}
                    >
                      <tspan
                        opacity={1}
                        style={{
                          fontSize: 12,
                          overflow: "hidden",
                        }}
                      >
                        {moment(value).format("DD MMM")}
                      </tspan>
                    </text>
                  </g>
                </>
              );
            },
            format: "%Y-%m-%d",
            tickValues: "every 1 days",
            legendOffset: -12,
          }}
          xFormat="time:%Y-%m-%d"
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          lineWidth={4}
          enablePoints={false}
          yFormat=" >-.2f"
          curve="monotoneX"
          axisTop={null}
          axisRight={null}
          colors={colorsPalette}
          animate={isInteractive}
          pointSize={5}
          pointColor={{ theme: "background" }}
          pointBorderWidth={0}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          //enableSlices={"x"}
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                  borderRadius: 9,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 15, fontWeight: 500 }}>
                    {point.data.y
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .replace(/\.00$/, "")}
                  </div>
                  <div
                    style={{ marginRight: 5, color: "#9f9f9f", marginTop: -5 }}
                  >
                    {moment(point.data.x).format("DD MMM")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                      background: point.serieColor,
                      marginRight: 5,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: -3,
                      color: "#6f6f6f",
                    }}
                  >
                    {point.serieId}
                  </div>
                </div>
              </div>
            );
          }}
          isInteractive={isInteractive}
          axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",

            renderTick: ({
              tickIndex,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y,
            }) => {
              return (
                <>
                  <g transform={`translate(${x} ${y})`} rotate={20}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX}, ${textY} ) `}
                    >
                      <tspan
                        opacity={1}
                        style={{
                          fontSize: 12,
                          overflow: "hidden",
                        }}
                      >
                        {formatter.format(value)}
                      </tspan>
                    </text>
                  </g>
                </>
              );
            },
          }}
          // axisBottom={{
          //     tickSize: 10,
          //     tickPadding: 5,
          //     tickRotation: 0,
          //     legend: "",
          //     legendPosition: "middle",

          //     renderTick: ({
          //         tickIndex,
          //         textAnchor,
          //         textBaseline,
          //         textX,
          //         textY,
          //         value,
          //         x,
          //         y
          //     }) => {

          //         return (
          //             <>
          //                 <g transform={`translate(${x} ${y})`} rotate={20} >

          //                     <text

          //                         alignmentBaseline={textBaseline}
          //                         textAnchor={textAnchor}
          //                         transform={`translate(${textX}, ${textY} ) rotate(10 0 0)`}
          //                         id={"text" + tickIndex}
          //                     >
          //                         <tspan

          //                             opacity={1}

          //                             style={{
          //                                 fontSize: 12,
          //                                 overflow: "hidden"
          //                             }}
          //                         >
          //                             {getDateFormatKey(value)}</tspan>
          //                     </text >
          //                 </g>

          //             </>

          //         );
          //     }
          // }}
          legends={[]}
        />
      </div>
    </div>
  );
};

export default LineChart;
