import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Tree from 'react-d3-tree';
import MindHeadItem from './MindHeadItem/MindHeadItem';

import "./MindMap.css";
import MindTaskItem from './MindTaskItem/MindTaskItem';
import MindUserItem from './MindUserItem/MindUserItem';

const orgChart = {
    name: 'Планерка тест',
    children: [
        {
            name: 'Сергей Купавцев',
            attributes: {
                department: 'Fabrication',
            },

        },
        {
            name: 'Foreman',
            attributes: {
                department: 'Assembly',
            },

        },
    ],
};


const MindMapComponent = ({ tasks, users, group, visible, setVisible }) => {
    const [mindMap, setMindMap] = useState(null)
    const [groupedTasks, setGroupedTasks] = useState({})
    const groupingTasks = (tasks) => {
        let taskGroups = [];
        let groupingTasks = {};
        tasks.forEach(task => {
            const groupTask = task.responsibles//groups.find(group => group.id === task.task_group_id);
            if (taskGroups.includes(groupTask)) {
                groupingTasks[groupTask].push({ ...task });
            } else {
                taskGroups.push(groupTask)
                groupingTasks[groupTask] = [task];
            }
        });
        console.log(groupingTasks);
        setGroupedTasks(groupingTasks);
    }
    useEffect(() => {
        if (tasks) {
            groupingTasks(tasks);
        }
    }, [tasks])
    useEffect(() => {

        let children = [];
        if (groupedTasks) {


            Object.keys(groupedTasks).forEach(user_id => {
                const user = users.find(user => user.id === Number(user_id));
                const name = user?.name;
                children.push({
                    name: name,
                    attributes: {
                        type: "user",
                        all: groupedTasks[user_id]?.length,
                        done: groupedTasks[user_id].filter(item => item.result !== null)?.length
                    },
                    children: groupedTasks[user_id].map(item => {
                        return {
                            name: item.title,
                            attributes: {
                                type: "task",
                                ...item,
                                user: user
                            }
                        }
                    })
                })
            })
        }
        setMindMap({
            name: group?.title,
            attributes: {
                type: "head"
            },
            children: children
        })
    }, [groupedTasks])

    const getNodeItem = ({ nodeDatum, toggleNode }) => {
        const type = nodeDatum.attributes?.type;
        switch (type) {
            case "user":
                return <MindUserItem nodeDatum={nodeDatum} toggleNode={toggleNode} />
            case "head":
                return <MindHeadItem nodeDatum={nodeDatum} toggleNode={toggleNode} />
            case "task":
                return <MindTaskItem nodeDatum={nodeDatum} toggleNode={toggleNode} />
        }
    }

    return (
        <Modal
            centered
            open={visible}
            onCancel={() => setVisible(false)}
            width={"90%"}
            destroyOnClose
            bodyStyle={{ height: 'calc(100vh - 100px)' }}
            footer={[

                <Button key="back" type="primary" onClick={() => setVisible(false)}>
                    Закрыть
                </Button>
            ]}
        >

            <div id="treeWrapper" style={{ width: '100%', height: '100%' }}>
                {mindMap && <Tree
                    collapsible
                    zoomable
                    draggable
                    hasInteractiveNodes
                    enableLegacyTransitions
                    nodeSize={{
                        x: 300,
                        y: 100
                    }}

                    pathClassFunc={() => 'custom-link'}

                    transitionDuration={500}
                    renderCustomNodeElement={getNodeItem}
                    data={mindMap}
                    orientation="horizontal" />}
            </div>
        </Modal>
    );
};

export default MindMapComponent;