import { MenuOutlined } from "@ant-design/icons";
import { dashboardModel } from "@entities/dashboard";
import {
  CopyDashboardButton,
  CreateDashboardButton,
  UpdateDashboardButton,
} from "@features/CreateUpdateDashboard";
import { Button, Layout, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SelectDashboard from "../../../../NewDashboard/features/SelectDashboard/ui";
import TasksService from "../../../../service/TasksService";
import CardPreview from "./CartPreview";
import "./Header.scss";
import { UserAccess } from "@entities/user";

export default function Header(props) {
  const layout = useSelector((state) => state.layout);
  const user = useSelector((state) => state.user);
  const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
  const [loadFile, setLoadFile] = useState(false);
  const [targetLocation, setTargetocation] = useState("");
  const location = useLocation();
  useEffect(() => {
    setTargetocation(location.pathname);
  }, [location.pathname]);

  const loadInPdf = () => {
    if (loadFile) return;
    setLoadFile(true);
    TasksService.getPdfTasks(layout.tasksIds)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.setAttribute("download", "");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error("Не удалось загрузить файл");
      })
      .finally(() => {
        setLoadFile(false);
      });
  };

  return (
    <Layout.Header className="site-layout-background header">
      {(user?.portal_isUser ||
        user?.portal_isAdmin ||
        user?.portal_isSupervisor ||
        user?.d_isManager) && (
        <>
          <Button
            className="header__menu-button"
            shape="default"
            type="text"
            icon={<MenuOutlined />}
            onClick={() => {
              props.setState(!props.state);
            }}
          />
          {targetLocation === "/portal-dashboard" ? (
            <div className="header__select">
              <SelectDashboard />

              {dashboard?.content && (
                <>
                  <UserAccess.DashboardEditAccess dashboard={dashboard}>
                    <UpdateDashboardButton />
                  </UserAccess.DashboardEditAccess>
                  <CopyDashboardButton />
                </>
              )}
            </div>
          ) : (
            <div className="header__title-container">
              <>
                <Typography.Title
                  level={
                    (layout?.showHeaderSubtitle || window.screen.width) < 450
                      ? 5
                      : 3
                  }
                  ellipsis={true}
                  style={{ maxWidth: "100%" }}
                  className="header__title"
                >
                  {layout.headerTitle}
                </Typography.Title>
                {layout?.showHeaderSubtitle && (
                  <Typography.Text className="header__subtitle">
                    {layout?.headerSubtitle}
                  </Typography.Text>
                )}
              </>
            </div>
          )}
          <CardPreview />
          <div className="header__actions">
            {targetLocation === "/portal-dashboard" && (
              <CreateDashboardButton />
            )}
            {targetLocation === "/portal-tasks" && (
              <Button
                loading={loadFile}
                type="primary"
                style={{ marginRight: 20 }}
                onClick={loadInPdf}
              >
                Загрузить в Excel
              </Button>
            )}
          </div>
        </>
      )}
    </Layout.Header>
  );
}
