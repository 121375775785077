import { useEffect, useState } from "react"
import { getUserRoles } from "../api"

export const useLoadRoles = () => {
    const [roles, setRoles] = useState<TUserRole[]>([])
    const loadRoles = () => {
        getUserRoles()
            .then(response => {
                const userRoles = response.data.data as TUserRole[];
                setRoles(userRoles);
            })
            .catch(() => {

            })
            .finally(() => {

            })
    }

    useEffect(() => {
        loadRoles();
    }, [])

    return {
        roles
    }
}