import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { analytic_url } from "../../../service/http";

interface ISelectDepartments {
  onChange?: (departments: any) => void;
  value?: TDepartment[];
}

const SelectDepartments = ({ value, onChange }: ISelectDepartments) => {
  const onChangeDepartments = (refs: any, departments: any) => {
    console.log("departments", refs, departments);

    onChange && onChange(departments);
  };
  return (
    <RequestedSelect
      mode="multiple"
      onlyRead={false}
      onSelect={onChangeDepartments}
      values={value?.map((value) => value.ref)}
      requestUrl={`${analytic_url}/api/manager/departments`}
      queryTypedParam="name"
      queryChoosenParams="ref"
      valueLabel="name"
      valueKey="ref"
    />
  );
};

export default SelectDepartments;
