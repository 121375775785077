import React from 'react';
import "./MindHeadItem.scss"

const MindHeadItem = ({ nodeDatum, toggleNode }) => (
    <g class="no-select">
        <g transform="translate(-19,-19)" stroke-width="0">

            <path d="M0 0L18.9988 38L38 0H0Z" fill="#848484" />
        </g>
        <text class='nodename-head' strokeWidth="0" strokeOpacity={0} x="40" y="3">
            {nodeDatum.name}
        </text>
        {nodeDatum.attributes?.description && (
            <g>
                <text id="text" class="small" fill="black" x="30" dy="15" strokeWidth="1">
                    {nodeDatum.attributes?.description}
                </text>


            </g>
        )}
    </g>

);

export default MindHeadItem;