import { Radio } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import DatePickerCustom from "../DatePickerCustom/DatePickerCustom";

const PresetModalButtons = ({
  preset,
  expandedRows,
  setExpandedActionId,
  expandedActionId,
  period,
  setPeriod,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const updateDate = (date) => {
    setPeriod(
      date[0].format("YYYY-MM-DD") + "," + date[1].format("YYYY-MM-DD")
    );
  };
  const getDate = () => {
    try {
      const period_ = period.split(",");
      return [dayjs(period_[0], "YYYY-MM-DD"), dayjs(period_[1], "YYYY-MM-DD")];
    } catch {
      return null;
    }
  };
  useEffect(() => {
    if (preset.subroute.expanded_row) {
      const rowsJson = preset.subroute.expanded_row;
      setExpandedRow(rowsJson);
    }
  }, [preset]);

  return (
    <>
      {!(preset.type !== "table" || !expandedRow) && (
        <div className="expanded__pickers">
          {expandedRow?.length > 1 && (
            <Radio.Group
              disabled={expandedRows.length === 0}
              value={expandedActionId}
              buttonStyle="solid"
              style={{ marginRight: 10, marginTop: 10 }}
              onChange={(event) => {
                setExpandedActionId(event.target.value);
              }}
            >
              {expandedRow.map((key, index) => {
                return (
                  <Radio.Button key={index} value={index}>
                    {key.title}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          )}
          <div style={{ marginTop: 10 }}>
            <DatePickerCustom
              date={getDate()}
              setDate={updateDate}
              error={undefined}
              disabled={expandedRows.length === 0}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PresetModalButtons;
