import { Select } from 'antd';
import { useEffect, useState } from 'react';

const CitySelect = ({ id, initialValue, multiple, updateValue, region, type = "city" }) => {
    const [value, setValue] = useState(initialValue);
    const [expanded, setExpanded] = useState([]);
    const [showPlaceholders, setShowPlaceholders] = useState({
        cities: true,
        regions: true,
        districts: true,
        countries: true,
    });
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (region !== "") {
            setValue(region);
            // setValue({ label: region?.label[0], value: region?.label[0] });
        }
    }, [region]);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    const getValue = (value) => {
        switch (type) {
            case "city":
                return value.value;
            case "country":
                return value.country
            case "district":
                return value.district
            case "region":
                return regions
        }
    }
    const onChange = (newValue) => {
        // тут есть возможность выводить результат выбора в виде объекта, а не строки
        setValue(newValue);
        // const converted = {
        //   id: +newValue.split(".")[0],
        //   type: newValue.split(".")[1],
        // };
        // setRegion(converted);
        if (!multiple) {

            updateValue(newValue.value);
        } else {
            updateValue(newValue.map(item => item.value));

        }
    };

    function onSearch(search) {
        // console.log(search);
        setShowPlaceholders({
            cities: false,
            regions: false,
            districts: false,
            countries: false,
        });
        const timeout = setTimeout(() => {
            onExpand(["city"], search);
        });

        if (isFetching) {
            clearTimeout(timeout);
        }
    }

    // console.log(showPlaceholders);

    function onExpand(allTypes, search) {
        // console.log(allTypes);
        setExpanded(allTypes[0] == "all" ? ["city", "region", "district", "country"] : allTypes);
        //allTypes[allTypes.length - 1];

        // console.log(type);
        const uri = `https://dealers.vakio.ru/api/location_with_filter?type=${type}${search && search !== "" ? "&value=" + search : ""
            }`;
        // console.log(uri);
        setIsFetching(true);
        if (type !== undefined) {
            fetch(uri, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                },
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setIsFetching(false);
                    console.log(json);

                    const citiesJson = json.filter((f) => f.city);

                    setDistricts(json?.map(item => { return { label: item.district, value: item.district } }))
                    setCountries(json?.map(item => { return { label: item.country, value: item.country } }))
                    setRegions(json?.map(item => { return { label: item.region, value: item.region } }))
                    console.log(json?.map(item => { return { label: item.country, value: item.country } }));
                    setCities(
                        citiesJson.map((i) => ({
                            id: `${i.id}`,
                            city: i.city.includes("(") ? i.city : i.city + ` (${i.region})`,
                        }))
                    );



                });
        }
    }

    const getName = () => {
        switch (type) {
            case "city":
                return "Город"
            case "country":
                return "Страна"
            case "district":
                return "Округ"
            case "region":
                return "Регион"
        }
    }
    const getValues = () => {
        switch (type) {
            case "city":
                return cities.map((itm) => (
                    <Select.Option value={itm.id} key={itm.id} >{itm.city} </Select.Option>
                ))
            case "country":
                console.log(countries);
                return countries.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))
            case "district":
                return districts.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))
            case "region":
                return regions.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))
        }
    }
    return (
        <div>
            <Select
                showSearch
                mode={multiple ? "multiple" : undefined}
                onSearch={onSearch}
                style={{ width: "100%" }}
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={getName()}
                allowClear
                // treeExpandedKeys={expanded}
                onChange={onChange}
                //onTreeExpand={onExpand}
                labelInValue
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) => {
                    return option.children.includes(input) >= 0
                }}

            >

                {type == "city" && cities.map((itm) => (
                    <Select.Option value={itm.id} key={itm.id} >{itm.city} </Select.Option>
                ))}
                {type == "country" && countries.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))}
                {type == "district" && districts.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))}
                {type == "region" && regions.map((itm) => (
                    <Select.Option value={itm.value} key={itm.value} >{itm.label} </Select.Option>
                ))}
            </Select>
        </div>
    );
}

export default CitySelect;