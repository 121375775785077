import * as React from "react"

const LineChart = (props) => (
    <svg
        {...props}
        width={70}
        height={56}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M0 56h70" stroke="#707378" strokeWidth={1.5} />
        <path
            d="M5.833 44.457S12.833.881 17.5.881s7 35.004 11.667 35.004c4.666 0 7-14.287 11.666-14.287 4.667 0 7 17.859 11.667 22.145 4.667 4.286 11.667 4.286 11.667 4.286"
            stroke="#808B91"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.752 45.296c-.13 0-.255-.095-.346-.264a1.371 1.371 0 0 1-.144-.637c0-.24.052-.469.143-.638.092-.169.217-.264.346-.264.13 0 .255.095.347.264.091.169.143.398.143.637s-.051.468-.143.637c-.092.17-.216.264-.346.265ZM17.5 1.803c-.13 0-.254-.095-.346-.264a1.37 1.37 0 0 1-.143-.637c0-.24.051-.469.143-.638C17.245.095 17.37 0 17.5 0c.13 0 .254.095.346.264.092.169.143.398.143.637s-.05.468-.143.637c-.091.17-.216.265-.346.265ZM29.126 36.732c-.13 0-.254-.095-.346-.264a1.37 1.37 0 0 1-.144-.637c0-.239.052-.468.144-.637.091-.17.216-.264.346-.264.13 0 .254.094.346.263.092.17.143.398.143.637 0 .24-.051.469-.143.638-.091.169-.216.264-.346.264ZM40.752 22.535c-.13 0-.255-.095-.346-.264a1.37 1.37 0 0 1-.144-.637c0-.239.052-.468.144-.637.091-.17.216-.264.346-.265.13 0 .254.095.346.264.092.169.143.398.143.637 0 .24-.051.469-.143.638-.091.169-.216.264-.346.264ZM52.5 44.62c-.13 0-.254-.095-.346-.264a1.371 1.371 0 0 1-.144-.638c0-.239.052-.468.144-.637.091-.169.216-.264.346-.264.13 0 .254.095.346.264.092.168.143.398.144.637 0 .239-.052.468-.144.637-.091.17-.216.265-.345.265H52.5ZM64.126 48.901c-.13 0-.254-.095-.346-.264a1.37 1.37 0 0 1-.144-.637c0-.239.052-.468.144-.637.091-.17.216-.264.346-.264.13 0 .254.094.346.263.092.17.143.398.143.637 0 .24-.05.469-.143.638-.091.169-.216.264-.346.264Z"
            fill="#434E59"
        />
        <path
            d="m5.752 42.817.49.901-.49.902-.49-.902.49-.901ZM17.5 26.366l.49.902-.49.901-.49-.901.49-.902ZM29.126 14.197l.49.902-.49.901-.49-.901.49-.902ZM40.752.676l.49.901-.49.902-.49-.902.49-.9ZM52.5.676l.49.901-.49.902-.49-.902.49-.9ZM64.126 47.775l.49.901-.49.901-.49-.901.49-.901Z"
            fill="#DCE6F2"
        />
    </svg>
)

export default LineChart
