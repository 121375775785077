import React, { useEffect, useRef, useState } from 'react';
import { ResponsivePie } from '@nivo/pie'
import autoAnimate from '@formkit/auto-animate';
import moment from 'moment';
import Tooltip from './Tooltip';
import { colorsPalette } from '../config';
import CustomLegend from '../../../../components/CustomLegend/CustomLegend';
import { getModifiedStyle, parseModifiedValue } from '../lib/ParseModificators';
const PieChart = ({
    data,
    isInteractive,
    modificators,
    outValue,
    selectionData,
    additionalInfo = true
}) => {

    const parentRef = useRef();
    const [interactive, setIsInteractive] = useState(true);
    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);
    useEffect(() => {

        setIsInteractive(isInteractive)
    }, [isInteractive])

    return (
        <>
            <div style={{ height: "100%", width: "100%" }} ref={parentRef}>
                <div style={{ height: additionalInfo ? "85%" : "100%" }}>
                    {true && <CustomLegend legends={data?.map(item => item.label) || []} />}
                    <ResponsivePie
                        data={data ?? []}
                        value={"value"}
                        margin={{ top: 5, right: 0, bottom: 10, left: 0 }}
                        innerRadius={0.0}
                        padAngle={0.7}
                        arcLabel={(e) => {
                            return `${e.id} - ${parseModifiedValue(e?.value, outValue?.value, modificators)}`;
                        }}
                        colors={colorsPalette}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        isInteractive={interactive}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={0}
                        enableArcLinkLabels={false}

                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        tooltip={({ datum }) => <div style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                            borderRadius: 9,
                            display: "flex",
                            flexDirection: 'column'
                        }}>
                            <div style={{
                                display: 'flex',
                                marginRight: 10,
                                justifyItems: "center"
                            }}>

                                <div style={{ fontSize: 15, fontWeight: 500, color: "#6f6f6f" }}>
                                    {datum.label}:
                                </div>

                            </div>
                            <div style={{
                                alignItems: "center",
                                justifyItems: "center",
                                display: 'flex'
                            }}>
                                <div style={{
                                    fontSize: 15,
                                    fontWeight: 500, color: "#6f6f6f", marginRight: 10
                                }}>
                                    {outValue.label}:
                                </div>
                                <div style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    ...getModifiedStyle(datum.value, outValue.value, selectionData)
                                }}>
                                    {parseModifiedValue(datum.value, outValue.value, modificators)}
                                </div>
                            </div>

                        </div >}

                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}

                        legends={false}
                    />
                </div>
            </div>
        </>
    );
};

export { PieChart };
