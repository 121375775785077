import React from 'react';
import DefaultSelect from '../../../../shared/ui/DefaultSelect';

const SelectInput = ({ value, updateValue, options, label_key, value_key, multiple }) => {

    const onChange = (value) => {
        console.log(value);
        updateValue(value);
    }

    return (
        <DefaultSelect
            options={options ?? []}
            value={value ?? undefined}
            label_key={label_key}
            value_key={value_key}
            onChange={onChange}
            mode={multiple ? "multiple" : undefined}
        />
    );
};

export default SelectInput;