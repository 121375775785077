import { ManagersTable } from "@widgets/ManagersTable";

interface IManagersPage {}

const ManagersPage = ({}: IManagersPage) => {
  return (
    <div>
      <ManagersTable />
    </div>
  );
};

export default ManagersPage;
