import React from 'react';
import "./MindUserItem.scss"

const MindUserItem = ({ nodeDatum, toggleNode }) => (
    <g class="no-select">
        <circle class="user-circle" r="19" cx="15" stroke-width={0} onClick={toggleNode} />

        <text class='nodename-user' strokeOpacity={0} x="-60" y="40">
            {nodeDatum.name}
        </text>
        <g>
            <text class="description-user" fill="black" x="-60" y="60" strokeWidth="1">
                <tspan>{`Выполнено ${nodeDatum.attributes?.done}`}</tspan>
                <tspan>/</tspan>
                <tspan>{nodeDatum.attributes?.all}</tspan>
            </text>
        </g>
    </g>

);

export default MindUserItem;