import { Iframe } from '@shared/ui/Iframe';

const VideoGuidesPage = () => {
	return (
		<Iframe
			src="https://www.youtube.com/embed/ygU6Y0JunFg"
			title="YouTube video"
			allowFullScreen
			style={{ width: '70%', aspectRatio: 16 / 9 }}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			nameOfVideo="Инструкция по работе с отчётами"
		/>
	);
};

export default VideoGuidesPage;
