import { Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLoadPresetData } from '../../../entities/preset';
import { parseModificatorsColumns } from '../../../entities/preset/lib/ParseModificators';
import "./ExpandedRow.scss"

// expandable: (preset.type !== "table" ||
// (!preset.subroute?.expanded_row || preset.subroute?.expanded_row === "")) ? undefined
// :
// {
//     expandRowByClick: true,
//     expandedRowRender:
//         (record, index, indent, expanded) => <ExpandedRow
//             dashboardManagers={dashboardManagers}
//             index={index}
//             delta={preset.request.body?.delta}
//             period={period} setPeriod={setPeriod}
//             setExpandedRows={setExpandedRows}
//             expandedActionId={expandedActionId}
//             expanded={expanded}
//             row={record}
//             preset={preset} />,
//     rowExpandable: (record) => record.id !== 'Остальные' && record.id !== 'Итого'
// },



const ExpandedRow = ({
    setExpandedRows,
    index,
    period,
    setPeriod,
    selectionData,
    targetExpanded = 0,
    expanded,
    row,
    preset }) => {

    const [targetExpand, setTargetExpand] = useState({})
    const [targetPreset, setTargetPreset] = useState({})

    const getUrl = () => {
        let expandPresetUrl = ""
        const expandedRow = preset?.subroute?.expanded_row?.[targetExpanded];
        if (expandedRow) {
            expandPresetUrl = expandedRow?.url;
        }
        return expandPresetUrl
    }
    const preparePreset = () => {
        let expandPreset = {}
        const expandedRow = preset?.subroute?.expanded_row?.[targetExpanded];
        if (expandedRow) {
            setTargetExpand(expandedRow);
            expandedRow?.parent?.map(key => {
                expandPreset[key] = preset[key];
            })
            console.log("EXPANDED", preset);
            expandPreset["type"] = expandedRow?.type ?? "table";
            expandPreset["filters"] = [
                ...preset?.fields?.map(field => {
                    return {
                        "field": field.field,
                        "value": field.value,
                        "comparison": field.comparison ?? "like",
                        "condition": "and"
                    }
                })?.filter(filter => filter?.value !== undefined)?.filter(filter => filter.field !== expandedRow.field) ?? [],
                expandedRow?.keyFilter ? {
                    "field": expandedRow?.keyFilter?.values[preset[expandedRow?.keyFilter?.key]],
                    "value": row[expandedRow?.keyFilter?.values[preset[expandedRow?.keyFilter?.key]]],
                    "comparison": "=",
                    "condition": "and"
                } : undefined,
                expandedRow.field ? {
                    "field": expandedRow.field,
                    "value": row[expandedRow.field],
                    "comparison": "=",
                    "condition": "and"
                } : undefined].filter(filter => filter?.value !== undefined)

            expandPreset["period"] = period;
        }
        setTargetPreset(expandPreset);
        return expandPreset;
    }
    useEffect(() => {
        if (expanded) {
            preparePreset();
            refreshFunc();
        }
    }, [targetExpanded, expanded])
    useEffect(() => {


        if (expanded) {
            setExpandedRows(prev => [...prev, index])
        } else {

            setExpandedRows(prev => prev.filter(item => item != index))
        }

    }, [expanded])
    const { data, setData, loading, error, modificators, refreshFunc } = useLoadPresetData({ preset: targetPreset, noBreak: true, presetUrl: getUrl() });
    return (
        <div className='expanded'>


            {loading ? <div className='expanded__loading'>
                <Spin />
            </div> :
                <>

                    <div className='expanded__table'>
                        <Table
                            rowClassName={targetExpand?.link ? "row-expand " : null}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        event.stopPropagation()
                                        if (targetExpand?.link) {
                                            const fields = targetExpand?.link.fields;
                                            let url = targetExpand?.link?.url;
                                            if (fields && fields?.length > 0 && url) {
                                                fields.forEach(item => {
                                                    url = url.replace(item, record[item])
                                                })
                                            }
                                            window.open(url, '_blank').focus();
                                        }
                                    }, // click row
                                    onDoubleClick: (event) => { }, // double click row
                                    onContextMenu: (event) => { }, // right button click row
                                    onMouseEnter: (event) => { }, // mouse enter row
                                    onMouseLeave: (event) => { }, // mouse leave row
                                };
                            }}
                            rowKey={(record, index) => index}
                            pagination={false}
                            bordered={true}
                            columns={parseModificatorsColumns(data?.columns, modificators, [], preset?.plans || []) || []}
                            dataSource={data?.dataSource || []}
                        />
                    </div>
                </>
            }
        </div >
    );
};

export default ExpandedRow;