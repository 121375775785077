import { ApartmentOutlined, PlusOutlined, TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Radio, Segmented, Select, Spin, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import TasksService from '../service/TasksService';
import UserService from '../service/UserService';
import { setHeaderTitle, setTasksIds } from '../store/layoutSlice';
import "./Tasks.scss";
import CreateGroupModal from './components/CreateGroupModal/CreateGroupModal';
import CreateTaskModal from './components/CreateTaskModal/CreateTaskModal';
import MindMapComponent from './components/MindMap/MindMap';
import TableView from './components/TableView/TableView';
import Task from './components/Task/Task';



const Tasks = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [sendLoad, setSendLoad] = useState(false)
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [taskGrouped, setTaskGrouped] = useState([]);
    const [checked, setChecked] = useState([])
    const [targetTask, setTargetTask] = useState(null)
    const [visible, setVisible] = useState(false);
    const [visibleGroup, setVisibleGroup] = useState(false);
    const [openMind, setOpenMind] = useState(false);
    const user = useSelector((state) => state.user);
    const [groupFilter, setGroupFilter] = useState(null);
    const [syncFilter, setSyncFilter] = useState(null);
    const [userFilter, setUserFilter] = useState(null);
    const [myFilter, setMyFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingGetTasks, setLoadingGetTasks] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [viewMode, setViewMode] = useState("List");
    const [movedTask, setMovedTask] = useState(0)
    const [targetMindGroup, setTargetMindGroup] = useState(null)
    const dispatch = useDispatch();
    const groupingTasks = (tasks) => {
        let taskGroups = [];
        let groupingTasks = {};
        tasks.forEach(task => {
            const groupTask = task.task_group_id//groups.find(group => group.id === task.task_group_id);
            if (taskGroups.includes(groupTask)) {
                groupingTasks[groupTask].push({ ...task });
            } else {
                taskGroups.push(groupTask)
                groupingTasks[groupTask] = [task];
            }
        });
        setTaskGrouped(groupingTasks);

    }
    const getFiltersByUser = () => {
        return userFilter.map((user) => {
            return [
                {
                    "field": "directors",
                    "value": user,
                    "comparison": "="
                },

                {
                    "field": "executors",
                    "value": user,
                    "comparison": "="
                },
                {
                    "field": "executors",
                    "value": "," + user,
                    "comparison": "like"
                },
                {
                    "field": "executors",
                    "value": user + ",",
                    "comparison": "like"
                },
                {
                    "field": "responsibles",
                    "value": user,
                    "comparison": "="
                },
                {
                    "field": "responsibles",
                    "value": "," + user,
                    "comparison": "like"
                },
                {
                    "field": "responsibles",
                    "value": user + ",",
                    "comparison": "like"
                },
            ]
        })
    }
    useEffect(() => {
        if (loadingGroups)
            return
        if (!targetTask?.create) {
            setTargetTask(null)
        }
        console.log("userFilter", userFilter);
        let filters = []
        if (groupFilter) {
            filters.push({
                "field": "task_group_id",
                "value": groupFilter,
                "comparison": "="
            })
        }
        if (syncFilter != null && syncFilter !== "none") {
            if (syncFilter === 2) {
                filters.push({
                    "field": "result",
                    "value": null,
                    "comparison": "!="
                })
            } else {
                filters.push({
                    "field": "sync",
                    "value": syncFilter,
                    "comparison": "="
                })
            }
        }
        if (userFilter) {
            filters.push({
                "field": "responsibles,executors",
                "value": [userFilter],
                "comparison": "="
            })
        }
        console.log("filters", filters);
        setLoading(true)
        TasksService.getTasks(filters).then(response => {
            console.log("tasks resonse", response);
            setTasks(response.data.result);
            dispatch(setTasksIds(response.data.result.map(item => item.id)))
            groupingTasks(response.data.result)
        }).catch((err) => {
            console.log("Get tasks error", err);
            message.error("Не удалось загрузить задачи.");
        }).finally(() => {
            setLoading(false);
        })
    }, [syncFilter, groupFilter, loadingGroups, userFilter])
    useEffect(() => {
        setViewMode(localStorage.getItem("tasks_view") || "List")
        dispatch(setHeaderTitle("Задачи"));
        console.log("set view mode", localStorage.getItem("tasks_view"));
        setLoadingUsers(true)
        UserService.getUsers().then(response => {
            console.log("get users", response.data.filter(item => item.id != null));
            setUsers(response.data.filter(item => item.id != null));
        }).catch(err => {
            message.error("Не удалось загрузить пользователей")
            console.log("Error get users", err);
        }).finally(() => {
            setLoadingUsers(false);
        })
        setLoadingGroups(true);
        TasksService.getGroups().then(response => {
            console.log("get groups", [{ id: "new", title: "Новая задача", description: "" }, ...response.data]);
            setGroups([{ id: "new", title: "Новая задача", description: "" }, ...response.data]);
            const group_id = new URLSearchParams(location.search).get("group_id")
            if (group_id) {
                setGroupFilter(Number(group_id));
            }
        }).catch(err => {
            console.log("Error get users", err);
        }).finally(() => {
            setLoadingGroups(false);
        })
    }, [])

    // useEffect(() => {
    //     const group_id = new URLSearchParams(location.search).get("group_id")
    //     if (group_id) {
    //         setGroupFilter(group_id);
    //     }
    // }, [groups.length])
    const createTaskClick = () => {
        console.log("USER", {
            "id": 0,
            "create": true,
            "task_group_id": groupFilter ? groupFilter : "new",
            "title": "",
            "comment": "",
            "description": "",
            "comment": "",
            "executors": "",
            "directors": user.id,
            "responsibles": undefined,
            "deadline": moment().set({ hour: 18, minute: 0 }).add(2, 'day').format("YYYY-MM-DD HH:mm:ss")
        });
        setTargetTask({
            "id": 0,
            "create": true,
            "task_group_id": groupFilter ? groupFilter : "new",
            "title": "",
            "comment": "",
            "files": [],
            "description": "",
            "comment": "",
            "executors": "",
            "directors": user.id,
            "responsibles": undefined,
            "deadline": moment().set({ hour: 18, minute: 0 }).add(2, 'day').format("YYYY-MM-DD HH:mm:ss")
        })
        //setVisible(true);
    }
    const addTask = (task) => {
        console.log("ADD TASK", task);
        setTasks(prevTasks => {
            groupingTasks([task, ...prevTasks])
            return [task, ...prevTasks]
        });
        dispatch(setTasksIds(prevTasks => { return [task, ...prevTasks].map(item => item.id) }))


    }
    const updateTask = (task) => {
        console.log("UPDATED TASK", task, tasks);
        const newTasks = tasks.map(task_ => {
            if (task_.id === task.id) {
                return task
            }
            return task_
        })
        setTasks(newTasks);
        dispatch(setTasksIds(newTasks.map(item => item.id)))
        groupingTasks(newTasks)
    }
    const deleteTask = (id) => {
        const newTasks = tasks.filter(task => task.id != id);
        setTasks(newTasks);
        dispatch(setTasksIds(newTasks.map(item => item.id)))

        groupingTasks(newTasks)
    }
    const checkedAll = () => {
        if (checked.length === tasks.length) {
            setChecked([])
        } else {
            setChecked(tasks.map(task => task.id));
        }
    }


    const syncTasks = () => {
        sendToBitrix(checked);
    }
    const sendToBitrix = (checked) => {
        setSendLoad(true);
        TasksService.syncTasks(checked).then(response => {
            console.log(response.data);
            const newTasks = tasks.map(task_ => {
                if (checked.includes(task_.id)) {
                    return { ...task_, sync: 1 }
                }
                return task_
            })
            setChecked([])
            setTasks(newTasks);
            dispatch(setTasksIds(newTasks.map(item => item.id)))

            groupingTasks(newTasks)
            message.success("Задачи успешно отправлены в Bitrix");
        }).catch(err => {
            message.error("Не удалось отправить задачи в Bitrix");
        }).finally(() => {
            setSendLoad(false);
        })

    }
    const getFromBitrix = () => {
        setLoadingGetTasks(true);
        TasksService.getResultTasks(checked).then(response => {
            console.log("response", response);
            const newTasks = tasks.map(task_ => {
                if (checked.includes(task_.id)) {
                    if (response.data[task_.id]) {

                        return { ...response.data[task_.id] }
                    } else {

                        return task_
                    }
                }
                return task_
            })
            setChecked([])
            setTasks(newTasks);
            dispatch(setTasksIds(newTasks.map(item => item.id)))

            groupingTasks(newTasks)
            message.success("Задачи успешно обновленны из Bitrix");
        }).catch(err => {
            message.error("Не удалось обновить задачи из Bitrix");
        }).finally(() => {
            setLoadingGetTasks(false);
        })

    }
    const updateGroupFilter = (value) => {
        setGroupFilter(value);
        if (value != null)
            navigate({
                pathname: '/portal-tasks',
                search: '?group_id=' + value
            })
        else {
            navigate({
                pathname: '/portal-tasks'

            })
        }
    }


    const updateViewMode = (value) => {
        localStorage.setItem("tasks_view", value);
        setViewMode(value);
        console.log("set view mode", value);
    }

    return (
        <>
            <div className='tasks' style={{ maxWidth: viewMode === "Table" ? 2500 : undefined, width: viewMode === "Table" ? "100%" : undefined }}>
                <div className='tasks__add-new'>
                    <div className='tasks__add-new__task' onClick={createTaskClick}>

                        <PlusOutlined style={{ fontSize: 30 }} />
                        Добавить задачу
                    </div>
                    <div className='tasks__add-new__group' onClick={() => setVisibleGroup(true)}>
                        <PlusOutlined style={{ fontSize: 30 }} />
                        Создать событие
                    </div>
                </div>
                <div className='tasks__filter'>
                    <div className='tasks__filter__inputs'>
                        <div>

                            <Select
                                allowClear
                                style={{ width: 200, marginRight: 20 }}
                                placeholder="Событие"
                                value={groupFilter}
                                onChange={updateGroupFilter}
                                options={[{ id: 0, title: "Все" }, ...groups].filter(task => task.id !== "new").map(group => {
                                    return { value: group.id, label: group.title }
                                })}
                            />

                            <Select
                                placeholder="Пользователь"
                                allowClear
                                showSearch
                                key={"id"}
                                onChange={setUserFilter}
                                style={{ width: 200 }}
                                value={userFilter}
                                options={users.map(user => {

                                    return { label: user.name, value: user.id }
                                })}
                                filterOption={(inputValue, option) => {
                                    return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                                }}
                            />
                        </div>
                        <Radio.Group d value={syncFilter} buttonStyle="solid" style={{ marginRight: 10, marginTop: 10 }} onChange={(event) => {
                            setTimeout(() => {
                                setSyncFilter(event.target.value)
                            })
                        }}>
                            {[{ value: "none", label: "Все" }, { value: 1, label: "Отправленные" }, { value: 0, label: "Не отправленные" }, { value: 2, label: "Завершенные" }].map(key => {
                                return <Radio.Button key={key.value} value={key.value}>{key.label}</Radio.Button>
                            })}
                        </Radio.Group>
                        {/* <Checkbox checked={myFilter} onChange={(e) => setMyFilter(e.target.checked)}>Только личные</Checkbox> */}
                    </div>
                    {(user?.portal_isAdmin || user?.portal_isSupervisor) && <div className='tasks__filter__actions'>
                        <Checkbox
                            indeterminate={checked.length && !(checked.length === tasks.length)}
                            style={{ fontSize: 14 }}
                            checked={checked.length === tasks.length && tasks.length > 0}
                            onChange={checkedAll}
                        >
                            Выделить все
                        </Checkbox>
                        <Button loading={sendLoad} style={{ marginRight: 10 }} type={"primary"} onClick={syncTasks}>
                            Отправить
                        </Button>
                        <Button type={"default"} onClick={getFromBitrix} loading={loadingGetTasks}>
                            Получить из Bitrix
                        </Button>
                    </div>}
                </div>
                <Segmented
                    style={{ marginTop: 10 }}
                    value={viewMode}
                    onChange={updateViewMode}
                    options={[
                        {
                            value: 'List',
                            icon: <UnorderedListOutlined />,
                        },
                        {
                            value: 'Table',
                            icon: <TableOutlined />,
                        },
                    ]}
                />

                <>
                    {viewMode === "Table" ?
                        <>

                            <TableView
                                targetTask={targetTask}
                                user={user}
                                task={targetTask}
                                deleteTask={deleteTask}
                                addTask={addTask}
                                groups={groups}
                                setGroups={setGroups}
                                setChecked={setChecked}
                                checked={checked}
                                setTargetTask={setTargetTask}
                                updateTask={updateTask}
                                users={users}
                                tasks={tasks}
                                loading={loading || loadingUsers}
                                setTasks={setTasks}
                            />
                        </>
                        :
                        <>

                            {targetTask != null && <div >
                                <div className='tasks__group'>
                                    {targetTask?.move ? <Divider className='tasks__group__title' plain>Перенос задачи</Divider> : <Divider className='tasks__group__title' plain>Новая задача</Divider>}
                                </div>
                                <div className='tasks__items'>
                                    <Task
                                        user={user}
                                        deleteTask={deleteTask}
                                        groups={groups}
                                        setGroups={setGroups}
                                        setChecked={setChecked}
                                        checked={checked}
                                        updateTask={updateTask}
                                        users={users}
                                        task={targetTask}
                                        setTargetTask={setTargetTask}
                                        addTask={addTask}
                                        setTasks={setTasks}
                                        setGroupFilter={setGroupFilter}
                                        key={targetTask?.id} />
                                </div>
                            </div>}
                            {(loading || loadingUsers) ?
                                <div className='tasks__load'>
                                    <Spin />
                                </div>
                                : <>

                                    {Object.keys(taskGrouped).length > 0 ?
                                        <>
                                            {Object.keys(taskGrouped).map(group => {

                                                return <div key={group}>
                                                    <div className='tasks__group'>
                                                        <Divider className='tasks__group__title' plain>
                                                            <div style={{ display: "flex" }}>
                                                                <div>
                                                                    {groups.find(group_ => group_.id === Number(group))?.title || "Неизвестная"}
                                                                </div>
                                                                <Button
                                                                    style={{ marginLeft: 10 }}
                                                                    shape="circle"
                                                                    icon={<ApartmentOutlined />}
                                                                    onClick={() => {
                                                                        setTargetMindGroup(groups.find(group_ => group_.id === Number(group)));
                                                                        setOpenMind(true);
                                                                    }} />
                                                            </div>
                                                        </Divider>
                                                    </div>
                                                    <div className='tasks__items'>
                                                        {taskGrouped[group].map(task => <Task
                                                            setMovedTask={setMovedTask}
                                                            movedTask={movedTask}
                                                            user={user}
                                                            files={task.files}
                                                            sendToBitrix={sendToBitrix}
                                                            deleteTask={deleteTask}
                                                            addTask={addTask}
                                                            groups={groups}
                                                            setGroups={setGroups}
                                                            setChecked={setChecked}
                                                            checked={checked}
                                                            setTargetTask={setTargetTask}
                                                            updateTask={updateTask}
                                                            users={users}
                                                            setGroupFilter={setGroupFilter}
                                                            groupingTasks={groupingTasks}
                                                            tasks={tasks}
                                                            task={task}
                                                            setTasks={setTasks}
                                                            key={task.id} />)}
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </>
                                        :
                                        <div>
                                            {`${loadingGroups ? "" : "Задач не найдено"}`}
                                        </div>
                                    }
                                </>}
                        </>}
                </>

                <CreateTaskModal
                    task={targetTask}
                    setVisible={setVisible}
                    visible={visible}
                    users={users}
                    user={user}

                    addTask={addTask}
                    groups={groups}
                    setGroups={setGroups}
                />

                <CreateGroupModal
                    setSyncFilter={setSyncFilter}
                    groups={groups}
                    setGroups={setGroups}
                    setVisible={setVisibleGroup}
                    visible={visibleGroup}
                    setGroupFilter={setGroupFilter}
                />
            </div >
            <MindMapComponent visible={openMind} setVisible={(state) => { setOpenMind(state); setTargetMindGroup(null) }} group={targetMindGroup} users={users} tasks={targetMindGroup ? taskGrouped?.[targetMindGroup.id] : []} />
        </>
    );
};

export default Tasks;