import React from 'react';
import FormItem from '../FormItem';
import RadioButtonInput from '../inputs/RadioButtonInput';
import SelectInput from '../inputs/SelectInput';

const OrderField = ({ preset, updatePreset, field }) => {

    const updateValue = (value) => {
        console.log("THERE");
        updatePreset([["order_by", value]])
    }

    return (
        <SelectInput
            updateValue={updateValue}
            value={preset.order_by ?? field?.default_value}
            options={[...preset?.subroute?.out_values, ...field?.extra_options ?? []]}
            label_key={"label"}
            value_key="value"
        />
    );
};

export default OrderField;