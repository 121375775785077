import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getManagers, updateManager } from "../api";

export const useManagersList = () => {
  const [managers, setManagers] = useState<TManager[]>([]);
  const [managerName, setManagerName] = useState<string>("");
  const [editingManager, setEditingManager] = useState<TManager | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatingLoading, setUpdatingLoading] = useState(false);
  const timeout = useRef<any>();

  const onStartEditing = (manager: TManager) => {
    setEditingManager(manager);
    setIsEditing(true);
  };

  const onCloseEditing = () => {
    setEditingManager(null);
    setIsEditing(false);
  };
  const loadManagers = (name?: string) => {
    setLoading(true);
    getManagers(name)
      .then((response: any) => {
        setManagers(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadManagersWithTimeout = (name: string) => {
    setLoading(true);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      loadManagers(name);
    }, 600);
  };

  const onSave = (manager: TManager) => {
    if (!editingManager?.id) return;

    setUpdatingLoading(true);
    updateManager({
      ...manager,
      id: editingManager.id,
    })
      .then((response) => {
        onCloseEditing();
        const updatedManger = response.data.data;
        setManagers(
          managers.map((manager) =>
            manager.id === updatedManger.id ? updatedManger : manager
          )
        );
        console.log("respnsoe", response.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setUpdatingLoading(false);
      });
  };
  const onChangeManagerName = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setManagerName(name);
    loadManagersWithTimeout(name);
  };
  useEffect(() => {
    loadManagers();
  }, []);

  return {
    loading,
    managers,
    isEditing,
    editingManager,
    updatingLoading,
    managerName,
    onSave,
    onStartEditing,
    onCloseEditing,
    onChangeManagerName,
  };
};
