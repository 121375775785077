import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import moment from "moment";
import { useState } from "react";
import { dashboardModel } from "../../../entities/dashboard";
import { useUpdatePresetInDashboard } from "../../../entities/dashboard/model/model";
import { getPresetData } from "../../../entities/preset/api";
import {
  getRequestBody,
  getRequestUrl,
} from "../../../entities/preset/lib/ParsePresetInfo";
import { useUpdatePreset } from "../../../entities/preset/models";
import PickColorButton from "../../../shared/ui/PickColorButton";
import { useSelector } from "react-redux";
import { UserAccess, userModel } from "@entities/user";

const PresetHeader = ({
  preset,
  openBoxModal,
  noTableMargin,
  setNoTableMargin,
  dashboard,
  editorMode = false,
  openModifyPreset,
  reloadGraph,
  isModal,
}) => {
  const editeMode = useSelector(dashboardModel.selectEditeModeDashboard);
  const updatePreset = useUpdatePreset(preset);
  const updatePresetInDashboard = useUpdatePresetInDashboard(
    preset.row_id,
    preset.key_id
  );
  const [loading, setLoading] = useState(false);
  const isAdmin = useSelector(userModel.selectUserIsAdmin);
  const ref = useSelector(userModel.selectUserRef);
  const updateBackgroundColor = (rgb) => {
    const newPreset = {
      ...JSON.parse(JSON.stringify(preset)),
      background: rgb,
    };
    updatePresetInDashboard(newPreset);
  };

  const loadExcel = () => {
    setLoading(true);
    const copiedPreset = JSON.parse(JSON.stringify(preset));
    const url = getRequestUrl(copiedPreset);
    const body = getRequestBody({ ...copiedPreset, excel: true });
    console.log("LOAD PRESET");
    if (!isAdmin) {
      body.filters = [
        ...(body.filters ?? []),
        // {
        //   field: "manager",
        //   value: !ref ? "ref" : ref,
        //   comparison: "like",
        //   condition: "and",
        // },
      ];
    }
    getPresetData(null, url, body)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.url;

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((e) => {
        console.log("ERROR", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onChangeNoTableMargin = () => {
    localStorage.setItem("groupChatWithMargin", noTableMargin ? "0" : "1");
    setNoTableMargin(!noTableMargin);
  };

  return (
    <div className="preset__header">
      <div>
        <div className="preset__header__label">{preset.name}</div>
        <div className="preset__header__period">
          {preset.period?.length > 1 && (
            <div>
              {moment(preset.period[0]).format("DD MMMM YYYY")} -{" "}
              {moment(preset.period[1]).format("DD MMMM YYYY")}
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {/* {!editorMode && <div className='preset__header__checkbox' style={{ marginRight: 10 }} onClick={(e) => e.stopPropagation()}>
                <Button
                    onMouseDown={() => { setMovingPreset(preset) }}
                    onMouseUp={() => { setMovingPreset(null) }}
                    shape="circle"
                    icon={<CopyOutlined />} />

            </div>} */}
        {!editeMode && (
          <div
            className="preset__header__checkbox"
            style={{ marginRight: 10 }}
            onClick={(e) => e.stopPropagation()}
          >
            {setNoTableMargin && preset.type === "table" && (
              <div>
                <Checkbox
                  checked={noTableMargin}
                  onChange={onChangeNoTableMargin}
                >
                  Убрать отступы в таблице
                </Checkbox>
              </div>
            )}

            {isModal && preset?.subroute?.support_excel && (
              <Button
                style={{ marginRight: 10 }}
                onClick={loadExcel}
                loading={loading}
                icon={<ReloadOutlined />}
              >
                Скачать Excel
              </Button>
            )}
            {openBoxModal && (
              <Button type="link" onClick={openBoxModal}>
                Открыть полностью
              </Button>
            )}

            {reloadGraph && (
              <Button
                style={{ marginRight: 10 }}
                onClick={reloadGraph}
                shape="circle"
                icon={<ReloadOutlined />}
              />
            )}
            {openModifyPreset && (
              <UserAccess.DashboardEditAccess dashboard={dashboard}>
                <Button
                  onClick={openModifyPreset}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </UserAccess.DashboardEditAccess>
            )}
          </div>
        )}
        {/* {!editorMode && <div className='preset__header__checkbox' onClick={(e) => e.stopPropagation()}>
                <Button
                    onClick={() => updateExtInfoCheckbox(!additionalInfo)}
                    style={{ border: "1px solid #eaeaea", background: additionalInfo ? "#eae6e6" : "white" }}
                    shape="circle"
                    icon={<InfoOutlined />} />

            </div>} */}
        {editeMode && (
          <div
            className="preset__header__color"
            onClick={(e) => e.stopPropagation()}
          >
            <PickColorButton
              setColorRgb={updateBackgroundColor}
              color={preset?.background}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PresetHeader;
