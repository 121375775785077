import React, { useEffect, useRef, useState } from 'react';
import "./Rubric.scss"
import QuestionService from '../../../service/QuestionService';
import { Button, message, Popconfirm, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import autoAnimate from '@formkit/auto-animate';
import { setHeaderTitle } from '../../../store/layoutSlice';
import ItemModal from '../Modals/ItemModal';
import { useNavigate, useParams } from 'react-router-dom';
const Rubric = () => {
    const [items, setItems] = useState([])
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [targetItem, setTargetItem] = useState({})
    const user = useSelector(state => state.user)
    const parentRef = useRef();
    const [visible, setVisible] = useState(false)


    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);

    useEffect(() => {
        dispatch(setHeaderTitle(""));
        console.log("Load ...");
        QuestionService.getArticleRubric(params.id).then((response) => {
            console.log("THER", response);
            dispatch(setHeaderTitle(response.data.rubric_title))
        })
        QuestionService.getArticleItems(params.id).then((reponse) => {
            console.log("items", reponse.data);
            setItems(reponse.data.filter(item => item.article_rubric_id == Number(params.id)));
        })
    }, [])

    const routeToItem = (item) => {
        navigate("/portal-questions/item/" + item.id)
    }

    const deleteItem = (item) => {

        QuestionService.deleteArticleItem(item.id).then(() => {
            setItems(items.filter(item_ => item_.id !== item.id));
            message.success("Статья успешно удалёна!");
        }).catch(() => {
            message.error("Не удалось удалить статью!");
        })
    }
    return (
        <>
            <Button
                onClick={() => {
                    setTargetItem({
                        article_title: "",
                        article_content: "",
                        article_excerpt: "",
                        article_rubric_id: params.id,
                        create: true
                    })
                    setVisible(true);
                }}
                style={{ marginTop: 10, marginBottom: 15, marginLeft: 15 }}>
                Создать статью
            </Button>
            <div parent={parentRef}>
                {
                    items && items.map(item => {
                        return <div className='items' key={item.id} onClick={() => routeToItem(item)} parent={parentRef}>
                            <div>
                                <Typography.Title level={4}>
                                    {item.article_title}
                                </Typography.Title>
                                <Typography.Text level={4}>
                                    {item.article_content}
                                </Typography.Text>
                            </div>

                            {(user.isAdmin || user.s_isAdmin) &&
                                <div className='items__actions' onClick={e => e.stopPropagation()}>
                                    <Button shape="circle" icon={<EditOutlined />} style={{ marginRight: 10 }} size={20} onClick={() => {
                                        setTargetItem(item);
                                        setVisible(true);
                                    }} />
                                    <Popconfirm
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title="Удалить рубрику?"
                                        onConfirm={() => deleteItem(item)}
                                        okText="Удалить"
                                        cancelText="Отмена"
                                    >
                                        <Button shape="circle" icon={<DeleteOutlined />} size={20} />
                                    </Popconfirm>
                                </div>}
                        </div>
                    })
                }
            </div>
            <ItemModal visible={visible} setVisible={setVisible} targetItem={targetItem} items={items} setItems={setItems} />

        </>
    );
};

export default Rubric;