import React from 'react';
import { useUpdatePreset } from '../../../../entities/preset/models';
import { validateNumbers } from '../../../../shared/lib';
import FormItem from '../FormItem';
import InputInput from '../inputs/InputInput';
import RadioButtonInput from '../inputs/RadioButtonInput';
import SelectInput from '../inputs/SelectInput';
import ColorSelection from '../PreparedFields/ColorSelection';
import DateField from '../PreparedFields/DateField';
import OrderField from '../PreparedFields/OrderField';
import PlanField from '../PreparedFields/PlanField';
import ExtraField from './ExtraFields';
import CitySelect from '../PreparedFields/CitySelect';

const InputField = ({ field, preset }) => {
    const updatePreset = useUpdatePreset(preset);

    const updateValue = (value) => {
        if (field?.validate === "number") {
            if (!validateNumbers(value)) return;
        }
        updatePreset([[field.key, value]])
    }
    const checkDisabled = () => {
        const flags = field?.disabled?.split(":")
        if (flags) {
            const [flag, key] = flags
            if (flag === "!") {
                return !preset?.[key];
            } else {
                return preset?.[key];
            }
        }
    }
    const getField = () => {
        switch (field.field) {
            case "input":
                return <InputInput disabled={checkDisabled()} updateValue={updateValue} value={preset[field.key] ?? field?.default_value} />

            case "date":
                return <DateField disabled={checkDisabled()} updatePreset={updatePreset} preset={preset} field={field} />
            case "order_by":
                return <OrderField disabled={checkDisabled()} updatePreset={updatePreset} preset={preset} field={field} />
            case "radio":
                return <RadioButtonInput disabled={checkDisabled()} updateValue={updateValue} value={preset[field.key] ?? field?.default_value} options={field.options} />
            case "colors":
                return <ColorSelection disabled={checkDisabled()} updatePreset={updatePreset} preset={preset} />
            case "plans":
                return <PlanField updatePreset={updatePreset} preset={preset} field={field} />
            case "select":
                return <SelectInput value={preset[field.key]} updateValue={updateValue} options={field.options} label_key="label" value_key="value" multiple={field.multiple} preset={preset} field={field} />

            default:
                return <div>{field.field}</div>
        }
    }


    return (
        <>
            <FormItem labels={field?.label}>
                {getField(field.field)}

            </FormItem>
            {field?.extra_fields?.map((extra_field, index) => {
                return <ExtraField key={index} updatePreset={updatePreset} extra_field={extra_field} preset={preset} field={field} />
            })}
        </>
    );
};

export default InputField;