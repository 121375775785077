import { $api } from "./http";

export default class QuestionService {
    static async getArticleChapters() {
        return $api.get("/api/article_chapter");
    }
    static async getArticleChapter(id) {
        return $api.get("/api/article_chapter/" + id);
    }
    static async createArticleChapter(chapter) {
        return $api.post("/api/article_chapter", chapter);
    }
    static async updateArticleChapter(id, chapter) {
        return $api.put("/api/article_chapter/" + id, chapter);
    }
    static async deleteArticleChapter(id) {
        return $api.delete("/api/article_chapter/" + id);
    }
    static async getArticleRubrics() {
        return $api.get("/api/article_rubric");
    }
    static async getArticleRubric(id) {
        return $api.get("/api/article_rubric/" + id);
    }
    static async createArticleRubric(chapter) {
        return $api.post("/api/article_rubric", chapter);
    }
    static async updateArticleRubric(id, chapter) {
        return $api.put("/api/article_rubric/" + id, chapter);
    }
    static async deleteArticleRubric(id) {
        return $api.delete("/api/article_rubric/" + id);
    }
    // ----
    static async getArticleItems() {
        return $api.get("/api/article_item");
    }
    static async getArticleItem(id) {
        return $api.get("/api/article_item/" + id);
    }
    static async createArticleItem(chapter) {
        return $api.post("/api/article_item", chapter);
    }
    static async updateArticleItem(id, chapter) {
        return $api.put("/api/article_item/" + id, chapter);
    }
    static async deleteArticleItem(id) {
        return $api.delete("/api/article_item/" + id);
    }
    // ---------
    static async getArticleStatuses() {
        return $api.get("/api/article_status");
    }
    static async getArticleStatus(id) {
        return $api.get("/api/article_status/" + id);
    }
    static async createArticleStatus(chapter) {
        return $api.post("/api/article_status", chapter);
    }
    static async updateArticleStatus(id, chapter) {
        return $api.put("/api/article_status/" + id, chapter);
    }
    static async deleteArticleStatus(id) {
        return $api.delete("/api/article_status/" + id);
    }
}