import React from 'react';
import { colorsPalette } from '../../Dashboard/utils';
import "./CustomLegend.scss"

const CustomLegend = ({ legends }) => {
    return (
        <div className='legends' onClick={(e) => e.stopPropagation()}>
            {legends?.length > 0 && legends?.map((legend, index) => {
                return <div key={index} className="legends__legend">
                    <div className='legends__legend__color' style={{ backgroundColor: colorsPalette[index] }}>
                    </div>
                    <div className='legends__legend__text'>
                        {legend}
                    </div>
                </div>
            })}
        </div>
    );
};

export default CustomLegend;