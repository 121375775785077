import React, { useEffect, useRef, useState } from 'react';
import { BarItem, ResponsiveBar } from '@nivo/bar'
import autoAnimate from '@formkit/auto-animate';
import { modifyValueToFormat, parseModifiedValue } from '../lib/ParseModificators';
import { colorsPalette } from '../config';
import CustomLegend from '../../../../components/CustomLegend/CustomLegend';
import Tooltip from './Tooltip';
import { getLegend } from '../lib/ParseLabel';

const BarChart = ({
    data,
    indexBy,
    outValues,
    isInteractive,
    modificators,
    selectionData }) => {

    const parentRef = useRef();

    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);




    const itemWidth = useRef({});
    return (
        <>
            <div style={{ height: "100%", width: "100%" }} ref={parentRef}>
                {true && <CustomLegend legends={outValues?.map(item => item.label) || []} />}
                <div style={{ height: true ? "85%" : "100%" }}>
                    <ResponsiveBar
                        data={data}
                        keys={outValues?.map(item => item.value) || []}
                        indexBy={indexBy}
                        barComponent={(props) => {
                            itemWidth.current[props.bar.index] = props.bar.width;
                            return <BarItem {...props} />
                        }}

                        margin={{ top: 10, right: 10, bottom: 30, left: 10 }}
                        padding={data?.length === 1 ? 0.45 : 0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={colorsPalette}
                        labelSkipHeight={8}
                        isInteractive={isInteractive}
                        isFocusable={false}
                        animate={isInteractive}
                        label={(item) => {
                            return parseModifiedValue(item.value, item.id, modificators)
                        }}
                        //valueFormat={(value) => value + "x"}
                        defs={
                            [
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}

                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={null}
                        axisBottom={{
                            tickSize: 10,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "",
                            legendPosition: "middle",

                            renderTick: ({
                                tickIndex,
                                textAnchor,
                                textBaseline,
                                textX,
                                textY,
                                value,
                                x,
                                y
                            }) => {

                                return (

                                    <g transform={`translate(${x} ${y})`} rotate={20}>

                                        <text

                                            alignmentBaseline={textBaseline}
                                            textAnchor={textAnchor}
                                            transform={`translate(${textX}, ${textY} ) rotate(8 0 0)`}

                                        >
                                            <tspan

                                                style={{
                                                    fontSize: 10,
                                                    overflow: "hidden"
                                                }}>
                                                {getLegend(value)}
                                            </tspan>
                                        </text >
                                    </g>


                                );
                            }
                        }}
                        gridYValues={[0]}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    '2.5'
                                ]
                            ]
                        }}
                        legends={
                            [

                            ]}
                        tooltip={(datum) => <Tooltip
                            modificators={modificators}
                            label={outValues?.find(item => item.value === datum.id).label}
                            datum={datum}
                            selectionData={selectionData}
                        />}
                        role="application"
                        ariaLabel="График"
                        barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                    />
                </div>
            </div>
        </>
    );
};

export default BarChart;