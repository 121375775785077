export const getRequestBody = (preset) => {
    let body = {};
    const fields = preset?.fields;
    if (fields) {
        const filters = [...preset?.filters ? preset?.filters : [], ...fields.filter(field => field?.value).map(field => {
            return {
                "field": field.field,
                "value": field.value,
                "comparison": field.comparison ?? "like",
                "condition": "and"
            }
        })]
        delete preset.fields;
        body["filters"] = filters;
    }
    delete preset.route;
    delete preset.subroute;
    body = { ...preset, ...body }
    return body
}
export const getRequestUrl = (preset) => {
    const url = preset.subroute.url;
    const domain = preset.route.domain;
    return domain + url;
}
export const getRequestParams = (preset) => {

}