import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboard } from "../api";
import { selectCurrentDashboard } from "./selectors";
import { updateDashboards } from "./store";

export const useUpdatePresetInDashboard = (row_id, key_id) => {
  const dashboard = useSelector(selectCurrentDashboard);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(false);
  const [mount, setMount] = useState(false);
  useEffect(() => {
    if (!mount) {
      setMount(true);
      return;
    }
    const timeout = setTimeout(() => {
      const targetDashboard = dashboard;
      if (!targetDashboard) return;

      updateDashboard(targetDashboard.id, {
        ...targetDashboard,
      }).then(() => {});
    }, 1500);
    return () => clearTimeout(timeout);
  }, [trigger]);

  const updatePresetInDashboard = (preset) => {
    const targetDashboard = dashboard;
    if (!targetDashboard) return;

    const modifiedDashboard = {
      ...targetDashboard,
      content: targetDashboard.content.map((row) => {
        if (row.row_id === row_id) {
          return {
            ...row,
            cols: row.cols.map((col) => {
              if (col.key_id === key_id) {
                return preset;
              }
              return col;
            }),
          };
        } else {
          return row;
        }
      }),
    };
    dispatch(updateDashboards(modifiedDashboard));
    setTrigger(!trigger);
  };
  return updatePresetInDashboard;
};
