import { useEffect, useRef, useState } from "react";
import { getValuesByKeys } from "../api";

interface IRequestedSelect {
  values: string | string[] | number | number[] | undefined;
  onSelect?: (
    choosenKeys: number[] | number | string | string[],
    fullItem: any
  ) => void;
  requestUrl: string;
  queryTypedParam: string;
  queryChoosenParams: string;
  valueLabel: string;
  valueKey: string;
  extraKey?: string;
  extraСondition?: (item: any) => boolean;
}

export const useRequestedSelect = ({
  extraСondition,
  extraKey,
  values,
  onSelect,
  requestUrl,
  queryTypedParam,
  queryChoosenParams,
  valueLabel,
  valueKey,
}: IRequestedSelect) => {
  const [options, setOptions] = useState<[]>([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const timer = useRef<any>();

  const getValues = (typedKey: string, choosenKeys: any) => {
    setLoading(true);
    getValuesByKeys(
      typedKey,
      typeof choosenKeys !== "object" ? [choosenKeys] : choosenKeys,
      requestUrl,
      queryTypedParam,
      queryChoosenParams
    )
      .then((response) => {
        const values = response.data.data ?? response.data;
        setOptions(values);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getValues("", values);
  }, []);

  const onChangeTypedKey = (typedKey: string) => {
    setId(typedKey);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      getValues(typedKey, values);
    }, 400);
  };

  const onSelectKey = (
    keys: string | string[] | number | number[],
    options: any
  ) => {
    console.log("item", options);

    onSelect &&
      onSelect(
        keys,
        Array.isArray(options)
          ? options.map((option) => option.item)
          : options.item
      );
  };
  const getKey = (value: any) => {
    if (extraСondition && extraСondition(value) && extraKey) {
      return extraKey;
    }
    return valueKey;
  };
  return {
    id,
    valuesOptions: options.map((value) => ({
      label: value[valueLabel],
      value: value[getKey(value)],
      item: value,
    })),
    loading,
    values,
    onChangeTypedKey,
    onSelectKey,
  };
};
