import { $api } from "../../../service/http";
const route = "api/manager";
export const getManagers = (name?: string) => {
  return $api.get(route, {
    params: {
      name,
    },
  });
};
export const updateManager = (manager: TManager) => {
  return $api.put(`${route}/${manager.id}`, manager);
};
