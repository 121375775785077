import { Form, Input, message, Modal, Typography } from 'antd';
import React, { useEffect } from 'react';
import QuestionService from '../../../service/QuestionService';

const ChapterModal = ({ visible, setVisible, targetChapter, chapters, setChapters }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(targetChapter);
    }, [visible])
    function sendForm() {
        form
            .validateFields()
            .then((values) => {
                if (targetChapter.create) {
                    QuestionService.createArticleChapter(values).then((response) => {
                        message.success("Раздел успешно создан!");

                        setChapters([...chapters, response.data]);
                        setVisible(false);
                    }).catch(() => {
                        message.error("Не удалось создать раздел!");
                    }).finally(() => {
                    })
                } else {
                    QuestionService.updateArticleChapter(targetChapter.id, { ...values }).then((response) => {
                        message.success("Раздел успешно обновлён!");
                        setChapters(chapters.map(item => {
                            if (item.id === targetChapter.id) {
                                return response.data
                            } else {
                                return item;
                            }
                        }));
                        setVisible(false);
                    }).catch(() => {
                        message.error("Не удалось обновить раздел!");
                    }).finally(() => {


                    })
                }
            })
            .catch((error) => {
                console.log("Validate Failed:", error);
            });
    }
    return (
        <Modal
            title={
                <Typography.Title level={4}>{targetChapter.create ? "Создать" : "Обновить"}</Typography.Title>
            }
            centered
            open={visible}
            onOk={sendForm}
            okText={targetChapter.create ? "Создать" : "Обновить"}
            onCancel={() => setVisible(false)}
            closable={false}
            destroyOnClose
            forceRender
            width={window.screen.width < 450 ? "100%" : 500}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}
        >
            <Form
                name="title"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Заголовок раздела"
                    required
                    name="chapter_title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Текст раздела"
                    required
                    name="chapter_content"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChapterModal;