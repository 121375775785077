import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Segmented, Select, Typography } from 'antd';
import { useEffect } from 'react';
import { uniqueId } from '../../../../shared/lib';
import FormItem from '../FormItem';

const PlanField = ({ preset, updatePreset, field }) => {


    useEffect(() => {
        if (!Array.isArray(preset?.plans)) {
            updatePlan([]);
        }
    }, [])
    const updatePlan = (plan) => {
        console.log("PLAN", plan);
        updatePreset([["plans", plan]])
    }

    const updatePlanValue = (id, value, key) => {
        updatePlan(preset?.plans?.map(plan => {
            if (plan.id === id) {
                return {
                    ...plan,
                    [key]: value
                }
            }
            return plan;
        }))
    }


    const addPlan = () => {
        if (preset?.outValues?.length > (preset?.plans?.length || 0)) {
            const id = uniqueId()
            updatePlan([...(preset?.plans || []), {
                "id": id,
                "title": "План " + ((preset?.plans?.length || 0) + 1),
                "fact_value": null,
                "plan_value": null,
                "inverseColors": false,
                "plan_type": "all"
            }])
        }
    }
    const deletePlan = (id) => {
        updatePlan(preset?.plans.filter(plan => plan.id != id))
    }

    return (<FormItem labels={"План"}>
        <div stlye={{ width: "100%" }}>
            <Button onClick={addPlan}>
                Добавить
            </Button>
            {Array.isArray(preset?.plans) && preset?.plans?.map(plan => {
                return <div key={plan.id} style={{ marginTop: 15, marginBottom: 10 }} >
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 7
                    }}>
                        <Typography.Text
                            ellipsis={{ tooltip: plan.title }}
                            style={{
                                fontWeight: 500,
                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                                width: "60%"
                            }}>
                            {plan.title}
                        </Typography.Text>
                        <div>
                            <Segmented
                                size='small'
                                value={plan?.plan_type || "all"}
                                onChange={(value) => updatePlanValue(plan.id, value, "plan_type")}

                                options={
                                    [
                                        { label: 'Абсолютный', value: 'all' },
                                        { label: 'Относительный', value: 'now' }
                                    ]} />
                        </div>

                    </div>
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>

                            <Input
                                style={{

                                }}
                                onChange={(e) => updatePlanValue(plan.id, e.target.value, "title")}
                                value={plan.title}
                                placeholder='Название' />
                            <Button
                                onClick={() => deletePlan(plan.id)}
                                danger
                                type="link"
                                style={{ marginLeft: 10, width: 50 }}
                                icon={<DeleteOutlined />} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "46%" }}>
                                <div style={{ marginBottom: 5 }}>Фактическое</div>
                                <Select
                                    style={{ width: "100%" }}
                                    value={plan?.fact_value}
                                    options={preset.subroute?.out_values?.filter(item => preset?.outValues?.includes(item.value))}
                                    onChange={(value) => updatePlanValue(plan.id, value, "fact_value")}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "46%" }}>
                                <div style={{ marginBottom: 5 }}>Планируемое</div>
                                <Select
                                    value={plan?.plan_value}

                                    style={{ width: "100%" }}
                                    options={preset.subroute?.out_values?.filter(item => preset?.outValues?.includes(item.value))}
                                    onChange={(value) => updatePlanValue(plan.id, value, "plan_value")}
                                />
                            </div>
                        </div>
                    </div>
                    <Checkbox checked={plan?.inverseColors} onChange={(e) => updatePlanValue(plan.id, e.target.checked, "inverseColors")}>{"Инверсия подсветки значений"}</Checkbox>
                </div>
            })}

        </div>
    </FormItem>
    );
};

export default PlanField;