import { $api } from "../../../service/http";

export function getPresetData(source, url, filters = []) {
    const config = {
        cancelToken: source ? source.token : undefined,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    };

    const postUrl =
        process.env.NODE_ENV === "production"
            ? url
            : url.replace("https://portal.vakio.ru", "http://vakio-portal.loc");
    return $api.post(postUrl, filters, config);
}

export const getOrderParamsReq = (routeKey, keyId) => {
    return $api.get(`/api/table-column-order`, {
        params: { route_key: routeKey, key_id: keyId },
    });
};
export const saveOrderParamsReq = (orderParams, routeKey, keyId) => {
    return $api.post(`/api/table-column-order/${routeKey}/${keyId}`, {
        orderParams,
    });
};
