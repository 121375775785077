import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useUpdateDashboard } from "../model";
import EditDashboardModal from "./EditDashboardModal";

interface IUpdateDashboardButton {

}

const UpdateDashboardButton = ({

}: IUpdateDashboardButton) => {

    const { onSubmit,
        visible,
        dashboard,
        loading,
        onOpenModal,
        onCancel } = useUpdateDashboard();

    return (
        <>
            <Button
                icon={<EditOutlined />}
                style={{ marginLeft: 10 }}
                type="link"
                loading={loading}
                size="large"
                onClick={onOpenModal}
            />
            <EditDashboardModal
                omSubmit={onSubmit}
                dashboard={dashboard}
                onCancel={onCancel}
                visible={visible}
            />
        </>
    );
};

export default UpdateDashboardButton;