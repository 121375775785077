import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { testing } from '../chartsTypes';

const cookies = new Cookies();
const initialState = {
    id: null,
    phone: "",
    name: "",
    email: "",
    capabilities: "",
    accessToken: "",
    refreshToken: "",
    isAdmin: false,
    s_isAdmin: false,
    p_isSupervisor: false,
    p_isCoordinator: false,
    p_isBrigadier: false,
    p_isUser: false,
    d_isManager: false,
    d_isAdminManager: false,
    d_isUser: false,
    f_isEditor: false,
    isSystemAdmin: false,
    f_isUser: false,
    portal_isUser: false,
    portal_isAdmin: false,
    portal_isSupervisor: false,
    bitrix_id: null,
    authorized: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.phone = action.payload.phone;
            state.email = action.payload.email;
            state.bitrix_id = action.payload.bitrix_id;
            state.ref = action.payload.ref;
            state.capabilities = action.payload.capabilities;
            state.s_isAdmin = action.payload.capabilities.includes("s_admin") || false;
            state.isAdmin = action.payload.capabilities.includes("super_admin") || action.payload.capabilities.includes("portal_admin") || action.payload.capabilities.includes("portal_supervisor") || false;
            state.d_isManager = action.payload.capabilities.includes("d_manager") || false;
            state.d_isAdminManager = action.payload.capabilities.includes("d_admin_manager") || false;
            state.d_isUser = action.payload.capabilities.includes("d_user") || false;
            state.p_isAdmin = action.payload.capabilities?.includes("p_admin") || false;
            state.isSystemAdmin = action.payload.capabilities?.includes("system_admin") || false;
            state.p_isUser = action.payload.capabilities?.includes("p_user") || false;
            state.p_isSupervisor = action.payload.capabilities?.includes("p_supervisor") || false;
            state.p_isBrigadier = action.payload.capabilities?.includes("p_brigadier") || false;
            state.p_isCoordinator = action.payload.capabilities?.includes("p_coordinator") || false;
            state.portal_isUser = action.payload.capabilities?.includes("portal_user") || false;
            state.portal_isAdmin = action.payload.capabilities?.includes("portal_admin") || false;
            state.portal_isSupervisor = action.payload.capabilities?.includes("portal_supervisor") || false;

            state.authorized = true;
            console.log("SET USER", action.payload);
            localStorage.setItem("user", JSON.stringify((action.payload)))
            cookies.set('user', (action.payload), testing ? {} : { domain: '.vakio.ru' });
            cookies.set('user', (action.payload));

        },
        signIn(state, action) {

            localStorage.setItem("accessToken", action.payload.access_token);
            localStorage.setItem("refreshToken", action.payload.refresh_token);

            state.accessToken = action.payload.access_token;
            state.refreshToken = action.payload.refresh_token;
            cookies.set('accessToken', (action.payload.access_token), testing ? {} : { domain: '.vakio.ru' });
            cookies.set('refreshToken', (action.payload.refresh_token), testing ? {} : { domain: '.vakio.ru' });
            cookies.set('accessToken', (action.payload.access_token));
            cookies.set('refreshToken', (action.payload.refresh_token));

        },
        signOut(state) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            cookies.remove('accessToken', { domain: '.vakio.ru' });
            cookies.remove('accessToken');
            cookies.remove('user', { domain: '.vakio.ru' });
            cookies.remove('user');
            cookies.remove('refreshToken', { domain: '.vakio.ru' });
            cookies.remove('refreshToken');
            state.authorized = false;
        },
    },
})

export const { setUser, signIn, signOut } = userSlice.actions
export default userSlice.reducer