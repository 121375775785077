import React from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const DynamicValue = ({ style, value, valueModififed }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {value > 0 ?
                <CaretUpOutlined style={{ marginRight: 3, color: "#10cc10" }} />
                :
                value < 0 ?
                    <CaretDownOutlined style={{ marginRight: 3, color: "#ff8484" }} />
                    :
                    <></>
            }
            <div style={{
                color: value > 0 ? "#20cc20" : value < 0 ? "#ff8484" : undefined,
                whiteSpace: "nowrap"
            }}>
                {valueModififed}
            </div>
        </div>
    );
};

export default DynamicValue;