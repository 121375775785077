import React from 'react';
import { useSetPreset } from '../../../../entities/preset/models';
import DefaultSelect from '../../../../shared/ui/DefaultSelect';

const UpdateRouteSelect = ({ routes, preset }) => {
    const setPreset = useSetPreset(preset);

    const onChange = (value) => {
        const route = routes.find(route => route.key === value);
        setPreset(
            {
                col: preset.col,
                key_id: preset.key_id,
                row_id: preset.row_id,
                name: preset?.name,
                route: route,
                period: preset?.period
            })
    }
    return (

        <DefaultSelect

            options={routes ?? []}
            value={preset?.route?.key ?? null}
            label_key={"label"}
            value_key={"key"}
            onChange={onChange}
        />
    );
};

export default UpdateRouteSelect;