import { useSelector } from "react-redux";
import { selectUserId, selectUserIsAdmin } from "../model";
import { ReactNode } from "react";

interface IDashboardEditAccess {
  children: ReactNode;
  dashboard: TDashboard;
}

const DashboardEditAccess = ({ children, dashboard }: IDashboardEditAccess) => {
  const isAdmin = useSelector(selectUserIsAdmin);
  const userId = useSelector(selectUserId);

  if (isAdmin || userId == dashboard?.creator) return children;
  return <></>;
};

export default DashboardEditAccess;
