import { Select } from 'antd';
import React, { useEffect } from 'react';

const DefaultSelect = ({
    value,
    onChange,
    options,
    label_key,
    value_key,
    allowClear,
    mode,
    style,
    modifiedOptions
}) => {


    return (
        <Select
            mode={mode}
            style={style ? style : { width: "100%" }}
            allowClear={allowClear ?? true}
            value={value ?? undefined}
            onChange={onChange}

            options={modifiedOptions ?
                modifiedOptions :
                options?.map(option => {
                    return {
                        value: option[value_key],
                        label: option[label_key]
                    }
                })}
        />
    );
};

export default DefaultSelect;