import React from 'react';
import { useSetPreset } from '../../../../entities/preset/models';
import DefaultSelect from '../../../../shared/ui/DefaultSelect';

const UpdateSubrouteSelect = ({ subroutes, preset }) => {
    const setPreset = useSetPreset(preset);

    const onChange = (value) => {
        const subroute = subroutes.find(route => route.key === value);
        setPreset(
            {
                col: preset.col,
                key_id: preset.key_id,
                route: preset.route,
                name: preset?.name,
                row_id: preset.row_id,
                period: preset?.period,
                subroute: subroute,
            })
    }
    return (

        <DefaultSelect
            options={subroutes ?? []}
            value={preset?.subroute?.key}
            label_key={"label"}
            value_key={"key"}
            onChange={onChange}
        />
    );
};

export default UpdateSubrouteSelect;