import React, { useRef } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Input, message, Popconfirm, Select, Table, Typography } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import UserService from '../../../service/UserService';



const UserTable = ({ setEditUser, updatedIndex, reloadPage, rolesForSelect, roles }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [tableUsers, setTableUsers] = useState([]);
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState(null)
    const deleteUser = (id) => {
        UserService.deleteUser(id).then(response => {
            message.success("Пользователь успешно удалён")
            setTableUsers(tableUsers.filter(user => user.id !== id))
        }).catch(error => message.error("Не удалось удалить пользователя"))
    }
    let refTimeout = useRef();
    const getUsersWithTimeout = (userName, userRole) => {
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
        }
        setIsLoading(true);

        refTimeout.current = setTimeout(() => {
            UserService.getUsers(userName, userRole).then(response => {
                setTableUsers(response.data);
            }).finally(() => {
                setIsLoading(false);
            })
        }, 1500)

    }
    useEffect(() => {
        setIsLoading(true);
        UserService.getUsers().then(response => {
            setTableUsers(response.data);
        }).finally(() => {
            setIsLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPage]);
    const onChangeName = (event) => {
        const name = event.target.value
        setUserName(name)
        getUsersWithTimeout(name, userRole);

    }
    const onChangeRole = (role) => {
        setUserRole(role)
        getUsersWithTimeout(userName, role);

    }
    return (
        <div>
            <Card
                title={<div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0, marginRight: 20 }}>Пользователи</Typography.Title>
                    <Input
                        placeholder='Имя или почта пользователя'
                        style={{
                            maxWidth: 300,
                            marginRight: 10
                        }}
                        allowClear value={userName} onChange={onChangeName} />
                    <Select
                        showSearch
                        filterOption={(inputValue, option) => {
                            return option.label.includes(inputValue) || option.value.includes(inputValue);
                        }}
                        placeholder='Права пользователя'
                        style={{
                            width: "100%",
                            maxWidth: 300
                        }}
                        allowClear
                        onChange={onChangeRole}
                        value={userRole}
                        options={rolesForSelect}
                    />
                </div>}
                style={{ marginBottom: 20 }}
                extra={
                    <div>

                        <Button
                            type="default"
                            // shape="circle"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setEditUser({
                                    capabilities: "p_user",
                                    email: "",
                                    name: "",
                                    phone: "",
                                    title: "",
                                });
                            }}
                        >
                            {/* {window.screen.width > 450 && "Добавить пользователя"} */}
                            Добавить
                        </Button>
                    </div>
                }
                bordered={false}
                className="card"
            >
                <Table

                    pagination={{ position: ["topRight"] }}
                    rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
                    bordered={true}
                    loading={isLoading}
                    columns={[

                        {
                            title: "Имя",
                            dataIndex: "name",
                            key: "name",
                        },
                        {
                            title: "E-mail",
                            dataIndex: "email",
                            key: "email",
                        },
                        {
                            title: "Телефон",
                            dataIndex: "phone",
                            key: "phone",
                        },
                        {
                            title: "Права",
                            dataIndex: "capabilities",
                            key: "capabilities",
                            render: (cap) => {
                                return <div> {cap.split(",").map(role => roles[role]).join(", ")}</div>
                            }
                        },

                        {
                            title: "",
                            dataIndex: "action",
                            key: "action",
                            render: (_, full) => (
                                <Typography.Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditUser(full);
                                    }}
                                >
                                    <EditOutlined />
                                </Typography.Link>
                            ),
                        },
                        {
                            title: "",
                            dataIndex: "delete",
                            key: "delete",
                            render: (_, full) => (
                                <Popconfirm title="Удалить пользователя?"
                                    placement="topRight"
                                    onConfirm={() => deleteUser(full.id)} okText="Удалить" cancelText="Отмена">

                                    <Typography.Link
                                        onClick={(e) => {

                                            e.preventDefault();

                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Typography.Link>
                                </Popconfirm>
                            ),
                        },
                    ]}
                    dataSource={tableUsers ? tableUsers : []}
                    scroll={{ x: 300 }}
                    rowKey="id"
                />
            </Card>
        </div>
    );
};

export default UserTable;