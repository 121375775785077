import { useDispatch, useSelector } from "react-redux";
import { dashboardApi, dashboardModel } from "../../entities/dashboard";
import { uniqueId } from "../../shared/lib";
import { empty_rows } from "./config";
export const useAddRow = ({ setVisible }) => {

    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
    const dispatch = useDispatch();

    const addRowPreset = (selectedRow) => {
        const row_id = uniqueId()
        const createRow = {
            "row_id": row_id,
            "cols": empty_rows[selectedRow - 1].map((row, index) => {
                return { ...row, row_id: row_id, key_id: uniqueId(index) }
            })
        }
        dashboardApi.updateDashboard(dashboard.id, {
            ...dashboard, content: [...dashboard.content, createRow]
        }).then(() => {
            dispatch(dashboardModel.updateDashboards({ ...dashboard, content: [...dashboard.content, createRow] }))
            if (setVisible)
                setVisible(false);
        })


    }


    return addRowPreset;
}