import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dashboardModel } from "../../entities/dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SelectDashboard = () => {
  const dashboards = useSelector(dashboardModel.selectDashboards);
  const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const dashboard_id = new URLSearchParams(location.search).get(
      "dashboard_id"
    );
    const dashboard = dashboard_id
      ? dashboard_id
      : localStorage.getItem("dashboard_id");
    if (dashboard) {
      navigate({
        search: "?dashboard_id=" + dashboard,
      });
      dispatch(dashboardModel.setDashboard(Number(dashboard)));
    }
  }, []);
  const onChange = (value) => {
    localStorage.setItem("dashboard_id", value);
    navigate({
      search: "?dashboard_id=" + value,
    });

    dispatch(dashboardModel.setDashboard(value));
    dispatch(dashboardModel.setRerenderDashboard(true));
  };
  return (
    <div className="header__select">
      <Select
        placeholder="Выберите панель"
        className="header__select__input"
        size="large"
        style={{
          width: 300,
        }}
        value={dashboard?.id}
        onChange={onChange}
      >
        {dashboards.map((item) => {
          return (
            <Select.Option value={item.id} key={item.id}>
              {`${item.title} ${item.is_public ? "" : ""}`}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectDashboard;
