import React from 'react';

const PlanValue = ({ style, value, valueModififed, planModificator }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{
                color: planModificator?.inverseColors ?
                    value > 100 ? "#ff8484" : "#20cc20" :
                    value >= 100 ? "#20cc20" : "#ff8484",
                whiteSpace: "nowrap"
            }}>
                {valueModififed}
            </div>
        </div>
    );
};

export default PlanValue;