import { Modal, Typography } from 'antd';
import React, { useEffect } from 'react';
import { dashboardModel } from '../../../entities/dashboard';
import { useLoadRoutes } from '../model';
import FormItem from './FormItem';
import InputField from './InputField/InputField';
import UpdateSubrouteSelect from './PreparedFields/UpdateSubrouteSelect';
import SelectTypeGraph from './PreparedFields/SelectTypeGraph';
import UpdateRouteSelect from './PreparedFields/UpdateRouteSelect';
import FiltersSelect from './PreparedFields/FiltersSelect';
import FiltersItem from './InputField/FiltersItem';
import SelectOutValues from './PreparedFields/SelectOutValues';
import InputName from './PreparedFields/InputName';
import { useSavePreset } from '../../../entities/preset/models';
import { useSelector } from 'react-redux';

const ModifyPressetModal = ({ visible, setVisible }) => {
    const preset = useSelector(dashboardModel.selectTargetPreset);

    const [routes, subroutes, loadingRoutes] = useLoadRoutes()
    const closeModal = () => {
        setVisible(false)
    }

    const savePreset = useSavePreset(closeModal);

    useEffect(() => {
        if (!visible) {
            dashboardModel.setTargetPreset({})
        }
    }, [visible])

    const onOk = () => {
        savePreset(preset);
    }
    return (
        <Modal
            title={
                <Typography.Title level={4}>{"Редактирование"}</Typography.Title>
            }
            centered
            open={visible}
            okText={"Сохранить"}
            onCancel={closeModal}
            closable={false}
            destroyOnClose
            onOk={onOk}
            forceRender
            style={{ marginTop: 20, marginBottom: 20 }}
            width={window.screen.width < 450 ? "100%" : 500}
            zIndex={1002}

            styles={{ body: { paddingBottom: 16 } }}
        >
            <InputName preset={preset} />
            <FormItem labels={["Область", "Подобласть"]}>
                <UpdateRouteSelect routes={routes} preset={preset} />
                <UpdateSubrouteSelect subroutes={preset?.route?.subroutes?.filter(item => item.show_route)} preset={preset} />
            </FormItem>
            <FormItem labels={"Фильтры"}>
                <FiltersSelect routes={routes} preset={preset} />
            </FormItem>
            <FiltersItem preset={preset} />

            {preset?.subroute &&
                <>
                    <FormItem labels={"Формат вывода"}>
                        <SelectTypeGraph preset={preset} />
                    </FormItem>
                    <FormItem labels={"Выходные значения"}>
                        <SelectOutValues preset={preset} error={false} />
                    </FormItem>
                </>
            }
            {preset?.subroute?.input_fields?.map((input_field, index) => {
                if (input_field.blacklist && input_field.blacklist?.includes(preset?.type)) {
                    return <div key={index}></div>
                }
                return <InputField key={index} field={input_field} preset={preset} />
            })
            }

        </Modal>

    );
};

export { ModifyPressetModal };