import { Radio } from 'antd';
import React from 'react';

const RadioButtonInput = ({ updateValue, value, options }) => {

    const onChange = (event) => {
        const value = event.target.value;
        updateValue(value)
    }
    return (
        <Radio.Group value={value} buttonStyle="solid" style={{ marginRight: 10 }} onChange={onChange}>
            {options?.map(key => {
                return <Radio.Button key={key.value} value={key.value}>{key.label}</Radio.Button>
            })}
        </Radio.Group>
    );
};

export default RadioButtonInput;