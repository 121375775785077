import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    routes: [],
    subroutes: [],
    fields: []

}

const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        setRoutes: (state, action) => {
            state.routes = action.payload;
        },
        setSubRoutes: (state, action) => {
            state.subroutes = action.payload;
        },
        setFields: (state, action) => {
            state.fields = action.payload;
        },
        setFieldValue: (state, action) => {
            const index = state.fields.findIndex(field => field.id === action.payload.id);

            state.fields[index].value = action.payload.value
        },
        setFieldComparison: (state, action) => {
            const index = state.fields.findIndex(field => field.id === action.payload.id);

            state.fields[index].comparison = action.payload.comparison
        },
        setFieldInput: (state, action) => {
            const index = state.fields.findIndex(field => field.id === action.payload.id);
            state.fields[index].input = action.payload.value;
            if (state.fields[index].field_type === "select" || state.fields[index].field_type === "search_select") {
                state.fields[index].labeledInput = action.payload.options;
            }
            if (state.fields[index].field_type === "city") {
                state.fields[index].comparison = "in";
            }
        }
    },
})

export const { setRoutes, setSubRoutes, setFields, setFieldValue, setFieldComparison, setFieldInput } = routesSlice.actions
export default routesSlice.reducer