import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/Navigation/AppRouter";

import dayjs from "dayjs";
import 'dayjs/locale/ru';
dayjs.locale('ru');
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
}
function App() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
