import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import autoAnimate from "@formkit/auto-animate";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { memo, useEffect, useRef, useState } from "react";
import "./Value.scss";
const ItemValue = ({ value, options }) => {
  const parentRef = useRef(null);
  const [parent] = useAutoAnimate({
    duration: 250,
    easing: "linear",
    // When true, this will enable animations even if the user has indicated
    // they don’t want them via prefers-reduced-motion.
    disrespectUserMotionPreference: true,
  });

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  return (
    <div style={{ marginTop: 9, width: "100%" }}>
      <div className="values__value" key={value.label} ref={parent}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="values__value__number">
            {Number(value.value)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .replace(/\.00$/, "")}
          </div>
          {value?.old && (
            <div
              className="values__value__old"
              style={
                value.grow
                  ? { borderColor: "#10cc10", color: "#10cc10" }
                  : { borderColor: "ff8484", color: "ff8484" }
              }
            >
              {value.grow ? (
                <CaretUpOutlined style={{ marginRight: 3 }} />
              ) : (
                <CaretDownOutlined style={{ marginRight: 3 }} />
              )}
              {value?.old} %
            </div>
          )}
        </div>
        {options.additionalInfo && (
          <div className="values__value__label">{value.label}</div>
        )}
      </div>{" "}
    </div>
  );
};
const ItemValueMemo = memo(ItemValue);
const Value = ({ data, keys = [], options, delta }) => {
  const [dataList, setDataList] = useState([]);
  const parentRef = useRef();

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  useEffect(() => {
    if (delta === "none" || delta == undefined) {
      setDataList(
        keys.map((key) => {
          let oldValue = data[key + " old"];
          if (oldValue) {
            oldValue = -(((oldValue - data[key]) * 100) / oldValue);
          }
          return { value: data?.[0][key], label: key };
        }) || []
      );
    } else {
      setDataList(
        keys.map((key) => {
          let oldValue = data[key + " old"];
          if (oldValue) {
            oldValue = -(((oldValue - data[key]) * 100) / oldValue);
          }

          return {
            value: data[key],
            label: key,
            old: Math.abs(oldValue?.toFixed(2)),
            grow: oldValue >= 0 ? true : false,
          };
        }) || []
      );
    }
  }, [data]);
  return (
    <>
      {dataList.length > 0 && (
        <>
          {dataList.length > 1 ? (
            <div className="values" ref={parentRef}>
              {dataList &&
                dataList.map((value, index) => (
                  <ItemValueMemo key={index} value={value} options={options} />
                ))}
            </div>
          ) : (
            <div className="once_value" ref={parentRef}>
              <div className="once_value__value" key={dataList[0].label}>
                <div className="once_value__value__number">
                  {Number(dataList[0].value)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .replace(/\.00$/, "")}
                </div>

                {options.additionalInfo && (
                  <div className="once_value__value__label">
                    {dataList[0].label}
                  </div>
                )}
                {dataList[0]?.old !== null &&
                  delta !== "none" &&
                  delta !== undefined && (
                    <div
                      className="once_value__value__old"
                      style={
                        dataList[0].grow
                          ? { borderColor: "#10cc10", color: "#10cc10" }
                          : { borderColor: "ff8484", color: "ff8484" }
                      }
                    >
                      {dataList[0].grow ? (
                        <CaretUpOutlined style={{ marginRight: 3 }} />
                      ) : (
                        <CaretDownOutlined style={{ marginRight: 3 }} />
                      )}
                      {dataList[0]?.old} %
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Value;
