import { Form } from "antd";
import { useEffect } from "react";

interface IUseEditManager {
  onSave: (manager: TManager) => void;
  isEditing: boolean;
  manager: TManager;
}

export const useEditManager = ({
  onSave,
  manager,
  isEditing,
}: IUseEditManager) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue(manager);
    } else {
      form.resetFields();
    }
  }, [isEditing]);
  const onSubmit = () => {
    form
      .validateFields()
      .then((values: TManager) => {
        onSave(values);
      })
      .catch(() => {});
  };
  return { form, onSubmit };
};
