import { $api } from "../../../service/http";

export function getDashboards() {

    return $api.get("/api/dashboard_preset");
}

export function createDashboard(dashboard) {
    return $api.post("/api/dashboard_preset", dashboard);
}

export function updateDashboard(id, dashboard) {
    return $api.put("/api/dashboard_preset/" + id, dashboard);
}
export function deleteDashboard(id) {
    return $api.delete("/api/dashboard_preset/" + id);
}
