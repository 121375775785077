import { Select, Table } from "antd";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import TableRenderDeltaItem from "../../Dashboard/components/TableRenderItem/TableRenderDeltaItem";
import "./TableChart.scss";

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 5px;
  }
`;
const TableChart = ({
  data,
  delta,
  columns,
  id_key,
  execute,
  expandable,
  selection,
  selectionState,
  selectionValues,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [deltaKeys, setDeltaKeys] = useState("");
  const [rawColumns, setRawColumns] = useState([]);
  const getDateFormatKey = (value) => {
    if (execute === "executeInMonths") return moment(value).format("MMMM YY");
    else if (execute === "executeInYears") return value + " год";
    else if (execute === "executeInQuarters")
      return value?.[5] + " квартал " + value.slice(0, 4);
  };

  // 2022-01-01 - 2022-12-16
  const dynamicTitle = (title) => {
    try {
      const splited = title.split(" - ");
      const deltaTitle_ =
        moment(splited[0], "YYYY-MM-DD").format("DD MMMM YYYY") +
        " - " +
        moment(splited[1], "YYYY-MM-DD").format("DD MMMM YYYY");
      if ("Invalid" in deltaTitle_) {
        return title;
      }
      return deltaTitle_;
    } catch {
      return title;
    }
  };

  const getStyle = (column, value, full) => {
    if (selection || selectionState) {
      const field = column.key;
      const targetSelection = selection.find(
        (item) => item?.field?.value === field
      );
      if (targetSelection) {
        let comparable_value;
        if (selectionValues) {
          const selectionField = selectionValues.find(
            (item) => item.show_value === field
          ).desired_value;
          if (selectionField && full?.[selectionField]) {
            comparable_value = full?.[selectionField];
          } else {
            comparable_value = value;
          }
        } else {
          comparable_value = value;
        }
        const limitValue = targetSelection?.value;
        if (limitValue >= comparable_value) {
          return { color: targetSelection.color_less };
        } else if (limitValue < comparable_value) {
          return { color: targetSelection.color_more };
        }
        // } else {
        //     return { color: targetSelection.color_equal }
        // }
      }
    }
    return null;
  };
  const getColumns = (columns) => {
    if (
      (delta === "none" || delta == undefined) &&
      !columns.find((item) => item.children != null)
    )
      return columns.map((column) => {
        return {
          ...column,
          render: (value, full) => {
            if (isNaN(value)) {
              return <div style={getStyle(column, value, full)}>{value}</div>;
            } else {
              return (
                <div style={getStyle(column, value, full)}>
                  {value
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .replace(/\.00$/, "")}
                </div>
              );
            }
          },
        };
      });
    else {
      return columns.map((column) => {
        return {
          title: dynamicTitle(column.title),
          children: column.children.map((value, index) => {
            return {
              ...value,
              render: (value, full) => {
                if (isNaN(value)) {
                  return (
                    <div style={getStyle(column.children[index], value, full)}>
                      {value}
                    </div>
                  );
                } else {
                  return (
                    <div style={getStyle(column.children[index], value, full)}>
                      {isNaN(value)
                        ? value
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/\.00$/, "")
                        : value}
                    </div>
                  );
                }
              },
            };
          }),
        };
      });
    }
  };
  useEffect(() => {
    if (delta !== "none" && delta !== undefined) getDeltaColumns(columns);
  }, []);
  const getDeltaColumns = (columns) => {
    let deltaKeys = [];
    columns[1].children.forEach((item, index) => {
      deltaKeys.push({
        new: item.dataIndex,
        old: columns[0].children[index].dataIndex,
        title: item.title,
      });
    });
    setDeltaKeys(deltaKeys);
    setRawColumns(
      deltaKeys.map((item) => {
        return {
          key: item.new.split("_")[1],
          dataIndex: item.new.split("_")[1],
          title: item.title + "\u00A0" + "\u0394",
          render: (_, full) => <TableRenderDeltaItem item={item} full={full} />,
        };
      })
    );
  };

  const selectComponentClass = (props) => {
    return (
      <div
        style={{ display: "flex" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Select {...props} size="small" />
      </div>
    );
  };
  selectComponentClass.Option = (props) => {
    return (
      <div
        style={{
          margin: 5,
          padding: 5,
          border: "1px solid black",
          borderRadius: 3,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(props.value);
        }}
      >
        {props.value}
      </div>
    );
  };

  const itemRender = (page, type, element) => {
    return (
      <div
        onClick={(e) => {
          setCurrentPage(page);
          e.stopPropagation();
        }}
      >
        {element}
      </div>
    );
  };
  return (
    <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
      {columns !== undefined && (
        <StyledTable
          expandable={expandable}
          pagination={{
            hideOnSinglePage: true,
            position: ["topRight", "bottomRight"],
            selectComponentClass,
            current: currentPage,
            defaultPageSize: 100,
            itemRender,
            size: "small",
          }}
          bordered={true}
          columns={
            [
              {
                key: "id",
                dataIndex: "id",
                title: "Имя",

                render: (value) => {
                  if (id_key === "date") {
                    return getDateFormatKey(value);
                  } else {
                    return <div>{value}</div>;
                  }
                },
              },
              ...getColumns(columns),
              ...rawColumns,
            ] || []
          }
          dataSource={data || []}
          rowKey={(record, index) => index}
        />
      )}
    </div>
  );
};

export default memo(TableChart);
