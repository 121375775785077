import { memo } from "react";
import { PieChart } from "../../../entities/preset";
import BarChart from "../../../entities/preset/ui/BarChart";
import LineChart from "../../../entities/preset/ui/LineChart";
import SumChart from "../../../entities/preset/ui/SumChart";
import TableGroupChart from "./TableGroupChart";

const GraphByType = ({
    preset,
    data,
    type,
    modificators,
    modal,
    noTableMargin,
    routes,
    updatePreset,
    loading,
}) => {
    switch (type) {
        case "table":
            return (
                <TableGroupChart
                    preset={preset}
                    data={data}
                    type={type}
                    modificators={modificators}
                    modal={modal}
                    noTableMargin={noTableMargin}
                    routes={routes}
                    updatePreset={updatePreset}
                    loading={loading}
                />
            );
        case "bar":
            return (
                <BarChart
                    data={data?.data ?? []}
                    indexBy={data?.indexBy}
                    outValues={data?.out_values}
                    modificators={modificators}
                    selectionData={preset?.selectionData}
                />
            );
        case "pie":
            return (
                <PieChart
                    data={data?.data ?? []}
                    outValue={data?.out_value}
                    modificators={modificators}
                    selectionData={preset?.selectionData}
                />
            );
        case "linear":
            return (
                <LineChart
                    data={data ?? []}
                    modificators={modificators}
                    selectionData={preset?.selectionData}
                />
            );
        case "sum":
            return (
                <SumChart
                    data={data.data ?? {}}
                    modificators={modificators}
                    labels={data?.out_value_labels}
                    selectionData={preset?.selectionData}
                />
            );

        default:
            return <div>-</div>;
    }
};

export default memo(GraphByType);
