import { Input } from 'antd';
import React from 'react';

const InputInput = ({ value, updateValue, disabled }) => {
    const onChange = (event) => {
        const value = event.target.value;
        updateValue(value);
    }
    return (
        <Input value={value} disabled={disabled} onChange={onChange} />
    );
};

export default InputInput;