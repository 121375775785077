import { Form, Input, Modal } from "antd";
import { useEditManager } from "../model";
import { SelectDepartments } from "@features/SelectDepartments";

interface IEditManagerModal {
  onCloseEditing: () => void;
  onSave: (manager: TManager) => void;
  isEditing: boolean;
  manager: TManager;
  confirmLoading?: boolean;
}

const EditManagerModal = (props: IEditManagerModal) => {
  const { form, onSubmit } = useEditManager(props);

  return (
    <Modal
      onCancel={props.onCloseEditing}
      open={props.isEditing}
      onOk={onSubmit}
      confirmLoading={props.confirmLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Имя" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Ref" name="ref">
          <Input disabled />
        </Form.Item>
        <Form.Item shouldUpdate={true} label="Департаменты" name="departments">
          <SelectDepartments />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditManagerModal;
