import { Col, Divider, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { empty_rows } from '../config';
import { useAddRow } from '../lib';

import "./RowModal.scss"



const StyledModal = styled(Modal)`
.ant-modal-header{
    border: none
}
`
const AddRowModal = ({ visible, setVisible }) => {
    const [mouseEnter, setMouseEnter] = useState(0)
    const [selectedRow, setSelectedRow] = useState(1)
    const addRowPreset = useAddRow({ setVisible });

    const onOk = () => {
        addRowPreset(selectedRow)
    }
    return (
        <StyledModal
            title={
                <Typography.Title level={4}>{"Выберите строку"}</Typography.Title>
            }
            centered
            open={visible}
            onOk={onOk}
            okText={"Добавить"}
            onCancel={() => setVisible(false)}
            closable={true}
            destroyOnClose
            forceRender

            className='modal'
            width={window.screen.width < 450 ? "100%" : 450}
            styles={{ body: { paddingBottom: 16 } }}
        >

            {empty_rows.map((row, index) => {
                return <div key={index}><Row

                    onMouseLeave={() => setMouseEnter(0)}
                    onMouseEnter={() => setMouseEnter(index + 1)}
                    className={`modal__row ${selectedRow === index + 1 ? "row_active" : ""}`}
                    gutter={16}
                    onClick={() => setSelectedRow(index + 1)}
                >
                    {row.map((col, coldIndex) => {
                        return <Col span={col.col} key={coldIndex}>
                            <div className={`modal__col  ${mouseEnter === index + 1 ? "hover" : ""}  ${selectedRow === index + 1 ? "active" : ""}`}> </div>
                        </Col>
                    })}

                </Row>
                    <Divider style={{ margin: "15px 0" }} className={"modal__divider"} />
                </div>
            })}

        </StyledModal >
    );
};

export default AddRowModal;