import { modifyValueToFormat } from "./ParseModificators";

export const getLegend = (value) => {
    if (true) {

        try {

            const splited = value?.split(" ")
            if (splited[0] === "Прибор" && splited[1]?.toLowerCase() === "vakio") {
                return splited[2] + `${splited.length > 3 ? ` ${splited[3]}` : ""}`
            } else if (splited[0] === "ООО" || splited[0].length < 4) {
                return value;
            } else {
                return value;
            }
        } catch {
            return value;
        }
    }
    return value + " год"
}