import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCopyDashboard } from "../model";
import EditDashboardModal from "./EditDashboardModal";

interface ICopyDashboardButton {

}

const CopyDashboardButton = ({

}: ICopyDashboardButton) => {

    const { onSubmit,
        visible,
        copiedDashboard,
        loading,
        onOpenModal,
        onCancel } = useCopyDashboard();

    return (
        <>
            <Button
                icon={<CopyOutlined />}
                style={{ marginLeft: 10 }}
                type="link"
                loading={loading}
                size="large"
                onClick={onOpenModal}
            />
            <EditDashboardModal
                omSubmit={onSubmit}
                dashboard={copiedDashboard}
                onCancel={onCancel}
                visible={visible}
            />
        </>
    );
};

export default CopyDashboardButton;