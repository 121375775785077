export const getOrderedColumns = (
    columns: TGroupChartColumn[],
    orderParams: TOrderParam
): TGroupChartColumn[] => {
    const columnsWithOrder = columns.map((column, index, arr) => {
        return {
            ...column,
            order: orderParams[column["key"]] ?? column.order,
        };
    });
    const orderedColumns = columnsWithOrder.toSorted((a, b) => {
        return (a.order ?? 100) - (b.order ?? 100);
    });

    const columnsWithDefaultOrder = orderedColumns.map((column, index, arr) => {
        const isHave = orderedColumns.some(
            (item) => item.key !== column.key && item.order === column.order
        );
        const newOrder = column.order === undefined || isHave ? index : column.order;
        return {
            ...column,
            order: newOrder,
        };
    });
    return columnsWithDefaultOrder;
};
