import React from 'react';
import CheckboxInput from '../inputs/CheckboxInput';
import RadioButtonInput from '../inputs/RadioButtonInput';

const ExtraField = ({ updatePreset, extra_field, preset }) => {


    const updateValue = (value) => {
        console.log();
        updatePreset([[extra_field.key, value]])
    }

    const getField = () => {
        switch (extra_field.type) {
            case "checkbox":
                return <CheckboxInput updateValue={updateValue} value={preset?.[extra_field.key]} extra_field={extra_field} />
            case "radio":
                return <RadioButtonInput updateValue={updateValue} value={preset[extra_field.key] ?? extra_field?.default_value} extra_field={extra_field} options={extra_field.options} />
            default:
                return <div>{extra_field.field}</div>
        }
    }


    return (
        <>
            {getField()}
        </>
    );
};

export default ExtraField;