import React from 'react';
import DynamicValue from './DynamicValue';
import PlanValue from './PlanValue';
import "./SumChart.scss"
import { getModifiedStyle, modifyValueToFormat } from "../lib/ParseModificators"


const Value = ({ value, modificators, labels, valueKey, selectionData }) => {

    let valueModififed = value;
    const modificator = modificators[valueKey]
    if (modificator) {
        valueModififed = `${modificator?.pre ?? ""}${modifyValueToFormat(valueModififed, modificator?.format)}${modificator?.after ?? ""}`
    }
    return < >
        <div className='values__value__label'>
            {labels[valueKey]}
        </div>
        <div className='values__value__number'>
            <>
                {modificator && modificator?.type === "dynamic" ?
                    <DynamicValue style={getModifiedStyle(value, valueKey, selectionData)} value={value} valueModififed={valueModififed} />
                    :
                    modificator?.type === "plan" ?
                        <PlanValue style={getModifiedStyle(value, valueKey, selectionData)} value={value} valueModififed={valueModififed} />
                        :
                        <div style={{ ...getModifiedStyle(value, valueKey, selectionData), whiteSpace: "nowrap" }}>{valueModififed}</div>
                }
            </>
        </div>
    </>
}
const OnceValue = ({ value, modificators, labels, valueKey, selectionData }) => {

    let valueModififed = value;
    const modificator = modificators[valueKey]
    if (modificator) {
        valueModififed = `${modificator?.pre ?? ""}${modifyValueToFormat(valueModififed, modificator?.format)}${modificator?.after ?? ""}`
    }
    return <div style={{
        display: "flex",
        width: "100%",
        height: "80%",
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: "column"
    }}>

        <div>

            <div className='once_value__value__number'>
                {modificator && modificator?.type === "dynamic" ?
                    <DynamicValue style={getModifiedStyle(value, valueKey, selectionData)} value={value} valueModififed={valueModififed} />
                    :
                    modificator?.type === "plan" ?
                        <PlanValue style={getModifiedStyle(value, valueKey, selectionData)} value={value} valueModififed={valueModififed} />
                        :
                        <div style={{ ...getModifiedStyle(value, valueKey, selectionData), whiteSpace: "nowrap" }}>{valueModififed}</div>
                }
            </div>
        </div>
        <div className='once_value__value__label'>
            {labels[valueKey]}
        </div>

    </div>
}


const SumChart = ({ data, labels, modificators, selectionData }) => {
    return (
        <>
            {Object.keys(data)?.length > 0 && <div style={{
                width: "100%",
                height: "100%"
            }}>
                {Object.keys(data)?.length > 1 ?
                    <div className='values'>
                        {
                            Object.keys(data)?.map(key => {
                                return <Value valueKey={key} value={data[key]} selectionData={selectionData} modificators={modificators} labels={labels} key={key} />
                            })}
                    </div>
                    :
                    <>
                        <OnceValue valueKey={Object.keys(data)[0]} value={data[Object.keys(data)[0]]} selectionData={selectionData} modificators={modificators} labels={labels} />
                    </>
                }
            </div>}
        </>
    );
};

export default SumChart;