export const getDashboardMap = (dashboard) => {


    let dashboard_ = [...dashboard];
    let maxLength = 0;
    dashboard_.push([])
    // Добавляем empty к каждой строке
    dashboard_.map(item => {
        if (item.length < 4)
            item.push({ "label": "empty" });
        return item
    })

    // Определяем максимальную длину
    dashboard_.forEach(item => {
        if (item[item.length - 1].label !== "empty") {
            if (maxLength < item.length) {
                maxLength = item.length;
            }
        } else {
            if (maxLength < item.length - 1) {
                maxLength = item.length;
            }
        }

    })
    // дополняем все строки до максимальной длины
    dashboard_.map(item => {
        for (let count = item.length; count < maxLength; count++) {
            item.push(null);
        }
        return item
    })


    // dashboard.push([])
    dashboard_ = dashboard_.map((item, index, array) => {
        const itemLength = item.length
        const lastItem = index === 0 ? null : array[index - 1]
        const nextItem = index === array.length - 1 ? null : array[index + 1]
        return item.map((item, index_) => {

            if (lastItem !== null) {

                if (lastItem[index_]?.label !== undefined) {
                    if (lastItem[index_].label !== "empty") {
                        if (item === null) {
                            item = { "label": "empty" }
                        }
                    }
                }
            }
            if (nextItem !== null) {
                if (nextItem[index_]?.label !== undefined) {
                    if (nextItem[index_].label !== "empty") {
                        if (item === null) {
                            item = { "label": "empty" }
                        }
                    }
                }
            }

            return item;
        })


    })
    return dashboard_
}

export const colorsPalette = ["#80D16B", "#4CD0D9", "#9C2765", "#E71044", "#D6F1F2", "#81A9B0", "#F6F3EB", "#B0C4D0", "#47677E", "#59984F"]