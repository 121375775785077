import { Collapse } from "antd";
import PresetModalButtons from "../../../../components/PresetModalButtons/PresetModalButtons";
import GroupChart from "../../../entities/preset/ui/GroupChart";
import { TableChart } from "../../../entities/preset/ui/TableChart";
import FormItem from "../../ModifyPressetModal/ui/FormItem";
import FiltersItem from "../../ModifyPressetModal/ui/InputField/FiltersItem";
import FiltersSelect from "../../ModifyPressetModal/ui/PreparedFields/FiltersSelect";
import ExpandedRow from "./ExpandedRow";
import { useState } from "react";
import { useTableColumnsList } from "@entities/preset/UseTableColumnsList";

const TableGroupChart = ({
    preset,
    data,
    type,
    modificators,
    modal,
    noTableMargin,
    routes,
    updatePreset,
    loading,
}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [targetExpanded, setTargetExpanded] = useState(0);
    const [period, setPeriod] = useState(preset?.period);

    const { orderedColumns, orderParamsLoading } = useTableColumnsList(
        preset,
        (1 === preset?.grouped?.length && false) || preset?.grouped?.length < 2,
        data?.columns,
        loading
    );

    if (preset?.grouped?.[0])
        return (
            <>
                {modal && (
                    <>
                        <Collapse size="small" style={{ marginBottom: 10 }}>
                            <Collapse.Panel header="Фильтры" key="1">
                                <FormItem style={{ width: 500 }}>
                                    <FiltersSelect
                                        routes={routes}
                                        preset={preset}
                                        updatePresetParent={updatePreset}
                                    />
                                </FormItem>
                                <FiltersItem
                                    preset={preset}
                                    updatePresetParent={(value) => {
                                        updatePreset(value);
                                    }}
                                    itemsBoxStyle={{
                                        minWidth: 400,
                                        marginRight: 20,
                                    }}
                                    boxStyle={{
                                        display: "flex",
                                    }}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </>
                )}
                <GroupChart
                    noTableMargin={noTableMargin}
                    modal={modal}
                    routes={routes}
                    loading={loading}
                    onRowClick={data?.link}
                    data={data?.dataSource ?? []}
                    layer={1}
                    orderParamsLoading={orderParamsLoading}
                    preset={preset}
                    columns={orderedColumns}
                    modificators={modificators}
                    selectionData={preset?.selectionData}
                    groups={preset?.grouped}
                    scroll
                />
            </>
        );
    return (
        <>
            {modal && (
                <PresetModalButtons
                    setPeriod={setPeriod}
                    period={period}
                    preset={preset}
                    expandedRows={expandedRows}
                    setExpandedActionId={setTargetExpanded}
                    expandedActionId={targetExpanded}
                />
            )}

            <TableChart
                scroll
                loading={loading}
                rowKey={"id"}
                onRowClick={data?.link}
                modificators={modificators}
                columns={data?.columns}
                dataSource={data?.dataSource}
                selectionData={preset?.selectionData}
                expandable={
                    !preset.subroute?.expanded_row || preset.subroute?.expanded_row === ""
                        ? undefined
                        : {
                              expandRowByClick: modal,

                              expandedRowRender: (record, index, indent, expanded) => (
                                  <ExpandedRow
                                      index={index}
                                      period={period}
                                      setPeriod={setPeriod}
                                      setExpandedRows={setExpandedRows}
                                      targetExpanded={targetExpanded}
                                      expanded={expanded}
                                      row={record}
                                      preset={preset}
                                  />
                              ),
                              rowExpandable: (record) =>
                                  record.id !== "Остальные" && record.id !== "Итого",
                          }
                }
            />
        </>
    );
};

export default TableGroupChart;
