import React from 'react';
import { getColWidth } from '../../../shared/lib';
import "./RowModal.scss"

export const AddRow = ({ openAddRowModal }) => {
    return (
        <ul style={{ marginLeft: 10 }}>
            <li className='row' span={6} onClick={openAddRowModal} style={{ width: `${getColWidth(6)}%` }}>
                <div className='row__col__new' style={{ height: (window.innerHeight - 100) / 3 }}  >

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                        <div style={{ color: "#7d7d7d" }}>Добавить строку</div>
                    </div>
                </div>
            </li>
        </ul>
    );
};

