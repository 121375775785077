import { Select } from 'antd';
import React from 'react';
import { useUpdatePreset } from '../../../../entities/preset/models';

const SelectOutValues = ({
    error,
    preset,
    updatePresetValues
}) => {
    const updatePreset = useUpdatePreset(preset);

    const onChange = (values) => {
        if (updatePresetValues) {
            updatePresetValues([["outValues", values]])
        }
        updatePreset([["outValues", values]])
    }
    return (
        <Select status={error ? "error" : undefined} style={{ width: "100%" }} value={preset?.outValues} onChange={onChange} options={preset?.subroute?.out_values} allowClear mode={preset?.type !== "pie" && preset?.type !== "linear" ? "multiple" : undefined} />

    );
};

export default SelectOutValues;