import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import autoAnimate from '@formkit/auto-animate';
import { Button, message, Popconfirm, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QuestionService from '../../../service/QuestionService';
import { setHeaderTitle } from '../../../store/layoutSlice';
import RubricModal from '../Modals/RubricModal';
import "./Chapter.scss";

const Chapter = () => {
    const [rubrics, setRubrics] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [visible, setVisible] = useState(false)
    const [targetRubric, setTargetRubric] = useState({})
    const user = useSelector(state => state.user)
    const parentRef = useRef();

    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);
    useEffect(() => {
        dispatch(setHeaderTitle(""));

        QuestionService.getArticleChapter(params.id).then((response) => {
            dispatch(setHeaderTitle(response.data.chapter_title))
        });

        QuestionService.getArticleRubrics().then((reponse) => {
            console.log("rubrics", reponse.data);
            setRubrics(reponse.data.filter(rubric => rubric.article_chapter_id == Number(params.id)));
        });
    }, [])

    const routeToRubric = (rubric) => {
        navigate("/portal-questions/rubric/" + rubric.id)
    }
    const deleteRubric = (rubric) => {

        QuestionService.deleteArticleRubric(rubric.id).then(() => {
            setRubrics(rubrics.filter(rubric_ => rubric_.id !== rubric.id));
            message.success("Рубрика успешно удалёна!");
        }).catch(() => {
            message.error("Не удалось удалить рубрику!");
        })
    }

    return (
        <>
            <Button
                onClick={() => {
                    setTargetRubric({
                        rubric_title: "",
                        rubric_content: "",
                        article_chapter_id: params.id,
                        create: true
                    })
                    setVisible(true);
                }}
                style={{ marginTop: 10, marginBottom: 15, marginLeft: 15 }}>
                Создать рубрику
            </Button>
            <div parent={parentRef}>
                {
                    rubrics && rubrics.map(rubric => {
                        return <div className='rubrics' key={rubric.id} onClick={() => routeToRubric(rubric)} parent={parentRef}>
                            <div>
                                <Typography.Title level={4}>
                                    {rubric.rubric_title}
                                </Typography.Title>
                                <Typography.Text level={4}>
                                    {rubric.rubric_content}
                                </Typography.Text>
                            </div>

                            {(user.isAdmin || user.s_isAdmin) &&
                                <div className='rubrics__actions' onClick={e => e.stopPropagation()}>
                                    <Button shape="circle" icon={<EditOutlined />} style={{ marginRight: 10 }} size={20} onClick={() => {
                                        setTargetRubric(rubric);
                                        setVisible(true);
                                    }} />
                                    <Popconfirm
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title="Удалить рубрику?"
                                        onConfirm={() => deleteRubric(rubric)}
                                        okText="Удалить"
                                        cancelText="Отмена"
                                    >
                                        <Button shape="circle" icon={<DeleteOutlined />} size={20} />
                                    </Popconfirm>
                                </div>}
                        </div>
                    })
                }
            </div>
            <RubricModal visible={visible} setVisible={setVisible} targetRubric={targetRubric} rubrics={rubrics} setRubrics={setRubrics} />

        </>
    );
};

export default Chapter;