import axios from "axios";

export const client_id = "95fccb78-346a-433d-ad3d-597a38554111";
export const client_secret = "nrObsW7Ou7ibIQYeILwoT5NbcR0e9KJw6yEgdtRK";
export const grant_type = "password";

export const api_url = "https://projects.vakio.ru";
//export const analytic_url = "https://portal.vakio.ru";

export const analytic_url =
    process.env.NODE_ENV === "production"
        ? "https://portal.vakio.ru"
        : "http://vakio-portal.loc";
export const dealers_url = "https://dealers.vakio.ru/api";
export const api_url_auth = "https://auth.vakio.ru";

export const $api = axios.create({
    //withCredentials: true,
    baseURL: analytic_url,
});
export const $api_files = axios.create({
    //withCredentials: true,
    baseURL: api_url,
});
export const $api_auth = axios.create({
    //withCredentials: true,
    baseURL: api_url_auth,
});
export const $api_dealers = axios.create({
    // withCredentials: true,

    baseURL: dealers_url,
});

export const requestApi = async (uri, parameters) => {
    console.log("API");
    const token = await localStorage.getItem("accessToken");
    parameters.headers.Authorization = "Bearer " + token;
    const response = await fetch(dealers_url + uri, { ...parameters });
    if (!response.ok) {
        throw response;
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        return { data: json, status: response.status };
    }

    return { status: response.status };
};

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

    return config;
});
$api_files.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

    return config;
});
$api_dealers.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

    return config;
});
$api_auth.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

    return config;
});

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        return;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response = await axios.get(`${api_url_auth}/user/refresh`, {
                    withCredentials: true,
                });
                localStorage.setItem("accessToken", response.data.accessToken);
                return $api.request(originalRequest);
            } catch (e) {}
        }
        throw error;
    }
);
