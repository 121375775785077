import { $api_dealers, requestApi } from "./http";

export default class DealersService {
    static async getDealers(filters = []) {

        return $api_dealers.post("/service_dealer/search", { filters: filters });
    }
    static async getLocations() {

        return $api_dealers.get("/location");
    }
    static async createDealer(dealer) {
        return requestApi("/service_dealer",
            {
                method: "POST",
                body: JSON.stringify({ ...dealer }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    }
    static async updateDealer(id, dealer) {
        return $api_dealers.put("/service_dealer/" + id, dealer);
    }
    static async deleteDealer(id) {
        return $api_dealers.delete("/service_dealer/" + id);
    }

}