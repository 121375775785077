import React, { useState, useEffect, useMemo } from "react";
import { Select, TreeSelect } from "antd";

const { TreeNode } = TreeSelect;

export default function RegionSelect({ region, setRegion }) {
  const [value, setValue] = useState(undefined);
  const [expanded, setExpanded] = useState([]);
  const [showPlaceholders, setShowPlaceholders] = useState({
    cities: true,
    regions: true,
    districts: true,
    countries: true,
  });
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (region !== "") {
      // setValue(region);
      setValue({ label: region?.label[0], value: region?.label[0] });
    }
  }, [region]);

  const onChange = (newValue) => {
    // тут есть возможность выводить результат выбора в виде объекта, а не строки
    setValue(newValue);
    // const converted = {
    //   id: +newValue.split(".")[0],
    //   type: newValue.split(".")[1],
    // };
    // setRegion(converted);
    setRegion(newValue);
  };

  function onSearch(search) {
    // console.log(search);
    setShowPlaceholders({
      cities: false,
      regions: false,
      districts: false,
      countries: false,
    });
    const timeout = setTimeout(() => {
      onExpand(["city"], search);
    });

    if (isFetching) {
      clearTimeout(timeout);
    }
  }

  function onExpand(allTypes, search) {
    setExpanded(
      allTypes[0] == "all"
        ? ["city", "region", "district", "country"]
        : allTypes
    );
    const type = "city"; //allTypes[allTypes.length - 1];

    const uri = `https://dealers.vakio.ru/api/location_with_filter?type=city${
      search && search !== "" ? "&value=" + search : ""
    }`;
    setIsFetching(true);
    if (type !== undefined) {
      fetch(uri, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("accessToken")
              ? localStorage.getItem("accessToken")
              : ""
          }`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          setIsFetching(false);
          const citiesJson = json.filter((f) => f.city);

          if (type === "city" || type === "all") {
            setCities(
              citiesJson.map((i) => ({
                id: `${i.id}`,
                city: i.city.includes("(") ? i.city : i.city + ` (${i.region})`,
              }))
            );
          }
        });
    }
  }

  return (
    <div>
      <Select
        showSearch
        onSearch={onSearch}
        style={{ width: "100%" }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Город"
        allowClear
        // treeExpandedKeys={expanded}
        onChange={onChange}
        //onTreeExpand={onExpand}
        labelInValue
        getPopupContainer={(trigger) => trigger.parentNode}
        filterOption={(input, option) => {
          return option.children.includes(input) >= 0;
        }}
      >
        {cities.map((itm) => (
          <Select.Option value={itm.id} key={itm.id}>
            {itm.city}{" "}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
