import { Button, Form, Input, message, Modal, Select, Typography } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DynamicPhone from '../../../components/DynamicPhone/DynamicPhone';
import UserService from '../../../service/UserService';
import { useSelector } from 'react-redux';

const UpdateUserModal = ({ editUser, setEditUser, setUpdatedIndex, reloadPage, setReloadPage, roles }) => {
    const dispatch = useDispatch();
    const [createNew, setCreateNew] = useState(true);
    const [visible, setVisible] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [capabilities, setCapabilities] = useState(editUser?.capabilities.split(",") ?? [])
    const [form] = Form.useForm();
    const userIsAdmin = useSelector((state) => state.user.isAdmin)

    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        form.setFieldsValue({
            password: retVal,
        });
    }
    useEffect(() => {
        // if (Object.keys(editUser).length === 0) return;
        if (editUser === undefined) return;
        if (editUser.email === "") {
            form.resetFields();
            form.setFieldsValue(editUser);
            setCreateNew(true);
        } else {
            // if (editUser_.capabilities != "")
            //   editUser_.capabilities = editUser_.capabilities.split(", ").map(cap => { return { value: unroles[cap], label: cap } })
            const edit = { ...editUser }
            if (!Array.isArray(edit.capabilities)) {

                edit.capabilities = editUser.capabilities.split(",")
                setCapabilities(edit.capabilities)
            }
            form.setFieldsValue(edit);
            setCreateNew(false);
        }
        setVisible(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editUser]);


    function sendForm() {
        form
            .validateFields()
            .then((values) => {

                const copy = Object.assign({}, values);
                copy.capabilities = capabilities;
                copy.phone = "+" + copy.phone
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .split(" ")
                    .join("");
                if (copy.phone === "+7") {
                    setPhoneError(true);
                    return;
                }
                if (createNew) {

                    UserService.createUser(copy).then((resposne) => {
                        if (resposne.status === 500) {
                            throw "error"
                        }
                        setVisible(false);
                        message.success("Пользователь добавлен");
                        setReloadPage(!reloadPage);
                        setUpdatedIndex(editUser.id)
                        setTimeout(() => {
                            setUpdatedIndex(0)
                        }, 1000)

                    }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
                } else {
                    UserService.updateUser(editUser.id, copy).then((resposne) => {
                        setVisible(false);
                        message.success("Пользователь обновлен");
                        setReloadPage(!reloadPage);
                        setUpdatedIndex(editUser.id)
                        setTimeout(() => {
                            setUpdatedIndex(0)
                        }, 1000)
                    }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
                }


            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });

    }
    const onSelectRole = (role, option) => {
        console.log("there");
        const roleItem = option.role
        if (!userIsAdmin && roleItem.admin) {
            message.error("Это выбрать может только администратор")
        } else {
            setCapabilities([...capabilities, role])
        }
    }
    const onDeSelect = (role, option) => {

        const roleItem = option.role
        if (!userIsAdmin && roleItem.admin) {
            message.error("Это выбрать может только администратор")
        } else {
            setCapabilities(capabilities.filter(item => item !== role))
        }
    }
    return (
        <Modal
            title={
                <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} пользователя</Typography.Title>
            }
            centered

            open={visible}
            onOk={sendForm}
            okText={createNew ? "Добавить" : "Обновить"}
            onCancel={() => {
                setVisible(false)
                setEditUser(undefined)
            }}
            closable={false}
            destroyOnClose
            forceRender
            width={window.screen.width < 450 ? "100%" : 450}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}

        >
            <Form
                name="wrap"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                initialValues={editUser}
                layout="vertical"
            >
                {/* <Form.Item
                    label="Название аккаунта"
                    required
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите название аккаунта" className="settings__input" />
                </Form.Item> */}

                <Form.Item
                    label="Имя пользователя"
                    required
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите имя" className="settings__input" />
                </Form.Item>

                <Form.Item
                    label="E-mail"
                    required
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Некорректный E-mail",
                            type: "email",
                        },
                    ]}
                >
                    <Input placeholder="Введите e-mail" className="settings__input" />
                </Form.Item>

                <Form.Item
                    label="Телефон"
                    required
                    name="phone"
                    validateStatus={phoneError ? "error" : undefined}
                    help={phoneError ? "Заполните номер телефона!" : undefined}
                    rules={[
                        {
                            required: false,
                            message: "Некорректный телефон",
                            type: "string",
                        },
                    ]}
                >
                    {/* <Input placeholder="Введите телефон" className="settings__input" /> */}
                    <DynamicPhone />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    required
                    name="password"
                    rules={[
                        {
                            required: false,
                            message: "Минимальная длина пароля: 8 символов",
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите пароль"
                        suffix={
                            <Button type="default" size="small" onClick={generatePassword}>
                                Сгенерировать
                            </Button>
                        }
                        className="settings__input"
                    />
                </Form.Item>

                <Form.Item label="Права" required >
                    <Select
                        filterOption={(inputValue, option) => {
                            return option.label.includes(inputValue) || option.value.includes(inputValue);
                        }}
                        showSearch
                        value={capabilities}
                        className="settings__input" mode="multiple" options={roles}
                        onSelect={onSelectRole}
                        onDeselect={onDeSelect}
                    />

                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateUserModal;