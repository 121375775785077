
import { $api, analytic_url } from "./http";
export default class FileService {

    static async uploadFile(id, base, folder, format, name) {
        try {
            return $api.post("/api/task_file", {
                task_id: id,
                format: format,
                name: name,
                file: base,
            })
        } catch (error) {
            throw error
        }

    }
    static async deleteFile(id) {
        try {
            return $api.delete("/api/task_file/" + id);
        } catch (error) {
            throw error
        }

    }
    static async downloadFile(id) {
        const token = localStorage.getItem("accessToken");
        const Authorization = "Bearer " + token
        return fetch(analytic_url + "/api/task_file/" + id, {
            headers: {
                Authorization
            }
        });

    }
    static async updateFile(id, file) {
        try {
            return $api.delete("/api/task_file/" + id, file);
        } catch (error) {
            throw error
        }

    }
}