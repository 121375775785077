import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { parseModificatorsColumns } from "../lib/ParseModificators";

const TableChart = ({
  onRowClick,
  columns,
  dataSource,
  rowKey,
  modificators,
  selectionData,
  expandable,
  loading,
  scroll,
  plans,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setHeight(ref?.current?.offsetHeight);
    }
  }, [ref?.current?.clientHeight]);
  return (
    <div
      ref={ref}
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Table
        style={
          scroll
            ? {
                height: height,
                overflowY: "scroll",
                paddingRight: 7,
              }
            : {}
        }
        onRow={
          onRowClick
            ? (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    console.log(onRowClick);
                    event.stopPropagation();
                    const fields = onRowClick?.fields;
                    let url = onRowClick?.url;
                    if (fields && fields?.length > 0 && url) {
                      fields.forEach((item) => {
                        url = url.replace(item, record[item]);
                      });
                    }
                    window.open(url, "_blank").focus();
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }
            : undefined
        }
        sticky={
          scroll
            ? {
                offsetHeader: 0,
              }
            : undefined
        }
        loading={loading}
        expandable={expandable}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: [10, 50, 100, 500],
          position: ["topRight"],
        }}
        size={"small"}
        columns={
          parseModificatorsColumns(
            columns,
            modificators,
            selectionData,
            plans || []
          ) || []
        }
        dataSource={dataSource || []}
        rowKey={rowKey}
      />
    </div>
  );
};

export { TableChart };
