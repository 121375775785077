import { Button } from "antd";
import { useCreateDashboard } from "../model";
import EditDashboardModal from "./EditDashboardModal";

interface ICreateDashboardButton {

}

const CreateDashboardButton = ({

}: ICreateDashboardButton) => {
    const { onSubmit,
        visible,
        dashboard,
        loading,
        onOpenModal,
        onCancel } = useCreateDashboard();
    return (
        <>
            <Button
                style={{ marginRight: 20 }}
                onClick={onOpenModal}
                loading={loading}
            >
                Создать панель
            </Button>
            <EditDashboardModal
                omSubmit={onSubmit}
                dashboard={dashboard}
                onCancel={onCancel}
                visible={visible}
            />
        </>
    );
};

export default CreateDashboardButton;