import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import QuestionService from '../../../service/QuestionService';
import { setHeaderSubTitle, setHeaderTitle } from '../../../store/layoutSlice';
import "./Item.scss"
const Item = () => {
    const [item, setItem] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("params", params);
        dispatch(setHeaderTitle(""))
        QuestionService.getArticleItem(params.id).then(response => {
            setItem(response.data);
            dispatch(setHeaderTitle(response.data.article_title))
        })
    }, [])
    return (
        <>
            {item?.article_title && <div className='item' key={item.id}>
                <Typography.Title level={3}>{item.article_title}</Typography.Title>
                {/* <div className='rubrics__image'>{rubric.image}</div> */}


                <Typography.Text>{item.article_excerpt}</Typography.Text>

            </div>}
            <div className='item'>
                <Typography.Text>{item.article_content}</Typography.Text>
            </div>
        </>
    );
};

export default Item;