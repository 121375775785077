import moment from "moment";
import DynamicValue from "../ui/DynamicValue";
import PlanValue from "../ui/PlanValue";
import Value from "../ui/Value";
import { Tooltip } from "antd";

export const parseModificatorsColumns = (
  columns,
  modificators,
  selectionData,
  plans
) => {
  try {
    return columns.map((column) => {
      if (column?.children) {
        return {
          ...column,
          fixed: column?.ellipsis ? "left" : undefined,
          width: column?.width ? column?.width : undefined,

          children: column.children.map((column) => {
            return {
              width: column?.ellipsis ? 250 : undefined,

              ...column,
              fixed: column?.ellipsis ? "left" : undefined,
              render: (value, full) => {
                let valueModififed = value ?? 0;
                const modificator = column?.modificator
                  ? column?.modificator
                  : modificators[column.key];
                let planModificator = null;
                if (plans) {
                  planModificator = plans.find((plan) =>
                    column.key.includes(plan.id)
                  );
                }
                if (modificator) {
                  valueModififed = `${
                    modificator?.pre ?? ""
                  }${modifyValueToFormat(valueModififed, modificator?.format)}${
                    modificator?.after ?? ""
                  }`;
                }

                if (column?.ellipsis) {
                  return (
                    <Tooltip placement="topLeft" title={valueModififed}>
                      <div>
                        {modificator && modificator?.type === "dynamic" ? (
                          <DynamicValue
                            style={getModifiedStyle(
                              value,
                              column.key,
                              selectionData
                            )}
                            value={value}
                            valueModififed={valueModififed}
                          />
                        ) : modificator?.type === "plan" ? (
                          <PlanValue
                            style={getModifiedStyle(
                              value,
                              column.key,
                              selectionData
                            )}
                            value={value}
                            valueModififed={valueModififed}
                            planModificator={planModificator}
                          />
                        ) : (
                          <div
                            style={{
                              ...getModifiedStyle(
                                value,
                                column.key,
                                selectionData
                              ),
                              whiteSpace: "nowrap",
                            }}
                          >
                            {valueModififed}
                          </div>
                        )}
                      </div>
                    </Tooltip>
                  );
                } else {
                  return (
                    <div>
                      {modificator && modificator?.type === "dynamic" ? (
                        <DynamicValue
                          style={getModifiedStyle(
                            value,
                            column.key,
                            selectionData
                          )}
                          value={value}
                          valueModififed={valueModififed}
                        />
                      ) : modificator?.type === "plan" ? (
                        <PlanValue
                          style={getModifiedStyle(
                            value,
                            column.key,
                            selectionData
                          )}
                          value={value}
                          valueModififed={valueModififed}
                          planModificator={planModificator}
                        />
                      ) : (
                        <div
                          style={{
                            ...getModifiedStyle(
                              value,
                              column.key,
                              selectionData
                            ),
                            whiteSpace: "nowrap",
                          }}
                        >
                          {valueModififed}
                        </div>
                      )}
                    </div>
                  );
                }
              },
            };
          }),
        };
      }
      return {
        ...column,
        fixed: column?.ellipsis ? "left" : undefined,
        width: column?.width ? column?.width : undefined,
        render: (value, full) => {
          let valueModififed = value;
          const modificator = column?.modificator
            ? column?.modificator
            : modificators[column.key];
          if (modificator) {
            valueModififed = `${modificator?.pre ?? ""}${modifyValueToFormat(
              valueModififed,
              modificator?.format
            )}${modificator?.after ?? ""}`;
          }
          if (column?.ellipsis) {
            return (
              <Tooltip placement="topLeft" title={valueModififed}>
                <div
                  style={{
                    ...(getModifiedStyle(value, column.key, selectionData) ??
                      {}),
                    width: "fit-content",
                    overflow: "auto",
                  }}
                >
                  {valueModififed}
                </div>
              </Tooltip>
            );
          } else {
            return (
              <div style={getModifiedStyle(value, column.key, selectionData)}>
                {valueModififed}
              </div>
            );
          }
        },
      };
    });
  } catch {
    return columns;
  }
};
export const parseGroupModificatorsColumns = (
  columns,
  modificators,
  selectionData,
  groups
) => {
  try {
    return columns.map((column) => {
      if (column?.children) {
        return {
          ...column,
          children: column.children.map((column) => {
            return {
              ...column,
              // onCell: (record, index) => ({
              //     colSpan: (record.header && column.key !== "name") ? 0 : 1
              // }),
              render: (value, full) => {
                if (!full?.header || column.key == "name")
                  return (
                    <div className="table-cell">
                      <Value
                        value={value}
                        itemKey={column.key}
                        selectionData={selectionData}
                        modificators={[
                          ...(modificators ?? []),
                          ...(column?.modificator ?? []),
                        ]}
                      />
                    </div>
                  );
                return <></>;
              },
            };
          }),
        };
      }
      return {
        ...column,
        // onCell: (record, index) => ({
        //     colSpan: (record.header && column.key !== "name") ? 0 : 1
        // }),
        render: (value, full) => {
          if (!full?.header || column.key == "name")
            return (
              <div
                className="table-cell"
                style={{
                  marginLeft: full?.layer
                    ? 10 * full.layer
                    : (1 + groups.length) * 15,
                }}
              >
                <Value
                  value={value}
                  itemKey={column.key}
                  selectionData={selectionData}
                  modificators={[
                    ...(modificators ?? []),
                    ...(column?.modificator ?? []),
                  ]}
                />
              </div>
            );
        },
      };
    });
  } catch {
    return columns;
  }
};

export const parseModifiedValue = (value, key, modificators) => {
  let valueModififed = value;
  const modificator = modificators?.[key];
  if (modificator) {
    valueModififed = `${modificator?.pre ?? ""}${modifyValueToFormat(
      valueModififed,
      modificator?.format
    )}${modificator?.after ?? ""}`;
  }
  return valueModififed;
};

export const modifyValueToFormat = (value, format) => {
  switch (format) {
    case "number":
      return !isNaN(value)
        ? value
            ?.toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            .replace(/\.00$/, "")
        : 0;
    case "date":
      return value && value != "-" ? moment(value).format("DD MMMM YYYY") : "-";
    case "min_to_time":
      var num = value;

      if (value == 0) return "0 мин";
      var days = num / 60 / 24;
      var rdays = Math.floor(days);
      var hours = (days - rdays) * 24;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return `${rdays > 0 ? rdays + " д" : ""} ${
        rhours > 0 || rhours > 0 ? rhours + " ч" : ""
      } ${rdays > 0 || rhours > 0 || rminutes > 0 ? rminutes + " м" : ""}`;

    default:
      return value;
  }
};

const getSelectionColorStyle = (value, key, selectionData) => {
  if (isNaN(value)) return {};
  if (!selectionData) return {};
  const selectionItem = selectionData?.find(
    (item) => item?.field?.value === key
  );
  if (!selectionItem) return {};
  if (isNaN(selectionItem.value)) return {};

  if (Number(value) > Number(selectionItem.value)) {
    return {
      color: selectionItem.color_more,
    };
  } else {
    return {
      color: selectionItem.color_less,
    };
  }
};
export const getModifiedStyle = (value, key, selectionData) => {
  let style = {
    ...getSelectionColorStyle(value, key, selectionData),
  };
  return style;
};
