import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../styles/Card.scss";

import "./Dashboard.scss";
import { dashboardApi, dashboardModel } from "./entities/dashboard";
import { AddRowModule } from "./features/AddRow";
import { DeleteRowAction } from "./features/DeleteRowAction";
import { EditeDashboardCheckbox } from "./features/EditeDashboardCheckbox/ui";
import { ModifyPressetModal } from "./features/ModifyPressetModal";
import { PresetBox } from "./features/PresetBox";
import PresetModalBox from "./features/PresetBox/ui/PresetModalBox";
import { useLoadRoutes } from "./features/ModifyPressetModal/model";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserAccess } from "@entities/user";
const DashboardPage = () => {
  const [routes, subroutes, loadingRoutes] = useLoadRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
  const editeMode = useSelector(dashboardModel.selectEditeModeDashboard);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [presetBoxModalVisible, setPresetBoxModal] = useState(false);
  const [openedModalPreset, setOpenedModalPreset] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dashboardApi.getDashboards().then((response) => {
      dispatch(dashboardModel.setDashboards(response.data));
      const row_id = new URLSearchParams(location.search).get("row_id");
      const key_id = new URLSearchParams(location.search).get("key_id");

      if (row_id && key_id) {
        const dashboard_id = new URLSearchParams(location.search).get(
          "dashboard_id"
        );
        const dashboard = response.data.find(
          (item) => item?.id === Number(dashboard_id || 0)
        );
        if (dashboard) {
          const row = dashboard?.content?.find(
            (item) => item.row_id === item?.row_id
          );
          const preset = row?.cols?.find(
            (item) => item.key_id === Number(key_id)
          );
          if (preset) {
            setOpenedModalPreset(preset.key_id);
            dispatch(dashboardModel.setModalPreset(preset));
            setPresetBoxModal(true);
          }
        }
      }
    });
  }, []);
  const openModifyModal = (preset) => {
    setModifyModalVisible(true);
    dispatch(dashboardModel.setTargetPreset(preset));
  };
  const openPresetBoxModal = (preset) => {
    setOpenedModalPreset(preset.key_id);
    dispatch(dashboardModel.setModalPreset(preset));
    setPresetBoxModal(true);
    navigate({
      search:
        "?dashboard_id=" +
        dashboard?.id +
        "&row_id=" +
        preset.row_id +
        "&key_id=" +
        preset.key_id,
    });
  };

  return (
    <>
      {dashboard?.content && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <EditeDashboardCheckbox />
        </div>
      )}
      {dashboard?.content?.map((row, index) => {
        return (
          <div
            key={row.row_id}
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            {editeMode && (
              <div className="delete-row-action">
                <DeleteRowAction row_id={row.row_id} />
              </div>
            )}
            {row.cols.map((preset, colIndex) => {
              return (
                <PresetBox
                  openedModalPreset={openedModalPreset}
                  dashboard={dashboard}
                  key={preset.key_id}
                  openModifyModal={openModifyModal}
                  openPresetBoxModal={openPresetBoxModal}
                  preset={preset}
                  routes={routes}
                />
              );
            })}
          </div>
        );
      })}
      <ModifyPressetModal
        visible={modifyModalVisible}
        setVisible={setModifyModalVisible}
      />
      <PresetModalBox
        visible={presetBoxModalVisible}
        setVisible={setPresetBoxModal}
        openModifyModal={openModifyModal}
        routes={routes}
      />
      <UserAccess.DashboardEditAccess dashboard={dashboard}>
        {dashboard?.content && <AddRowModule />}
      </UserAccess.DashboardEditAccess>
    </>
  );
};

export default DashboardPage;
