import { $api, $api_auth, client_id, client_secret, grant_type } from "./http";

export default class UserService {
    static async signIn(username, password) {
        return $api_auth.post("/oauth/token", { username, password, client_id, client_secret, grant_type });
    }
    static async signUp(email) {
        return $api_auth.post("/api/portal_register", { email });
    }
    static async deleteUser(id) {
        return $api_auth.delete("/api/user/" + id);
    }
    static async createUser(user) {
        return $api_auth.post("/api/user", user);
    }
    static async updateUser(id, user) {
        return $api_auth.put("/api/user/" + id, user);
    }

    static async getUsers(name, role) {
        return $api_auth.get("/api/user/all", {
            params: {
                name,
                role: role ? role : undefined
            }
        });
    }
    static async getManagers() {
        return $api.post("api/managers");
    }
    static async getUserInfo() {
        return $api_auth.get("/api/user/get");
    }
}