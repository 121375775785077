import { Checkbox, DatePicker, Radio } from 'antd';
import dayjs from 'dayjs';
import "./DatePickerCustom.scss";


const DatePickerCustom = ({ date, detailing, setDetailing, setDate, fixed, setFixed, createPreset, error, disabled }) => {

    const onChangeRadioDate = (event) => {
        const value = event.target.value;
        const nowDate = dayjs();
        const difDate = dayjs().subtract(1, value);

        setDate([difDate, nowDate]);
    }
    const onChange = (date) => {
        setDate(date);
    }
    return (
        <div className='date'>
            <div className={createPreset ? 'date__radio' : undefined}>
                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={onChangeRadioDate} style={{ marginRight: 10 }} disabled={disabled === true}>
                    <Radio.Button value="months">Месяц</Radio.Button>
                    <Radio.Button value="quarters">Квартал</Radio.Button>
                    <Radio.Button value="years">Год</Radio.Button>
                </Radio.Group>
            </div>
            <div>
                <DatePicker.RangePicker
                    disabled={disabled === true}
                    status={error}
                    value={date}
                    onChange={onChange}
                    format={"YYYY-MM-DD"}
                    style={{ marginRight: 10 }}
                />
                {setFixed !== undefined && <Checkbox checked={fixed} onChange={(e) => setFixed(e.target.checked)}>Не изменять</Checkbox>}

            </div>

        </div>
    );
};

export default DatePickerCustom;