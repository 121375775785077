import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

import moment from "moment";
import 'moment/locale/ru';
moment.locale("ru");

const rootElement = document.getElementById('root') as HTMLElement;;
const root = createRoot(rootElement);
root.render(
  <ConfigProvider locale={ruRU}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider >,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
