import moment from "moment";

export const getColWidth = (colNum) => {
    const width = colNum * 100 / 24;
    return width;
}

export const uniqueId = (extra = "") => {
    return moment().unix() + extra
}

export const validateNumbers = (value) => {
    const reg = /^\d*(\.\d*)?$/;
    return reg.test(value)
}