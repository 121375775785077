import BarChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/BarChart';
import GroupChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/GroupChart';
import LineChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/LineChart';
import PieChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/PieChart';
import SumChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/SumChart';
import TableChart from '../../../../../Dashboard/components/ModalCreatePreset/charts/TableChart';
import { useUpdatePreset } from '../../../../entities/preset/models';

const SelectTypeGraph = ({ preset }) => {
    const updatePreset = useUpdatePreset(preset);

    const getChartIcon = (chart_type) => {
        switch (chart_type) {
            case "bar":
                return <BarChart />
            case "linear":
                return <LineChart />
            case "pie":
                return <PieChart />
            case "sum":
                return <SumChart />
            case "table":
                return <TableChart />
            case "group":
                return <GroupChart />
        }
    }

    const onChangeType = (type) => {
        updatePreset([["type", type], ["outValues", undefined]])

    }

    return (
        <div className='choose_charts'>
            {preset?.subroute?.chart_types?.split(",").map((chart_type, index) => {
                return <div key={index} className={`choose_charts__chart ${preset?.type === chart_type ? "seted" : ""}`}
                    onClick={() => onChangeType(chart_type)}>
                    {getChartIcon(chart_type)}
                </div>
            })}
        </div>
    );
};

export default SelectTypeGraph;