import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { getOrderedColumns } from "./lib/ColumnOrderLib";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { getOrderParamsReq, saveOrderParamsReq } from "./api";

export const useTableColumnsList = (
    preset: any,
    isLast: boolean,
    columns: TGroupChartColumn[] | undefined,
    loading: boolean
) => {
    const { defferedCallback } = useDefferedCallback(1000);
    const [orderParamsLoading, setOrderParamsLoading] = useState(false);
    const [orderParamsError, setOrderParamsError] = useState(false);
    const orderParams = useRef<TOrderParam>({});

    const [columnsWithOrder, setColumnsWithOrder] = useState<
        TGroupChartColumn[] | undefined
    >(columns);

    const saveOrderParams = (newOrderParams: TOrderParam) => {
        orderParams.current = { ...orderParams.current, ...newOrderParams };
        const preparedOrdersParams = Object.keys(orderParams.current).map((key) => {
            return {
                order: orderParams.current[key],
                route_key: preset.subroute.key,
                column_key: key,
                key_id: preset.key_id,
            };
        });
        defferedCallback(() =>
            saveOrderParamsReq(preparedOrdersParams, preset.subroute.key, preset.key_id)
        );
    };
    const swapColumns = (newIndex: number, currentIndex: number) => {
        if (newIndex === undefined) return;
        const firstColumn = columnsWithOrder![currentIndex];
        const secondColumn = columnsWithOrder![newIndex];

        const newOrderParams: TOrderParam = {
            ...orderParams.current,
            [firstColumn.key]: secondColumn.order!,
            [secondColumn.key]: firstColumn.order!,
        };
        saveOrderParams(newOrderParams);
        setColumnsWithOrder(getOrderedColumns(columnsWithOrder ?? [], newOrderParams));
    };
    const onClickLeft = (
        event: React.MouseEvent<HTMLElement>,
        key: string,
        parent?: TGroupChartColumn
    ) => {
        event.stopPropagation();
        const currentIndex = columnsWithOrder!.findIndex((column) => column.key === key);
        if (currentIndex === undefined) return;
        const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
        swapColumns(newIndex, currentIndex);
    };

    const onClickRight = (
        event: React.MouseEvent<HTMLElement>,
        key: string,
        parent?: TGroupChartColumn
    ) => {
        const currentIndex = columnsWithOrder!.findIndex((column) => column.key === key);
        if (currentIndex == undefined) return;
        const newIndex =
            currentIndex < columnsWithOrder!.length - 1 ? currentIndex + 1 : currentIndex;
        swapColumns(newIndex, currentIndex);
    };

    const getPreparedColumns = (
        columns: TGroupChartColumn[],
        parent?: TGroupChartColumn
    ): TGroupChartColumn[] => {
        return columns.map((column: TGroupChartColumn) => {
            return {
                ...column,
                children: column.children
                    ? getPreparedColumns(column.children, column)
                    : column.children,
                title:
                    !column.children && !parent
                        ? () => {
                              return (
                                  <div onClick={(e) => e.stopPropagation()} style={{}}>
                                      <div>{column.title}</div>

                                      <div>
                                          <Button
                                              onClick={(e) =>
                                                  onClickLeft(e, column.key, parent)
                                              }
                                              type="text"
                                              size="small"
                                              icon={
                                                  <ArrowLeftOutlined
                                                      style={{ fontSize: 8 }}
                                                  />
                                              }
                                          />
                                          <Button
                                              onClick={(e) =>
                                                  onClickRight(e, column.key, parent)
                                              }
                                              type="text"
                                              size="small"
                                              icon={
                                                  <ArrowRightOutlined
                                                      style={{ fontSize: 8 }}
                                                  />
                                              }
                                          />
                                      </div>
                                  </div>
                              );
                          }
                        : column.title,
            };
        });
    };

    const loadOrderParams = () => {
        setOrderParamsLoading(true);
        setOrderParamsError(false);
        getOrderParamsReq(preset.subroute.key, preset.key_id)
            .then((response) => {
                const orderParams = response.data.data.reduce(
                    (prev: any, current: any) => {
                        return {
                            ...prev,
                            [current.column_key]: current.order,
                        };
                    },
                    {}
                );
                const orderedColumns = getOrderedColumns(columns!, orderParams);

                setColumnsWithOrder(orderedColumns);

                const newOrderParams = orderedColumns.reduce((prev, current) => {
                    return {
                        ...orderParams.current,
                        ...prev,
                        [current.key]: current.order,
                    };
                }, {});

                saveOrderParams(newOrderParams);
            })
            .catch(() => {
                console.log("ERR");

                setOrderParamsError(true);
            })
            .finally(() => {
                setOrderParamsLoading(false);
            });
    };

    useEffect(() => {
        if (loading || isLast || !columns || (columns && columns?.length < 1)) return;
        loadOrderParams();
    }, [columns?.length, loading]);

    const preparedColumns =
        isLast || orderParamsError
            ? columns
            : getPreparedColumns(columnsWithOrder ?? columns ?? []);

    return {
        orderedColumns: preparedColumns,
        orderParamsLoading,
    };
};
