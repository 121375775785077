import { EditOutlined } from '@ant-design/icons';
import autoAnimate from '@formkit/auto-animate';
import { Button, message, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QuestionService from '../service/QuestionService';
import { setHeaderTitle } from '../store/layoutSlice';
import ChapterModal from './components/Modals/ChapterModal';
import "./QuestionsPage.scss";

const QuestionsPage = () => {
    const [chapters, setChapters] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [targetChapter, setTargetChapter] = useState({})
    const user = useSelector(state => state.user)

    const parentRef = useRef();

    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);

    useEffect(() => {
        QuestionService.getArticleChapters().then((response) => {
            dispatch(setHeaderTitle("Разделы"))
            console.log("Set chapters", response.data);
            setChapters(response.data);
        })
    }, [])

    const routeToChapter = (chapter) => {
        navigate("/portal-questions/chapter/" + chapter.id)
    }

    const deleteChapter = (chapter) => {

        QuestionService.deleteArticleChapter(chapter.id).then(() => {
            setChapters(chapters.filter(chapter_ => chapter_.id !== chapter.id));
            message.success("Раздел успешно удалён!");
        }).catch(() => {
            message.error("Не удалось удалить раздел!");
        })
    }
    return (
        <>
            <Button
                onClick={() => {
                    setTargetChapter({
                        chapter_title: "",
                        chapter_content: "",
                        create: true
                    })
                    setVisible(true);
                }}
                style={{ marginTop: 10, marginBottom: 15, marginLeft: 15 }}>
                Создать раздел
            </Button>
            <div ref={parentRef}>

                {chapters && chapters.map(chapter => {
                    return <div className='chapters' key={chapter.id} onClick={() => routeToChapter(chapter)}>

                        <div>

                            <Typography.Title level={4}>
                                {chapter.chapter_title}
                            </Typography.Title>
                            <Typography.Text level={4}>
                                {chapter.chapter_content}
                            </Typography.Text>
                        </div>

                        {(user.isAdmin || user.s_isAdmin) &&
                            <div className='chapters__actions' onClick={e => e.stopPropagation()}>
                                <Button shape="circle" icon={<EditOutlined />} style={{ marginRight: 10 }} size={20} onClick={() => {
                                    setTargetChapter(chapter);
                                    setVisible(true);
                                }} />
                            </div>}


                    </div>
                })}
            </div>
            <ChapterModal visible={visible} setVisible={setVisible} targetChapter={targetChapter} chapters={chapters} setChapters={setChapters} />
        </>
    );
};

export default QuestionsPage;