import { CSSProperties } from 'react';

interface IIframe {
	style: CSSProperties;
	title: string;
	allowFullScreen?: boolean;
	src: string;
	allow?: string;
	nameOfVideo?: string;
}

const Iframe = ({ style, title, allowFullScreen, src, allow, nameOfVideo }: IIframe) => {
	return (
		<div>
			<h3 style={{ marginTop: 0 }}>{nameOfVideo && nameOfVideo}</h3>
			<iframe
				style={style}
				title={title}
				allowFullScreen={allowFullScreen}
				src={src}
				allow={allow}
			/>
		</div>
	);
};

export default Iframe;
