import * as React from "react"

const PieChart = (props) => (
    <svg
        {...props}
        width={70}
        height={56}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            clipPath="url(#a)"
            stroke="#fff"
            strokeWidth={0.38}
            strokeLinejoin="round"
        >
            <path
                d="M34.994.054a28.7 28.7 0 0 1 12.313 2.77 28.319 28.319 0 0 1 9.876 7.766 27.845 27.845 0 0 1 5.483 11.224c.95 4.1.952 8.359.005 12.46a27.843 27.843 0 0 1-5.475 11.228 28.319 28.319 0 0 1-9.87 7.773 28.7 28.7 0 0 1-12.31 2.78 28.7 28.7 0 0 1-12.314-2.766L35 28.054l-.006-28Z"
                fill="#C3CAD1"
            />
            <path
                d="M22.676 53.276c-5.787-2.752-10.457-7.36-13.245-13.072A27.66 27.66 0 0 1 7.33 21.837 27.955 27.955 0 0 1 17.285 6.18 28.622 28.622 0 0 1 34.96.054l.039 28-12.324 25.222Z"
                fill="#DCE6F2"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h70v56H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default PieChart;
