import { Input, Select } from 'antd';
import { useUpdatePreset } from '../../../../entities/preset/models';
import DefaultSelect from '../../../../shared/ui/DefaultSelect';
import SearchSelect from '../../../../shared/ui/SearchSelect';
import { comparisons } from '../../config';
import FormItem from '../FormItem';
import CitySelect from '../PreparedFields/CitySelect';

const FiltersItem = ({ preset, updatePresetParent, boxStyle, itemsBoxStyle }) => {
    const updatePreset = useUpdatePreset(preset);

    const onChangeFieldValue = (id, value) => {
        console.log("SET VALUE", value);
        const newValue = [["fields", preset?.fields.map((field) => {
            if (field.id === id) {
                return { ...field, value: value }
            }
            return field;
        })]]
        if (updatePresetParent) {

            updatePresetParent(newValue)
        } else {

            updatePreset(newValue)
        }
    }
    const onChangeFieldComparison = (id, comparison) => {
        const newValue = [["fields", preset?.fields.map((field) => {
            if (field.id === id) {
                return { ...field, comparison: comparison }
            }
            return field;
        })]]
        if (updatePresetParent) {

            updatePresetParent(newValue)
        } else {

            updatePreset(newValue)
        }

    }
    const getFilterItem = (field) => {
        const multiple = field?.comparison == "whereIn" || field?.comparison == "whereNotIn"
        switch (field.field_type) {
            case "input":
                return <Input value={field.value} onChange={value_ => onChangeFieldValue(field.id, value_.target.value)} className='fields__input' />
            case "search_select":
                return <SearchSelect
                    multiple={field.comparison === "whereIn"}
                    field={field}
                    onChange={onChangeFieldValue}
                    value={field.value ?? undefined}
                />
            case "select":
                return <Select style={{ width: "100%" }} value={field.value} onChange={value_ => onChangeFieldValue(field.id, value_)} options={JSON.parse(field?.options ?? "[]")} mode={field.comparison === "whereIn" && "multiple"} />
            case "city":
                return <CitySelect multiple={multiple} initialValue={field.value} updateValue={value_ => onChangeFieldValue(field.id, value_)} type="city" />
            case "country":
                return <CitySelect multiple={multiple} initialValue={field.value} updateValue={value_ => onChangeFieldValue(field.id, value_)} type="country" />
            case "region":
                return <CitySelect multiple={multiple} initialValue={field.value} updateValue={value_ => onChangeFieldValue(field.id, value_)} type="region" />
            case "district":
                return <CitySelect multiple={multiple} initialValue={field.value} updateValue={value_ => onChangeFieldValue(field.id, value_)} type="district" />

            default:
                return <></>
        }
    }
    return (
        <div style={boxStyle ? boxStyle : {}}>
            {preset?.fields?.map((field => {
                return <FormItem key={field.id} labels={field.title} style={itemsBoxStyle}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "100%", marginRight: 10 }}>

                            {getFilterItem(field)}
                        </div>
                        <div style={{ width: 130 }}>
                            <DefaultSelect
                                style={{ width: 130 }}
                                allowClear={false}
                                value={field?.comparison ?? "="}
                                modifiedOptions={comparisons}
                                onChange={(value) => onChangeFieldComparison(field.id, value)}
                            />
                        </div>
                    </div>

                </FormItem>
            }))}
        </div>
    );
};

export default FiltersItem;