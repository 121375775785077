import { Checkbox } from 'antd';
import React from 'react';

const CheckboxInput = ({ extra_field, value, updateValue }) => {
    const onChange = (e) => {
        updateValue(e.target.checked);
    }
    return (
        <Checkbox checked={value} onChange={onChange}>{extra_field.label}</Checkbox>
    );
};

export default CheckboxInput;