import { Select } from 'antd';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import "./UserSelect.scss"
const UserSelect = ({ users, offFocus, multiple, editing, disabled, setSelectedUsers, selectedUsers, rawUsers, task, updateTask, key_select }) => {
    const [isActive, setIsActive] = useState(false)
    const ref = useRef()
    const findUsers = (ids) => {

        const idsArr = ids.split(",")
        return idsArr.map(id => {
            const user = users.find(item => item.id === Number(id));
            if (user)
                return user;
            return { name: "Аноним" }
        })
    }
    const findUser = (id) => {


        const user = users.find(item => item.id === Number(id));
        if (user)
            return user;
        return { name: "Аноним" }

    }

    useEffect(() => {
        if (!offFocus)
            if (!multiple) {
                if (rawUsers != "" && rawUsers != null && rawUsers != undefined)
                    setSelectedUsers(findUser(rawUsers));
                else {
                    setSelectedUsers(null)
                }
            } else {

                if (rawUsers != "" && rawUsers != null)
                    setSelectedUsers(findUsers(rawUsers));
                else {
                    setSelectedUsers([])
                }
            }
    }, [])

    const setActiveSelect = (e) => {
        e.stopPropagation();
        setIsActive(true);
    }

    const onChange = (item) => {
        if (!multiple) {
            console.log("Set item change", item);
            if (item != undefined)
                setSelectedUsers(findUser(item));
            else
                setSelectedUsers((item));
            console.log(findUser(item));
        } else {
            if (item.length === 0) {
                setSelectedUsers([])
            }
            else {
                setSelectedUsers(findUsers(item.join(",")));
            }
        }
    }

    const doneChange = () => {
        setIsActive(false);
        console.log("Done change", selectedUsers);
        if (!offFocus)
            if (multiple)
                updateTask({ ...task, [key_select]: selectedUsers.map(selectedUsers => selectedUsers.id).join(",") })
            else
                updateTask({ ...task, [key_select]: selectedUsers.id })
    }
    return (
        <div className='task__users__item__names' onClick={setActiveSelect}>
            {(((isActive && !offFocus) || editing) && !disabled) ? <>
                <Select
                    allowClear
                    mode={multiple ? "multiple" : undefined}
                    autoFocus={!offFocus}
                    showSearch
                    showAction="focus"
                    onBlur={doneChange}
                    style={{

                        maxWidth: !offFocus ? 240 : 130,
                        minWidth: !offFocus ? 170 : 130
                    }}
                    key={"id"}
                    onChange={onChange}
                    value={
                        multiple ?
                            selectedUsers.map(user => {
                                return { label: user.name, value: user.id }
                            })
                            :
                            selectedUsers?.id === undefined ? null : selectedUsers?.id}
                    options={users.map(user => {

                        return { label: user.name, value: user.id }
                    })}
                    filterOption={(inputValue, option) => {
                        return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
                    }}
                />
            </>
                :
                <>
                    {multiple ?
                        <>

                            {selectedUsers?.map((user, index, full) => <div key={user?.id || index} className='task__users__item__names__item'>
                                {`${user.name}${index === full.length - 1 ? "" : ", "}`}
                            </div>)}
                        </>
                        :
                        <>

                            {<div key={selectedUsers?.id} className='task__users__item__names__item'>
                                {`${selectedUsers ? selectedUsers?.name : ""}`}
                            </div>}
                        </>}
                </>
            }
        </div>
    );
};

export default UserSelect;